import { useAppDispatch } from 'app/config/store';
import {
  createEntity as createEquipmentPlan,
  deleteEntity as deleteEquipmentPlan,
} from 'app/entities/equipment-plan/equipment-plan.reducer';
import { createEntity as createResource, deleteEntity as deleteResource } from 'app/entities/resource-plan/resource-plan.reducer';
import { Button } from 'app/shared/components/button';
import Modal from 'app/shared/components/modal/Modal';
import ResourcesPlans from 'app/shared/model/custom/custom-resources-plans.model';
import { ChangedEquipmentPlanType, ShiftMode } from 'app/shared/types/types';
import React, { Dispatch, SetStateAction } from 'react';
import { translate } from 'react-jhipster';

interface ShiftPlanConfirmationModalProps {
  setShiftMode: Dispatch<SetStateAction<ShiftMode>>;
  setChangedResourcePlans: Dispatch<SetStateAction<{ plans: ResourcesPlans; status: string }[]>>;
  setIsShiftPlanLoading: Dispatch<SetStateAction<boolean>>;
  setIsShiftPlanPopupOpen: Dispatch<SetStateAction<boolean>>;
  changedResourcePlans: { plans: ResourcesPlans; status: string }[];
  changedEquipmentPlans: ChangedEquipmentPlanType[];
  isShiftPlanPopupOpen: boolean;
  refreshShift: () => void;
  onResourcePlanSave?: () => void;
}

const ShiftPlanConfirmationModal = ({
  setShiftMode,
  setChangedResourcePlans,
  setIsShiftPlanLoading,
  setIsShiftPlanPopupOpen,
  changedResourcePlans,
  changedEquipmentPlans,
  isShiftPlanPopupOpen,
  refreshShift,
  onResourcePlanSave,
}: ShiftPlanConfirmationModalProps) => {
  const dispatch = useAppDispatch();

  const onShiftPlanConfirmationCancel = () => {
    setShiftMode('VIEW');
    setIsShiftPlanPopupOpen(false);
    setChangedResourcePlans([]);
  };

  const onShiftPlanConfirmationSave = async () => {
    setIsShiftPlanLoading(true);
    for (let i = 0; i < changedResourcePlans.length; i++) {
      if (changedResourcePlans[i].status === 'ADDED') {
        await dispatch(
          createResource({
            shift: { id: changedResourcePlans[i]?.plans?.shift?.id },
            resource: { id: changedResourcePlans[i]?.plans?.resource.id },
            organigram: { id: changedResourcePlans[i]?.plans?.organigram?.id },
            equipment: changedResourcePlans[i]?.plans?.equipment,
            status: 'Assigned',
          }),
        );
      }
      if (changedResourcePlans[i].status === 'DELETED' && changedResourcePlans[i]?.plans?.id) {
        let resourceChangedEquipmentPlans = changedEquipmentPlans.filter(
          ep => ep?.status === 'DELETED' && ep?.plans?.resource?.id === changedResourcePlans[i]?.plans?.resource?.id,
        );
        for (let i = 0; i < resourceChangedEquipmentPlans.length; i++) {
          await dispatch(deleteEquipmentPlan(resourceChangedEquipmentPlans[i]?.plans?.id));
        }
        await dispatch(deleteResource(changedResourcePlans[i]?.plans?.id));
      }
    }
    await onShiftPlanConfirmationEquipmentSave();
    setChangedResourcePlans([]);
    setShiftMode('VIEW');
    refreshShift();
    setIsShiftPlanLoading(false);
    setIsShiftPlanPopupOpen(false);
    onResourcePlanSave?.();
  };

  const onShiftPlanConfirmationEquipmentSave = async () => {
    for (let i = 0; i < changedEquipmentPlans.length; i++) {
      let equipmentResource = changedResourcePlans.find(
        rp =>
          rp.status === 'ADDED' &&
          rp.plans?.resource?.id === changedEquipmentPlans[i]?.plans?.resource?.id &&
          rp.plans?.equipment === changedEquipmentPlans[i].plans?.equipment?.name,
      );
      if (changedEquipmentPlans[i]?.status === 'ADDED' && equipmentResource) {
        await dispatch(
          createEquipmentPlan({
            notes: changedEquipmentPlans[i]?.plans?.notes,
            startTime: changedEquipmentPlans[i]?.plans?.startTime,
            endTime: changedEquipmentPlans[i]?.plans?.endTime,
            resource: equipmentResource?.plans?.resource,
            shift: { id: changedEquipmentPlans[i]?.plans?.shift?.id },
            equipment: { id: changedEquipmentPlans[i]?.plans?.equipment?.id },
            organigram: { id: changedEquipmentPlans[i]?.plans?.organigram?.id },
          }),
        );
      }
    }
  };

  const onShiftPlanConfirmationCancelHandler = () => {
    onShiftPlanConfirmationCancel?.();
    onResourcePlanSave?.();
  };

  return (
    <Modal size="sm" title={'Confirmation'} isOpen={isShiftPlanPopupOpen} onClose={onShiftPlanConfirmationCancel}>
      <div className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
        {translate('rosterApp.planning.confirmation-popup.shift-plan-confirmation-message')}
      </div>
      <div className="flex gap-2 items-center justify-end p-3 md:p-4 border-t border-gray-200 rounded-b bg-navyBlue">
        {onShiftPlanConfirmationCancel && (
          <Button variant="outline" onClick={onShiftPlanConfirmationCancelHandler}>
            {translate('entity.action.cancel')}
          </Button>
        )}
        {onShiftPlanConfirmationSave && (
          <Button
            onClick={() => {
              onShiftPlanConfirmationSave();
            }}
          >
            {translate('entity.action.save')}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default ShiftPlanConfirmationModal;
