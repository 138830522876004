import { faCircleNotch, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { getEligibleEquipments, getEligibleResources } from 'app/modules/planning/planning.reducer';
import PositionTypeEnum from 'app/shared/enums/PositionTypeEnum';
import ResourceTypeEnum from 'app/shared/enums/ResourceTypeEnum';
import Resource from 'app/shared/model/custom/custom-resource.model';
import ResourcePlans from 'app/shared/model/custom/custom-resources-plans.model';
import { IEquipmentPlan } from 'app/shared/model/equipment-plan.model';
import { IEquipment } from 'app/shared/model/equipment.model';
import { IResourcePlan } from 'app/shared/model/resource-plan.model';
import { hasPermission } from 'app/shared/reducers/authentication.selector';
import { getImageUrl } from 'app/shared/util/file-utils';
import React, { useCallback, useMemo, useState } from 'react';
import AvatarMore from '../avatar-more/AvatarMore';
import Avatar from '../avatar/Avatar';
import Tooltip from '../tooltip/Tooltip';

type AvataGroupProps = {
  shiftId?: number;
  organigramId?: number;
  departmentId?: number;
  max: number;
  avatars?: { src: string; alt: string }[];
  resourcePlans?: ResourcePlans[];
  equipmentPlans?: IEquipmentPlan[];
  eligibleResources?: any;
  eligibleEquipments?: any;
  mode?: string;
  isFull?: boolean;
  addResourcePlan?: (resourcePlan: IResourcePlan) => void;
  updateResourcePlan?: (resourcePlan: IResourcePlan) => void;
  addEquipmentPlan?: (equipmentPlan: IEquipmentPlan) => void;
  deleteResourcePlan?: (planId: number, id: number) => void;
  deleteEquipmentPlan?: (planId: number, id: number) => void;
};

const AvatarGroup = ({
  shiftId,
  organigramId,
  departmentId,
  max,
  avatars = [],
  resourcePlans = [],
  equipmentPlans = [],
  eligibleResources = [],
  eligibleEquipments = [],
  mode,
  isFull,
  addResourcePlan,
  updateResourcePlan,
  addEquipmentPlan,
  deleteResourcePlan,
  deleteEquipmentPlan,
}: AvataGroupProps) => {
  const [isAddResource, setIsAddResource] = useState<boolean>(false);
  const [isAddEquipment, setIsAddEquipment] = useState<boolean>(false);
  const [addLoading, setAddLoading] = useState<boolean>(false);
  const [newAddedResource, setNewAddedResource] = useState<Resource>(null);
  const dispatch = useAppDispatch();
  const hasResourcePlanAddPermission = useAppSelector((state: IRootState) => hasPermission(state, 'resource_plan_add'));
  // TODO
  const hasEquipmentPlanAddPermission = true; //useAppSelector((state: IRootState) => hasPermission(state, 'resource_plan_add'));

  const isModePlanningNotFull = useMemo(() => mode === 'PLAN' && !isFull, [mode, isFull]);

  const maximum = useMemo(() => (isModePlanningNotFull ? max - 1 : max), [isModePlanningNotFull, max]);

  const equipmentsMax = useMemo(() => (mode === 'PLAN_EQUIPMENT' && !isFull ? max - 1 : max), [mode, isFull, max]);

  const addResource = async () => {
    if (!isAddResource) {
      setAddLoading(true);
      await dispatch(getEligibleResources({ shiftId, organigramId }));
      if (eligibleResources) {
        setIsAddResource(true);
      }
      setAddLoading(false);
    }
  };

  const addEquipment = async () => {
    if (!isAddEquipment) {
      setAddLoading(true);
      await dispatch(getEligibleEquipments({ shiftId, departmentId }));
      if (eligibleEquipments) {
        setIsAddEquipment(true);
      }
      setAddLoading(false);
    }
  };

  const submitAddResource = async (resource: Resource) => {
    if (resource) {
      setAddLoading(true);
      await dispatch(getEligibleEquipments({ shiftId, departmentId }));
      await addResourcePlan({
        shift: { id: shiftId },
        resource: resource,
        organigram: { id: organigramId },
        status: 'Assigned',
      });
      setNewAddedResource(resource);
      setIsAddResource(false);
      setAddLoading(false);
    }
  };

  const submitAddResourceEquipment = async (equipment: IEquipment) => {
    if (equipment) {
      setAddLoading(true);
      await updateResourcePlan({
        shift: { id: shiftId },
        resource: newAddedResource,
        organigram: { id: organigramId },
        status: 'Assigned',
        equipment: equipment?.name,
      });
      await addEquipmentPlan({
        shift: { id: shiftId },
        organigram: { id: organigramId },
        equipment: equipment,
        resource: newAddedResource,
      });
      setNewAddedResource(null);
      setAddLoading(false);
    }
  };

  const submitAddEquipment = async (equipment: IEquipment) => {
    if (equipment) {
      setAddLoading(true);
      await addEquipmentPlan({
        shift: { id: shiftId },
        organigram: { id: organigramId },
        equipment: equipment,
      });
      setIsAddEquipment(false);
      setAddLoading(false);
    }
  };

  const handleAddShow = useCallback(
    (value: boolean) => {
      setIsAddResource(value);
      setIsAddEquipment(value);
    },
    [setIsAddResource, setIsAddEquipment],
  );

  const handleDeleteResourcePlan = async (planId: number, id: number) => {
    deleteResourcePlan(planId, id);
  };

  const handleDeleteEquipmentPlan = async (planId: number, id: number) => {
    deleteEquipmentPlan(planId, id);
  };

  const displayedResources = useMemo(() => {
    const alreadyAssignedResourceIds = resourcePlans
      .filter(plan => plan?.resource && plan?.organigram?.refPosition?.type !== (PositionTypeEnum.STANDBY as string))
      .map(plan => plan.resource.id);
    return eligibleResources.filter(
      resource => !alreadyAssignedResourceIds.includes(resource.id) || resource?.type === (ResourceTypeEnum.DUMMY as string),
    );
  }, [eligibleResources, resourcePlans]);

  const getResourceEquipmentName = (resourceId: number) => {
    if (resourceId) {
      for (let i = 0; i < equipmentPlans.length; i++) {
        if (equipmentPlans[i].resource?.id === resourceId) {
          return equipmentPlans[i].equipment?.name;
        }
      }
    }
    return '';
  };

  return (
    <div className="flex align-items-center">
      {mode !== 'PLAN_EQUIPMENT' &&
        (avatars.length
          ? avatars?.map(
              (avatar, index) =>
                ((max && index < maximum) || !max) && (
                  <div key={index} className={`relative ${index !== 0 ? '-ml-3' : ''}`}>
                    <Tooltip text={avatar.alt}>
                      <Avatar src={avatar.src} alt={avatar.alt} size="h-8 w-8" />
                    </Tooltip>
                  </div>
                ),
            )
          : resourcePlans?.map((plan, index) => {
              const shortName = `${plan?.resource?.firstName?.substring(0, 1)}${plan?.resource?.lastName?.substring(0, 1)}`;
              const fullName = `${plan?.resource?.firstName?.substring(0, 1)?.toUpperCase()}${plan?.resource?.firstName?.substring(
                1,
              )} ${plan?.resource?.lastName?.substring(0, 1)}${plan?.resource?.lastName?.substring(1)}`;

              const equipmentName = getResourceEquipmentName(plan?.resource?.id);

              const avatarContent = () => (
                <Tooltip texts={[fullName, equipmentName]}>
                  <Avatar
                    planId={plan?.id}
                    resourceId={plan?.resource?.id}
                    resourceTeamId={plan?.resource?.team?.id}
                    src={getImageUrl(plan?.resource?.avatarContentType, plan?.resource?.avatar)}
                    alt={shortName}
                    bgColor={plan?.resource?.bgColor}
                    mode={mode}
                    deleteResourcePlan={handleDeleteResourcePlan}
                    size={`${equipmentName ? `h-8 w-8 border-2 border-indigo-500 shadow-lg shadow-indigo-500/50` : 'h-8 w-8'}`}
                  />
                </Tooltip>
              );

              if ((max && index < maximum) || !max) {
                return (
                  <div key={index} className={`relative ${index !== 0 ? '-ml-1.5' : ''}`}>
                    {newAddedResource?.id === plan?.resource?.id &&
                    newAddedResource?.type?.toUpperCase() !== ResourceTypeEnum[ResourceTypeEnum.DUMMY]?.toUpperCase() ? (
                      <AvatarMore
                        equipments={eligibleEquipments.filter(
                          equipment => !equipmentPlans.map(plan => plan.equipment.id).includes(equipment.id),
                        )}
                        isAdd={true}
                        addClicked={newAddedResource != null}
                        setAddClicked={() => setNewAddedResource(null)}
                        mode="PLAN_EQUIPMENT"
                        addEquipment={submitAddResourceEquipment}
                      >
                        {avatarContent()}
                      </AvatarMore>
                    ) : (
                      avatarContent()
                    )}
                  </div>
                );
              }
            }))}
      {mode !== 'PLAN_EQUIPMENT' && max > 0 && hasResourcePlanAddPermission ? (
        <AvatarMore
          resourcePlans={resourcePlans.filter((plan, index) => index >= maximum)}
          mode={mode}
          addResource={submitAddResource}
          deleteResourcePlan={handleDeleteResourcePlan}
        >
          <span
            className={`relative text-slate-600 border-white border-2 rounded-full flex font-bold items-center justify-center h-9 w-9 bg-slate-300 -ml-3 cursor-pointer`}
          >
            +{avatars.length ? avatars.length - maximum : resourcePlans.length - maximum}
          </span>
        </AvatarMore>
      ) : (
        <></>
      )}
      {isModePlanningNotFull && (
        <AvatarMore
          resources={displayedResources}
          isAdd={true}
          addClicked={isAddResource}
          setAddClicked={handleAddShow}
          mode={mode}
          addResource={submitAddResource}
          deleteResourcePlan={handleDeleteResourcePlan}
        >
          {hasResourcePlanAddPermission && (
            <span
              onClick={addResource}
              className={`relative text-slate-600 border-white border-2 rounded-full flex font-bold items-center justify-center h-9 w-9 bg-slate-300 -ml-3 cursor-pointer`}
            >
              {addLoading ? <FontAwesomeIcon icon={faCircleNotch} className="animate-spin" /> : <FontAwesomeIcon icon={faPlus} />}
            </span>
          )}
        </AvatarMore>
      )}
      {mode === 'PLAN_EQUIPMENT' && (
        <>
          {equipmentPlans
            ?.filter((_, index) => !max || index < equipmentsMax)
            .map((equipmentPlan, index) => {
              const { id, name, refEquipmentType } = equipmentPlan?.equipment || {};
              return (
                <div key={index} className="relative">
                  <Avatar
                    src={getImageUrl(refEquipmentType?.iconContentType, refEquipmentType?.icon)}
                    alt={name}
                    equipmentId={id}
                    equipmentName={name}
                    deleteEquipmentPlan={handleDeleteEquipmentPlan}
                    mode={mode}
                    planId={equipmentPlan?.id}
                    size="h-7 w-7"
                  />
                </div>
              );
            })}
          {max > 0 && (
            <AvatarMore
              equipmentPlans={equipmentPlans.filter((plan, index) => index >= equipmentsMax)}
              mode={mode}
              deleteEquipmentPlan={handleDeleteEquipmentPlan}
            >
              <span
                className={`relative text-slate-600 border-white border-2 rounded-full flex font-bold items-center justify-center h-9 w-9 bg-slate-300 cursor-pointer`}
              >
                +{equipmentPlans.length - equipmentsMax}
              </span>
            </AvatarMore>
          )}
          <AvatarMore
            equipments={eligibleEquipments.filter(equipment => !equipmentPlans.map(plan => plan.equipment.id).includes(equipment.id))}
            isAdd={true}
            addClicked={isAddEquipment}
            setAddClicked={handleAddShow}
            mode={mode}
            addEquipment={submitAddEquipment}
            deleteEquipmentPlan={handleDeleteEquipmentPlan}
          >
            {hasEquipmentPlanAddPermission == true && (
              <span
                onClick={addEquipment}
                className={`relative text-slate-600 border-white border-2 rounded-full flex font-bold items-center justify-center h-9 w-9 bg-slate-300 cursor-pointer`}
              >
                {addLoading ? <FontAwesomeIcon icon={faCircleNotch} className="animate-spin" /> : <FontAwesomeIcon icon={faPlus} />}
              </span>
            )}
          </AvatarMore>
        </>
      )}
    </div>
  );
};

export default AvatarGroup;
