import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import Modal from 'app/shared/components/modal/Modal';
import ValidationInput from 'app/shared/components/validation/validation-input';
import { getEntities as getOrganigrams } from 'app/entities/organigram/organigram.reducer';
import { getEntities as getRefActivityTypes } from 'app/entities/ref-activity-type/ref-activity-type.reducer';
import React, { useEffect, useState } from 'react';
import { translate } from 'react-jhipster';
import * as Yup from 'yup';
import { createEntity, updateEntity } from './demand-template.reducer';
import { useFormik } from 'formik';
import { Button } from 'app/shared/components/button';
import { IDemandTemplate } from 'app/shared/model/demand-template.model';
import ValidationSelect from 'app/shared/components/validation/validation-select';

type DemandTemplateProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  demandTemplate: IDemandTemplate;
};

const DemandTemplateAddEdit = ({ isOpen, onClose, onSave, demandTemplate }: DemandTemplateProps) => {
  const dispatch = useAppDispatch();

  const organigram = useAppSelector(state => state.organigram.entities);
  const refActivityType = useAppSelector(state => state.refActivityType.entities);

  const validationSchema = Yup.object().shape({
    headCount: Yup.number().required(translate('entity.validation.required')),
    organigram: Yup.object().required(translate('entity.validation.required')),
    refActivityType: Yup.object().required(translate('entity.validation.required')),
    refDepartment: Yup.object(),
    refPosition: Yup.object(),
  });
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      headCount: demandTemplate?.headcount,
      organigram: demandTemplate?.organigram,
      refActivityType: demandTemplate?.refActivityType,
      refDepartment: demandTemplate?.organigram?.refDepartment,
      refPosition: demandTemplate?.organigram?.refPosition,
    },
    validationSchema,
    onSubmit: values => submitForm(values),
  });

  useEffect(() => {
    formik.setValues({
      headCount: demandTemplate?.headcount,
      organigram: demandTemplate?.organigram,
      refActivityType: demandTemplate?.refActivityType,
      refDepartment: demandTemplate?.organigram?.refDepartment,
      refPosition: demandTemplate?.organigram?.refPosition,
    });
    dispatch(getOrganigrams({ sort: 'demandCoefficient,asc' }));
    dispatch(getRefActivityTypes({ sort: 'name,asc' }));
  }, [isOpen, demandTemplate]);

  const submitForm = async (values: Yup.InferType<typeof validationSchema>) => {
    if (!loading) {
      const req: IDemandTemplate = {
        id: demandTemplate?.id,
        headcount: values?.headCount,
        organigram: values?.organigram,
        refActivityType: values?.refActivityType,
      };

      setLoading(true);
      try {
        if (demandTemplate?.id) {
          await dispatch(updateEntity(req));
        } else {
          await dispatch(createEntity(req));
        }
        onSave();
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      size="lg"
      title={`${translate(
        demandTemplate?.id ? 'rosterApp.demandTemplate.home.createOrEditLabel' : 'rosterApp.demandTemplate.home.createLabel',
      )}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
          <div className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <ValidationSelect
                formik={formik}
                options={refActivityType}
                optionsKey="id"
                optionsValue="name"
                label={translate('rosterApp.demandTemplate.refActivityType')}
                field="refActivityType"
              />
            </div>
            <div className="sm:col-span-6">
              <ValidationSelect
                formik={formik}
                options={organigram}
                optionsKey="id"
                displayMapping={{
                  Department: 'refDepartment.name',
                  Position: 'refPosition.name',
                }}
                label={`${translate('rosterApp.organigram.refDepartment')} - ${translate('rosterApp.organigram.refPosition')}`}
                field="organigram"
                sort="Department"
              />
            </div>
            <div className="sm:col-span-6">
              <ValidationInput formik={formik} label={translate('rosterApp.demandTemplate.headcount')} field="headCount" />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end p-3 md:p-4 border-t gap-2 border-gray-200 rounded-b">
          <Button variant="outline" onClick={onClose} type="button">
            {translate('entity.action.cancel')}
          </Button>
          <Button type="submit" disabled={loading}>
            {loading && <FontAwesomeIcon icon={faSpinner} spin={loading} />}
            {translate('entity.action.save')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default DemandTemplateAddEdit;
