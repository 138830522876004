import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity, updateEntity } from 'app/entities/activity/activity.reducer';
import { getEntities as getDepartments } from 'app/entities/ref-department/ref-department.reducer';
import { getEntities as getActivityTypes } from 'app/entities/ref-activity-type/ref-activity-type.reducer';
import Modal from 'app/shared/components/modal/Modal';
import ValidationInput from 'app/shared/components/validation/validation-input';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { translate } from 'react-jhipster';
import * as Yup from 'yup';
import { Button } from 'app/shared/components/button';
import { IActivity } from 'app/shared/model/activity.model';
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import ValidationSelect from 'app/shared/components/validation/validation-select';

type ActivityAddEditProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  activity: IActivity;
};

const ActivityAddEdit = ({ isOpen, onClose, onSave, activity }: ActivityAddEditProps) => {
  const dispatch = useAppDispatch();
  const activityTypes = useAppSelector(state => state.refActivityType.entities);
  const departments = useAppSelector(state => state.refDepartment.entities);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    refActivityType: Yup.object().required(translate('entity.validation.required')),
    startTime: Yup.string().required(translate('entity.validation.required')),
    endTime: Yup.string().required(translate('entity.validation.required')),
    refDepartment: Yup.object(),
    notes: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      refActivityType: activity?.refActivityType || null,
      startTime: convertDateTimeFromServer(activity?.startTime) || '',
      endTime: convertDateTimeFromServer(activity?.endTime) || '',
      refDepartment: activity?.refDepartment || '',
      notes: activity?.notes || '',
    },
    validationSchema,
    onSubmit: values => submitForm(values),
  });

  useEffect(() => {
    if (isOpen) {
      getAllEntities();
      getAllPositions();
    }
  }, [isOpen]);

  useEffect(() => {
    formik.resetForm();
    formik.setValues({
      refActivityType: activity?.refActivityType || null,
      startTime: convertDateTimeFromServer(activity?.startTime) || '',
      endTime: convertDateTimeFromServer(activity?.endTime) || '',
      refDepartment: activity?.refDepartment || '',
      notes: activity?.notes || '',
    });
  }, [isOpen, activity]);

  const getAllEntities = () => {
    dispatch(getActivityTypes({ sort: 'name,asc' }));
  };

  const getAllPositions = () => {
    dispatch(getDepartments({ sort: 'name,asc' }));
  };

  const submitForm = async values => {
    if (!loading) {
      setLoading(true);
      const res = {
        id: activity?.id,
        refActivityType: values.refActivityType,
        startTime: convertDateTimeToServer(values.startTime),
        endTime: convertDateTimeToServer(values.endTime),
        refDepartment: values.refDepartment,
        notes: values.notes,
      };

      try {
        if (activity?.id) {
          await dispatch(updateEntity(res));
        } else {
          await dispatch(createEntity(res));
        }
        onSave();
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      size="lg"
      title={`${translate(activity?.id ? 'rosterApp.activity.home.createOrEditLabel' : 'rosterApp.activity.home.createLabel')}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
          <div className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6 mb-4">
            <div className="sm:col-span-6">
              <ValidationSelect
                formik={formik}
                options={activityTypes}
                optionsKey="id"
                optionsValue="name"
                label={translate('rosterApp.activity.refActivityType')}
                field="refActivityType"
                object
              />
            </div>
            <div className="sm:col-span-3">
              <ValidationInput formik={formik} label={translate('rosterApp.activity.startTime')} field="startTime" type="datetime-local" />
            </div>
            <div className="sm:col-span-3">
              <ValidationInput formik={formik} label={translate('rosterApp.activity.endTime')} field="endTime" type="datetime-local" />
            </div>
            <div className="sm:col-span-6">
              <ValidationSelect
                formik={formik}
                options={departments}
                optionsKey="id"
                optionsValue="name"
                label={translate('rosterApp.activity.refDepartment')}
                field="refDepartment"
                object
              />
            </div>
            <div className="sm:col-span-6">
              <ValidationInput formik={formik} label={translate('rosterApp.activity.notes')} field="notes" />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end p-3 md:p-4 gap-2 border-t border-gray-200 rounded-b">
          <Button variant="outline" onClick={onClose} type="button">
            {translate('entity.action.cancel')}
          </Button>
          <Button type="submit" disabled={loading}>
            {loading && <FontAwesomeIcon icon={faSpinner} spin={loading} />}
            {translate('entity.action.save')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ActivityAddEdit;
