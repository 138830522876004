import { overridePaginationStateWithQueryParams } from '@util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '@util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import DataTable, { DataTableOptions } from 'app/shared/components/data-table/DataTable';
import { EquipmentStatus } from 'app/shared/enums/EquipmentStatusEnum';
import EquipmentCustom from 'app/shared/model/custom/custom-equipment.model';
import { getEquipmentStatusI18nTranslation } from 'app/shared/util/i18n';
import { Translate, getPaginationState, translate } from 'react-jhipster';
import EquipmentAddEdit from './equipment-add-edit';
import EquipmentDelete from './equipment-delete-modal';
import { getEntities } from './equipment.reducer';

type CustomEquipmentProps = {
  showDetails?: boolean;
};

const CustomEquipment = ({ showDetails = false }: CustomEquipmentProps) => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  pageLocation.search = '';

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'name'), pageLocation.search),
  );

  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState<EquipmentCustom>();
  const [deletingEquipments, setDeletingEquipments] = useState<EquipmentCustom[]>();
  const equipmentList = useAppSelector(state => state.equipment.entities);
  const loading = useAppSelector(state => state.equipment.loading);
  const totalItems = useAppSelector(state => state.equipment.totalItems);

  const equipmentDtOptions: DataTableOptions = {
    headers: [],
    columns: [
      {
        key: 'name',
        filterable: true,
        sortable: true,
        render: row => <h6 className="text-slate-800 mb-0">{row?.name}</h6>,
      },
      {
        key: 'type',
        filterable: true,
        sortable: true,
        render: row => <>{row?.type}</>,
      },
      {
        key: 'status',
        filterable: true,
        sortable: true,
        render: row => getEquipmentStatusI18nTranslation(row.status as EquipmentStatus),
      },
      {
        key: 'refEquipmentType',
        filterable: true,
        filterableKey: 'name',
        sortable: true,
        render: row => (
          <Link className="text-primary capitalize hover:text-blue-700 no-underline font-medium" to={`/ref-equipment-type`}>
            {row.refEquipmentType?.name}
          </Link>
        ),
      },
      {
        key: 'refDepartment',
        filterable: true,
        filterableKey: 'name',
        sortable: false,
        render: row => (
          <Fragment>
            <Link className="text-primary hover:text-blue-700 no-underline font-medium" to={`/ref-department`}>
              {row.refDepartment?.name}
            </Link>
          </Fragment>
        ),
      },
    ],
  };

  equipmentDtOptions.headers = equipmentDtOptions.columns.map(c => translate(`rosterApp.equipment.${c.key}`));

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleAdd = () => {
    setSelectedEquipment(null);
    setIsAddEditModalOpen(true);
  };

  const handleUpdate = (rows: EquipmentCustom[]) => {
    setSelectedEquipment(rows[0]);
    setIsAddEditModalOpen(true);
  };

  const handleDelete = (rows: EquipmentCustom[]) => {
    setIsDeleteModalOpen(true);
    setDeletingEquipments(rows);
  };

  const handleSaveAddEdit = () => {
    setIsAddEditModalOpen(false);
    sortEntities();
  };

  const handleCloseAddEdit = () => {
    setIsAddEditModalOpen(false);
  };

  const handleConfirmDelete = () => {
    setIsDeleteModalOpen(false);
    sortEntities();
  };

  const handleCloseDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const getDataTable = () => (
    <>
      {equipmentList && (
        <DataTable
          data={equipmentList}
          options={equipmentDtOptions}
          sort={sort}
          loading={loading}
          onRefresh={handleSyncList}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          paginationState={paginationState}
          onPaginationClick={handlePagination}
          paginationTotalItems={totalItems}
          paginationMaxButtons={5}
          showDetails={showDetails}
          createLabel={translate('rosterApp.equipment.home.createLabel')}
          refreshListLabel={translate('rosterApp.equipment.home.refreshListLabel')}
          notFoundLabel={translate('rosterApp.equipment.home.notFound')}
          title={showDetails && translate('global.menu.entities.equipment')}
        />
      )}
    </>
  );

  return (
    <Fragment>
      {!showDetails && (
        <h2 id="equipment-heading" className={`mb-2 whitespace-nowrap`} data-cy="EquipmentHeading">
          <Translate contentKey="global.menu.entities.equipment">Equipment</Translate>
        </h2>
      )}
      {getDataTable()}

      <EquipmentAddEdit isOpen={isAddEditModalOpen} onClose={handleCloseAddEdit} onSave={handleSaveAddEdit} equipment={selectedEquipment} />
      <EquipmentDelete
        isOpen={isDeleteModalOpen}
        onClose={handleCloseDelete}
        onConfirm={handleConfirmDelete}
        deletingEquipments={deletingEquipments}
      />
    </Fragment>
  );
};

export default CustomEquipment;
