import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Department from '@model/custom/custom-department.model';
import ShiftModel from '@model/custom/custom-shift.model';
import { getMonthSlot, getNextMonthSlotFromDay, getPrevMonthSlotFromDay } from '@util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getEquiptments } from 'app/entities/equipment/equipment.reducer';
import { selectEquipmentList } from 'app/entities/equipment/equipment.selector';
import { getEntities as getFacilities } from 'app/entities/facility/facility.reducer';
import { selectFacilityList } from 'app/entities/facility/facility.selector';
import Month from 'app/modules/planning/Month/Month';
import Sidebar from 'app/modules/planning/Sidebar/Sidebar';
import MessagePopup from 'app/shared/components/message-popup/message-popup';
import SplitPane from 'app/shared/components/split-pane/SplitPane';
import Position from 'app/shared/model/custom/custom-position.model';
import { IFacility } from 'app/shared/model/facility.model';
import { IResourcePlan } from 'app/shared/model/resource-plan.model';
import { IResource } from 'app/shared/model/resource.model';
import { CalendarPlanningMode, ChangedResourcePlanType, ShiftActionType, ShiftDirection, ShiftMode } from 'app/shared/types/types';
import dayjs from 'dayjs';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { translate } from 'react-jhipster';
import Calendar from '../calendar/Calendar';
import { getShiftDetails, getShiftsByStartDateAndEndDate, setCalendarPlanningMode } from './planning.reducer';
import { selectShifts } from './planning.selector';
import Side from './side/Side';

const Planning = () => {
  const dispatch = useAppDispatch();
  const activeView = useAppSelector(state => state.planning.activeView);
  const [currentShifts, setCurrentShifts] = useState<ShiftModel[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [monthSlot, setMonthSlot] = useState<dayjs.Dayjs[]>(getMonthSlot(new Date()));
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const facilityList = useAppSelector(selectFacilityList);
  const [selectedFacility, setSelectedFacility] = useState<IFacility>(facilityList[0]);
  const shifts = useAppSelector(selectShifts);
  const equipments = useAppSelector(selectEquipmentList);
  const [loading, setLoading] = useState(false);
  const [selectedShiftOrPosition, setSelectedShiftOrPosition] = useState<{
    shiftId: number;
    organigramId: number;
    position: Position;
  } | null>();
  const [planEquipment, setPlanEquipment] = useState<boolean>(false);
  const shiftRefreshed = useAppSelector(state => state.planning.shiftRefreshed);
  const calendarPlanningMode = useAppSelector(state => state.planning.calendarPlanningMode) as CalendarPlanningMode;
  const [shouldRefreshShifts, setShouldRefreshShifts] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [changedResourcePlans, setChangedResourcePlans] = useState<ChangedResourcePlanType[]>([]);
  const changedResourcePlansRef = useRef<ChangedResourcePlanType[]>(changedResourcePlans);
  const [currentShiftMode, setCurrentShiftMode] = useState<ShiftActionType>();

  const initDepartments = (initDepartmentsShifts?: ShiftModel[]) => {
    if (initDepartmentsShifts && initDepartmentsShifts.length > 0) {
      const adjustedDepartments = (
        initDepartmentsShifts[0]?.facilities.find(facilitiy => facilitiy.id === selectedFacility?.id)?.departments ?? []
      ).map(dep => ({ ...dep, unfolded: true }));
      setDepartments(adjustedDepartments);
    } else {
      setDepartments([]);
    }
  };

  useEffect(() => {
    dispatch(getFacilities({}));
    dispatch(getEquiptments({}));
  }, []);

  useEffect(() => {
    if (shouldRefreshShifts) {
      refreshShifts();
      dispatch(setCalendarPlanningMode('SHIFT_VIEW'));
    }
  }, [monthSlot]);

  useEffect(() => {
    setCurrentShifts(shifts);
    initDepartments(shifts);
  }, [shifts]);

  useEffect(() => {
    initDepartments(shifts);
  }, [selectedFacility]);

  useEffect(() => {
    setMonthSlot(getMonthSlot(selectedDate));
    setShouldRefreshShifts(true);
  }, [selectedDate]);

  const refreshShifts = async () => {
    document.body.style.cursor = 'wait';
    setLoading(() => true);
    if (monthSlot?.length > 0) {
      const startDate = monthSlot[0].toDate();
      startDate.setHours(0, 0, 0, 0);
      const endDate = monthSlot[monthSlot.length - 1].toDate();
      endDate.setHours(23, 59, 59, 999);
      await dispatch(getShiftsByStartDateAndEndDate({ startDate, endDate }));
    }
    setLoading(() => false);
    document.body.style.cursor = 'auto';
    setSelectedShiftOrPosition(null);
  };

  useEffect(() => {
    if (calendarPlanningMode === 'SHIFT_DETAILS_VIEW' && shifts?.length > 0) {
      const calendarDate = (shifts as ShiftModel[])[0].startTime;
      const calendarDateDayjs = dayjs.utc(calendarDate);
      setMonthSlot([calendarDateDayjs]);
      setShouldRefreshShifts(false);
    }
  }, [calendarPlanningMode]);

  const handleReset = () => {
    setMonthSlot(getMonthSlot(new Date()));
    setShouldRefreshShifts(true);
  };

  const handleShiftDirection = async (shiftDirection: ShiftDirection) => {
    if (shifts?.length > 0 && calendarPlanningMode === 'SHIFT_DETAILS_VIEW') {
      const shiftId = (shifts as ShiftModel[])[0]?.id;
      try {
        const result = await dispatch(getShiftDetails({ shiftId, shiftDirection }));
        const returnedShift = (result.payload as { data: ShiftModel[] })?.data;
        if (returnedShift && returnedShift.length > 0) {
          const calendarDate = returnedShift[0].startTime;
          const calendarDateDayjs = dayjs.utc(calendarDate);
          setMonthSlot([calendarDateDayjs]);
        }
        setShouldRefreshShifts(false);
      } catch (error) {
        console.error('Error fetching shift details: ', error);
      }
    }
  };

  const handlePrev = () => {
    if (calendarPlanningMode === 'SHIFT_DETAILS_VIEW') {
      handleShiftDirection('PREVIOUS');
    } else {
      const prevMonthSlot = getPrevMonthSlotFromDay(monthSlot[0]);
      setSelectedDate(prevMonthSlot[0].toDate());
    }
  };

  const handleNext = () => {
    if (calendarPlanningMode === 'SHIFT_DETAILS_VIEW') {
      handleShiftDirection('NEXT');
    } else {
      const nextMonthSlot = getNextMonthSlotFromDay(monthSlot[monthSlot.length - 1]);
      setSelectedDate(nextMonthSlot[0].toDate());
    }
  };

  const getMonthIndex = () => {
    const day = monthSlot[monthSlot.length - 1];
    return day.month();
  };

  const handleFolding = (departmentId: number, unfolded: boolean) => {
    setDepartments(prevState => prevState.map(department => (department.id === departmentId ? { ...department, unfolded } : department)));
  };

  // Handler to update the selected date
  const handleDateSelected = (date: Date) => {
    setSelectedDate(date);
  };

  const setEquipmentUnfolded = (deparmentId: number, value: boolean) => {
    setDepartments(
      departments.map(dep => {
        if (dep.id === deparmentId) {
          dep.unfoldedEquipments = value;
        }
        return dep;
      }),
    );
  };

  const handleSetSelectedPosition = (position: Position, departmentId: number) => {
    if (selectedShiftOrPosition?.organigramId === position?.organigram?.id && selectedShiftOrPosition?.position?.id === position?.id) {
      if (currentShiftMode === 'EDIT_PLAN') {
        setSelectedShiftOrPosition(prevState => ({ ...prevState, organigramId: undefined, position: undefined }));
      } else {
        setSelectedShiftOrPosition(null);
      }
    } else {
      const nbEditableShifts = currentShifts.filter(shift => shift.mode === 'PLAN').length;
      if (nbEditableShifts === 1) {
        setSelectedShiftOrPosition({
          shiftId: currentShifts.find(shift => shift.mode === 'PLAN')?.id,
          organigramId: position?.organigram?.id,
          position: currentShifts
            .find(shift => shift.mode === 'PLAN')
            ?.facilities.flatMap(facility => facility.departments)
            ?.find(dep => dep.id === departmentId)
            ?.positions?.find(pos => pos.id === position?.id),
        });
      } else if (nbEditableShifts === 0) {
        setSelectedShiftOrPosition(null);
        setErrorMessage(translate('error.noShiftInPlanning'));
      } else {
        setSelectedShiftOrPosition(null);
        setErrorMessage(translate('error.onlyOneShiftInPlanning'));
      }
    }
  };

  useEffect(() => {
    if (refreshShifts) {
      refreshShifts();
    }
  }, [shiftRefreshed]);

  const setShiftMode = (shiftId: number, mode: ShiftMode) => {
    setCurrentShifts(prevShifts => prevShifts.map(shift => (shift.id === shiftId ? { ...shift, mode } : shift)));
  };

  const handlePlanInShift = (selectedRows: IResource[]) => {
    const shiftResourcesIds = selectedShiftOrPosition.position?.resourcesPlans?.map(rp => rp?.resource?.id);
    const resourcesNotInShift = selectedRows.filter(resource => !shiftResourcesIds.includes(resource.id));
    if (
      selectedShiftOrPosition.position?.shiftDemand?.headCount >=
      shiftResourcesIds.length + resourcesNotInShift.length + changedResourcePlansRef.current.filter(rp => rp.status === 'ADDED').length
    ) {
      for (let i = 0; i < resourcesNotInShift.length; i++) {
        addResourcePlan({
          organigram: { id: selectedShiftOrPosition?.organigramId },
          shift: { id: selectedShiftOrPosition?.shiftId },
          resource: resourcesNotInShift[i],
          status: 'Assigned',
        });
      }
    } else {
      setErrorMessage(translate('error.recourcesCountExceedHeadCount'));
    }
  };

  const addResourcePlan = (resourcePlan: IResourcePlan) => {
    const newResourcePlans = [
      ...changedResourcePlansRef.current,
      {
        plans: {
          id: resourcePlan.id,
          equipment: resourcePlan.equipment,
          resource: {
            id: resourcePlan.resource.id,
            firstName: resourcePlan.resource.firstName,
            lastName: resourcePlan.resource.lastName,
            name: resourcePlan.resource.name,
            bgColor: 'bg-blue-500 text-white',
            avatar: resourcePlan.resource.avatar,
            avatarContentType: resourcePlan.resource.avatarContentType,
          },
          shift: {
            id: resourcePlan.shift.id,
            facilities: [],
            startTime: resourcePlan?.shift?.startTime?.toDate()?.toDateString(),
            endTime: resourcePlan?.shift?.endTime?.toDate()?.toDateString(),
            name: '',
            status: resourcePlan?.shift?.status,
            mode: null,
          },
          status: resourcePlan.status,
          task: resourcePlan.task,
          organigram: resourcePlan.organigram,
        },
        status: 'ADDED',
      },
    ];
    changedResourcePlansRef.current = newResourcePlans;
    setChangedResourcePlans(newResourcePlans);
  };

  const updateResourcePlan = (resourcePlan: IResourcePlan) => {
    const newResourcePlans = changedResourcePlansRef.current.map(rp => {
      if (rp.plans?.resource?.id === resourcePlan?.resource?.id) {
        let copiedRP = { ...rp };
        copiedRP.plans.equipment = resourcePlan?.equipment;
        return copiedRP;
      }
      return rp;
    });
    changedResourcePlansRef.current = newResourcePlans;
    setChangedResourcePlans(newResourcePlans);
  };

  const deleteResourcePlan = (planId: number, resourceId: number, organigramId: number, shiftId: number) => {
    const newResourcePlans = [
      ...changedResourcePlansRef.current.filter(r => (planId ? r?.plans?.id !== planId : r?.plans?.resource?.id !== resourceId)),
    ];
    if (planId) {
      newResourcePlans.push({
        plans: {
          id: planId,
          resource: { id: resourceId, firstName: '', lastName: '', name: '' },
          organigram: { id: organigramId },
          shift: {
            id: shiftId,
            facilities: [],
            startTime: '',
            endTime: '',
            name: '',
            status: '',
            mode: null,
          },
        },
        status: 'DELETED',
      });
    }
    changedResourcePlansRef.current = newResourcePlans;
    setChangedResourcePlans(newResourcePlans);
  };

  useEffect(() => {
    changedResourcePlansRef.current = changedResourcePlans;
  }, [changedResourcePlans]);

  const onSelectEditResourcePlan = (shiftId?: number) => {
    setSelectedShiftOrPosition({ shiftId, organigramId: undefined, position: undefined });
  };

  const onShiftModeSelect = (shiftId?: number, shiftMode?: ShiftActionType) => {
    setCurrentShiftMode(shiftMode);
    if (shiftMode === 'EDIT_PLAN') {
      onSelectEditResourcePlan(shiftId);
    }
  };

  return (
    <div className="App">
      <SplitPane minWidth={0} maxWidth={100} onClickWidth={0.001} defaultWidth={calendarPlanningMode === 'SHIFT_DETAILS_VIEW' ? 50 : 85}>
        <div className="max-h-full h-full overflow-y-auto overflow-x-hidden custom-scrollbar">
          {activeView === 'planning' ? (
            <Fragment>
              <div className="flex">
                <Sidebar
                  departments={departments}
                  onUnfolded={handleFolding}
                  currentMonthIndex={getMonthIndex()}
                  handleReset={handleReset}
                  handlePrev={handlePrev}
                  handleNext={handleNext}
                  onDateSelected={handleDateSelected}
                  selectedDate={selectedDate}
                  facilities={facilityList}
                  equipments={equipments}
                  setFacility={setSelectedFacility}
                  facility={selectedFacility}
                  selectedPosition={selectedShiftOrPosition?.position}
                  setSelectedPosition={handleSetSelectedPosition}
                  planEquipment={planEquipment}
                />
                <Month
                  monthSlot={monthSlot}
                  shifts={currentShifts}
                  departments={departments}
                  equipments={equipments}
                  selectedDate={selectedDate} // Pass selectedDate to the Month component
                  setEquipmentUnfolded={setEquipmentUnfolded}
                  planEquipment={planEquipment}
                  setPlanEquipment={setPlanEquipment}
                  setShiftMode={setShiftMode}
                  changedResourcePlans={changedResourcePlansRef.current}
                  setChangedResourcePlans={setChangedResourcePlans}
                  addResourcePlan={addResourcePlan}
                  updateResourcePlan={updateResourcePlan}
                  deleteResourcePlan={deleteResourcePlan}
                  onShiftModeSelect={onShiftModeSelect}
                  onResourcePlanSave={() => onSelectEditResourcePlan()}
                />
              </div>
              {loading && shifts.length === 0 && (
                <div className="flex flex-col justify-center items-center p-8 border">
                  <FontAwesomeIcon icon={faCircleNotch} spin={true} />
                  <p>{translate('global.loading')}</p>
                </div>
              )}
            </Fragment>
          ) : (
            <Calendar />
          )}
        </div>
        <Side selectedShiftOrPosition={selectedShiftOrPosition} planResourcesInShift={handlePlanInShift} />
      </SplitPane>
      <MessagePopup isOpen={errorMessage !== ''} message={errorMessage} onClose={() => setErrorMessage('')} />
    </div>
  );
};

export default Planning;
