import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getRefDepartments } from 'app/entities/ref-department/ref-department.reducer';
import { getEntities as getRefEquipmentTypes } from 'app/entities/ref-equipment-type/ref-equipment-type.reducer';
import Modal from 'app/shared/components/modal/Modal';
import ValidationInput from 'app/shared/components/validation/validation-input';
import ValidationSelect from 'app/shared/components/validation/validation-select';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { translate } from 'react-jhipster';
import * as Yup from 'yup';
import { createEntity, updateEntity } from './equipment.reducer';
import EquipmentCustom from 'app/shared/model/custom/custom-equipment.model';
import { Button } from 'app/shared/components/button';
import { ALL_EQUIPMENT_STATUS_VALUES } from 'app/shared/enums/EquipmentStatusEnum';
import { getEquipmentStatusI18nTranslation } from 'app/shared/util/i18n';

type EquipmentAddEditProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  equipment: EquipmentCustom;
};

const EquipmentAddEdit = ({ isOpen, onClose, onSave, equipment }: EquipmentAddEditProps) => {
  const dispatch = useAppDispatch();
  const refEquipmentTypes = useAppSelector(state => state.refEquipmentType.entities);
  const refDepartments = useAppSelector(state => state.refDepartment.entities);

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(translate('entity.validation.required')),
    type: Yup.string(),
    status: Yup.string().required(translate('entity.validation.required')),
    refDepartment: Yup.object(),
    refEquipmentType: Yup.object(),
  });

  const formik = useFormik({
    initialValues: {
      name: equipment?.name,
      type: equipment?.type,
      status: equipment?.status,
      refDepartment: equipment?.refDepartment,
      refEquipmentType: equipment?.refEquipmentType,
    },
    validationSchema,
    onSubmit: values => submitForm(values),
  });

  useEffect(() => {
    formik.setValues({
      name: equipment?.name,
      type: equipment?.type,
      status: equipment?.status,
      refDepartment: equipment?.refDepartment,
      refEquipmentType: equipment?.refEquipmentType,
    });
    getAllRefEquipmentType();
    getAllRefDepartments();
  }, [isOpen, equipment]);

  const getAllRefDepartments = () => {
    dispatch(getRefDepartments({ sort: 'name,asc' }));
  };
  const getAllRefEquipmentType = () => {
    dispatch(getRefEquipmentTypes({ sort: 'name,asc' }));
  };

  const submitForm = async (values: Yup.InferType<typeof validationSchema>) => {
    if (!loading) {
      const req: EquipmentCustom = {
        id: equipment?.id,
        name: values.name,
        type: values.type,
        status: values.status,
        refEquipmentType: values.refEquipmentType,
        refDepartment: values.refDepartment,
      };

      setLoading(true);
      try {
        if (equipment?.id) {
          await dispatch(updateEntity(req));
        } else {
          await dispatch(createEntity(req));
        }
        onSave();
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      size="lg"
      title={`${translate(equipment?.id ? 'rosterApp.equipment.home.createOrEditLabel' : 'rosterApp.equipment.home.createLabel')}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
          <div className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <ValidationInput formik={formik} label={translate('rosterApp.equipment.name')} field="name" placeholder="RTG3" />
            </div>
            <div className="sm:col-span-3">
              <ValidationInput formik={formik} label={translate('rosterApp.equipment.type')} field="type" placeholder="RTG" />
            </div>
            <div className="sm:col-span-3">
              <ValidationSelect
                formik={formik}
                options={ALL_EQUIPMENT_STATUS_VALUES.map(opt => ({ key: opt, value: getEquipmentStatusI18nTranslation(opt) }))}
                optionsKey="key"
                optionsValue="value"
                label={translate('rosterApp.equipment.status')}
                field="status"
                object={false}
              />
            </div>
            <div className="sm:col-span-3">
              <ValidationSelect
                formik={formik}
                options={refDepartments}
                optionsKey="id"
                optionsValue="name"
                label={translate('rosterApp.equipment.refDepartment')}
                field="refDepartment"
              />
            </div>
            <div className="sm:col-span-3">
              <ValidationSelect
                formik={formik}
                options={refEquipmentTypes}
                optionsKey="id"
                optionsValue="name"
                label={translate('rosterApp.equipment.refEquipmentType')}
                field="refEquipmentType"
              />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2 justify-end p-3 md:p-4 border-t border-gray-200 rounded-b bg-navyBlue">
          <Button onClick={onClose} type="button" variant="outline">
            {translate('entity.action.cancel')}
          </Button>
          <Button type="submit" disabled={loading}>
            {loading && <FontAwesomeIcon icon={faSpinner} spin={loading} />}
            {translate('entity.action.save')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default EquipmentAddEdit;
