import { IResource } from 'app/shared/model/resource.model';
import { IShift } from 'app/shared/model/shift.model';

export interface IResourcePlanRequest {
  id?: number;
  requestRef?: string | null;
  type?: string | null;
  status?: string | null;
  resource?: IResource | null;
  shift?: IShift | null;
}

export const defaultValue: Readonly<IResourcePlanRequest> = {};
