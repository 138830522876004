import React, { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getShiftDetails, setActiveView, setCalendarPlanningMode } from 'app/modules/planning/planning.reducer';
import Tooltip from 'app/shared/components/tooltip/Tooltip';
import { ResourceShiftModel } from 'app/shared/model/custom/custom-shift-resource.model';
import { getCalendarCellBgColorById } from 'app/shared/util/color-utils';
import { convertDateTimeFromServer, formatDateWithTimezone, isSameDay } from 'app/shared/util/date-utils';
import clsx from 'clsx';
import { initializeSelectCalendarDate, updateSelectedCalendarDate } from '../calendar.reducer';
import { selectResourceShifts, selectSelectedCalendarDates } from '../calendar.selector';

interface CalendarBodyCellProps {
  date: Date;
  isInVacation?: boolean;
}

const CalendarBodyCell = ({ date, isInVacation = false }: CalendarBodyCellProps) => {
  const dispatch = useAppDispatch();
  const calendarSelector = useAppSelector(selectResourceShifts);
  const selectedCalendarDates = useAppSelector(selectSelectedCalendarDates);

  const resourceShifts: ResourceShiftModel = calendarSelector.resourceShifts;
  const shiftsWithResourcePlan = resourceShifts.shiftsWithResourcePlan;
  const shiftsWithTeamPlan = resourceShifts.shiftsWithTeamPlan;

  const isDateSelected = useMemo(() => {
    const formatedDate = formatDateWithTimezone(date, 'YYYY-MM-DD');
    return selectedCalendarDates.startDate === formatedDate || selectedCalendarDates.endDate === formatedDate;
  }, [selectedCalendarDates, date]);

  const onResourceShiftClick = async (shiftId: number) => {
    await dispatch(getShiftDetails({ shiftId }));
    await dispatch(setCalendarPlanningMode('SHIFT_DETAILS_VIEW'));
    await dispatch(setActiveView('planning'));
    await dispatch(initializeSelectCalendarDate({}));
  };
  const onCellClick = () => {
    dispatch(updateSelectedCalendarDate(formatDateWithTimezone(date, 'YYYY-MM-DD')));
  };

  return (
    <td
      className={`min-w-20 relative border border-gray-200 p-1 cursor-pointer calendar-body-cell ${
        isDateSelected ? 'calendar-body-cell-selected' : ''
      }`}
      onClick={onCellClick}
    >
      <div className="absolute left-0 top-0 w-full h-full flex flex-col justify-between z-10">
        {date && (
          <span className={`text-right mb-1 mr-1 text-sm ${isSameDay(date, new Date()) ? 'font-black' : ''}`}>{date.getDate()}</span>
        )}

        {shiftsWithResourcePlan &&
          shiftsWithResourcePlan.length > 0 &&
          shiftsWithResourcePlan
            .filter(shift => shift.status === 'PUBLISHED')
            .map((shift, index) => {
              if (isSameDay(date, new Date(convertDateTimeFromServer(shift.startTime)))) {
                const shiftStartTimeStr = convertDateTimeFromServer(shift.startTime).toString();
                const tooltipTexts = [
                  `Equipment: ${shift.equipment ? shift.equipment.name : 'None'}`,
                  `Department: ${shift.organigram.refDepartment ? shift.organigram.refDepartment.name : 'None'}`,
                  `Position: ${shift.organigram.refPosition ? shift.organigram.refPosition.name : 'None'}`,
                ];
                return (
                  <Tooltip key={index + shiftStartTimeStr} parentClassName="w-full" texts={tooltipTexts}>
                    <div
                      className={clsx(
                        'rounded-md border-white border-[1px] mx-2 font-bold text-sm resource-shift-btn',
                        shift?.bgColor || 'bg-indigo-700',
                        shift?.bgHoverColor ? `hover:${shift.bgHoverColor}` : 'hover:bg-indigo-800',
                      )}
                    >
                      <div className="w-full py-0.5 px-1 text-center cursor-pointer" onClick={() => onResourceShiftClick(shift.id)}>
                        <span>{shift.refShiftType.name}</span>
                      </div>
                    </div>
                  </Tooltip>
                );
              }
            })}

        <div className="mt-4"></div>
      </div>
      <div className="absolute left-0 top-0 w-full h-full">
        <div className="w-[calc(100%-4px)]  h-[calc(100%-4px)] m-[2px] flex flex-col">
          {shiftsWithTeamPlan?.map((shift, index) => {
            if (isSameDay(date, new Date(convertDateTimeFromServer(shift?.startTime)))) {
              const shiftStartTimeStr = convertDateTimeFromServer(shift?.startTime)?.toString();
              return (
                <div
                  key={index + shiftStartTimeStr}
                  className={`h-full w-full opacity-20 ${getCalendarCellBgColorById(shift?.refShiftType?.id)}`}
                >
                  {isInVacation && (
                    <div
                      className="relative w-full h-full bg-center bg-no-repeat bg-contain"
                      style={{ backgroundImage: "url('content/images/vacation.png')" }}
                    ></div>
                  )}
                </div>
              );
            }
          })}
          {shiftsWithTeamPlan?.length === 0 && isInVacation && (
            <div
              className="relative w-full h-full bg-center bg-no-repeat bg-contain"
              style={{ backgroundImage: "url('content/images/vacation.png')" }}
            ></div>
          )}
        </div>
      </div>
    </td>
  );
};

export default CalendarBodyCell;
