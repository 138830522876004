import { ActivityCategory, EquipmentStatus, LeaveStatus, OrganizationStatus, OrganizationType, PositionType, ResourceType } from '../enums';
import { translate } from 'react-jhipster';

export const getActivityCategoryI18nTranslation = (category?: ActivityCategory) => {
  return category ? translate(`rosterApp.activity.enum.category.${category}`) : '';
};

export const getEquipmentStatusI18nTranslation = (status?: EquipmentStatus) => {
  return status ? translate(`rosterApp.equipment.enum.status.${status}`) : '';
};

export const getLeaveStatusI18nTranslation = (status?: LeaveStatus) => {
  return status ? translate(`rosterApp.leave.enum.status.${status}`) : '';
};

export const getOrganizationStatusI18nTranslation = (status?: OrganizationStatus) => {
  return status ? translate(`rosterApp.organisation.enum.status.${status}`) : '';
};

export const getOrganizationTypeI18nTranslation = (type?: OrganizationType) => {
  return type ? translate(`rosterApp.organisation.enum.type.${type}`) : '';
};

export const getPositionTypeI18nTranslation = (type?: PositionType) => {
  return type ? translate(`rosterApp.refPosition.enum.type.${type}`) : '';
};

export const getResourceTypeI18nTranslation = (type?: ResourceType) => {
  return type ? translate(`rosterApp.resource.enum.type.${type}`) : '';
};
