import { faBars, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Translate } from 'react-jhipster';
import * as JhipsterMenu from 'app/entities/menu';

const EntitiesMenu = () => {
  const dropdownRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef}>
      <div onClick={() => setIsMenuOpen(!isMenuOpen)} className={`dropdown-item cursor-pointer ${isMenuOpen && 'active'}`}>
        <FontAwesomeIcon icon={faBars} fixedWidth className="mx-2" />
        <span className="mr-2">
          <Translate contentKey={'global.menu.entities.main'} />
        </span>
      </div>
      {isMenuOpen && (
        <div className="absolute top-1 mr-2 text-xl right-full text-slate-400">
          <FontAwesomeIcon icon={faCaretRight} />
        </div>
      )}
      <div
        className="absolute top-0 right-full mr-4 bg-white rounded-md shadow-md overflow-y-auto custom-scrollbar"
        style={{ maxHeight: '60vh' }}
      >
        {isMenuOpen && <JhipsterMenu.default />}
      </div>
    </div>
  );
};

export default EntitiesMenu;
