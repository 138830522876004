import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getRefTrainings } from 'app/entities/ref-training/ref-training.reducer';
import { getEntities as getResources } from 'app/entities/resource/resource.reducer';
import { Button } from 'app/shared/components/button';
import Modal from 'app/shared/components/modal/Modal';
import ValidationInput from 'app/shared/components/validation/validation-input';
import ValidationSelect from 'app/shared/components/validation/validation-select';
import { IResourceTraining } from 'app/shared/model/resource-training.model';
import { convertDateTimeToServer } from 'app/shared/util/date-utils';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { translate } from 'react-jhipster';
import * as Yup from 'yup';
import { createEntity, updateEntity } from './resource-training.reducer';

type ResourceTrainingAddEditProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  resourceTraining: IResourceTraining;
};

const ResourceTrainingAddEdit = ({ isOpen, onClose, onSave, resourceTraining }: ResourceTrainingAddEditProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    resource: Yup.object().required(translate('entity.validation.required')),
    refTraining: Yup.object().required(translate('entity.validation.required')),
    activeFrom: Yup.date().required(translate('entity.validation.required')),
    activeTo: Yup.date().required(translate('entity.validation.required')),
    level: Yup.string().optional(),
    trainer: Yup.string().optional(),
  });

  const resources = useAppSelector(state =>
    state.resource.entities.slice().sort((a, b) => {
      const nameA = a?.name || '';
      const nameB = b?.name || '';
      return nameA.localeCompare(nameB);
    }),
  );

  const refTrainings = useAppSelector(state =>
    state.refTraining.entities.slice().sort((a, b) => {
      const nameA = a?.name || '';
      const nameB = b?.name || '';
      return nameA.localeCompare(nameB);
    }),
  );

  const formik = useFormik({
    initialValues: {
      resource: resourceTraining?.resource ?? null,
      refTraining: resourceTraining?.refTraining ?? null,
      activeFrom: resourceTraining?.activeFrom ?? null,
      activeTo: resourceTraining?.activeTo ?? null,
      level: resourceTraining?.level ?? '',
      trainer: resourceTraining?.trainer ?? '',
    },
    validationSchema,
    onSubmit: values => submitForm(values),
    enableReinitialize: true,
  });

  useEffect(() => {
    formik.setValues({
      resource: resourceTraining?.resource ?? null,
      refTraining: resourceTraining?.refTraining ?? null,
      activeFrom: resourceTraining?.activeFrom ?? null,
      activeTo: resourceTraining?.activeTo ?? null,
      level: resourceTraining?.level ?? '',
      trainer: resourceTraining?.trainer ?? '',
    });
  }, [isOpen, resourceTraining]);

  useEffect(() => {
    dispatch(getResources({ page: 0, size: 10000 }));
    dispatch(getRefTrainings({}));
  }, [isOpen]);

  const submitForm = async values => {
    if (!loading) {
      const payload: IResourceTraining = {
        id: resourceTraining?.id,
        activeFrom: convertDateTimeToServer(values?.activeFrom),
        activeTo: convertDateTimeToServer(values?.activeTo),
        level: values?.level,
        trainer: values?.trainer,
        refTraining: values?.refTraining,
        resource: values?.resource,
      };

      setLoading(true);
      try {
        if (resourceTraining?.id) {
          await dispatch(updateEntity(payload));
        } else {
          await dispatch(createEntity(payload));
        }
        onSave();
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      size="lg"
      title={`${translate(
        resourceTraining?.id ? 'rosterApp.resourceTraining.home.createOrEditLabel' : 'rosterApp.resourceTraining.home.createLabel',
      )}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
          <div className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <ValidationSelect
                formik={formik}
                options={resources}
                optionsKey="id"
                optionsValue="name"
                label={translate('rosterApp.resourceTraining.resource')}
                field="resource"
                object
              />
            </div>
            <div className="sm:col-span-3">
              <ValidationSelect
                formik={formik}
                options={refTrainings}
                optionsKey="id"
                optionsValue="name"
                label={translate('rosterApp.resourceTraining.refTraining')}
                field="refTraining"
                object
              />
            </div>
            <div className="sm:col-span-3">
              <ValidationInput formik={formik} label={translate('rosterApp.resourceTraining.activeFrom')} type="date" field="activeFrom" />
            </div>
            <div className="sm:col-span-3">
              <ValidationInput formik={formik} label={translate('rosterApp.resourceTraining.activeTo')} type="date" field="activeTo" />
            </div>
            <div className="sm:col-span-3">
              <ValidationInput formik={formik} label={translate('rosterApp.resourceTraining.level')} field="level" />
            </div>
            <div className="sm:col-span-3">
              <ValidationInput formik={formik} label={translate('rosterApp.resourceTraining.trainer')} field="trainer" />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2 justify-end p-3 md:p-4 border-t border-gray-200 rounded-b bg-navyBlue">
          <Button variant="outline" type="button" onClick={onClose}>
            {translate('entity.action.cancel')}
          </Button>
          <Button type="submit" disabled={loading}>
            {loading && <FontAwesomeIcon icon={faSpinner} spin={loading} />}
            {translate('entity.action.save')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ResourceTrainingAddEdit;
