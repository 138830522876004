import dayjs from 'dayjs';
import { IRefRotation } from 'app/shared/model/ref-rotation.model';
import { IShift } from 'app/shared/model/shift.model';

export interface IRefShiftType {
  id?: number;
  name?: string;
  startTime?: dayjs.Dayjs | null;
  endTime?: dayjs.Dayjs | null;
  working?: boolean | null;
  parent?: IRefShiftType | null;
  refRotations?: IRefRotation[] | null;
  shifts?: IShift[] | null;
}

export const defaultValue: Readonly<IRefShiftType> = {
  working: false,
};
