import { REPORT_FORMAT } from '../types/types';

const formatExtensionRecord: Record<REPORT_FORMAT, string> = {
  PDF: '.pdf',
  EXCEL: '.xlsx',
};
export const getFileExtension = (format: REPORT_FORMAT): string => {
  return formatExtensionRecord[format];
};

export const getImageUrl = (avatarContentType, avatar) => (avatarContentType && avatar ? `data:${avatarContentType};base64,${avatar}` : '');
