import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Button } from 'app/shared/components/button';
import Modal from 'app/shared/components/modal/Modal';
import ValidationInput from 'app/shared/components/validation/validation-input';
import ValidationMultiSelect from 'app/shared/components/validation/validation-multi-select';
import ValidationSelect from 'app/shared/components/validation/validation-select';
import { ALL_POSITION_TYPE_VALUES } from 'app/shared/enums/PositionTypeEnum';
import Position from 'app/shared/model/custom/custom-position.model';
import { getPositionTypeI18nTranslation } from 'app/shared/util/i18n';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { translate } from 'react-jhipster';
import * as Yup from 'yup';
import { getEntities as getTrainings } from '../ref-training/ref-training.reducer';
import { createCustomEntity, updateCustomEntity } from './ref-position.reducer';

type PositionAddEditProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  position: Position;
};

const PositionAddEdit = ({ isOpen, onClose, onSave, position }: PositionAddEditProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const trainings = useAppSelector(state => state.refTraining.entities);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(translate('entity.validation.required')),
    description: Yup.string().required(translate('entity.validation.required')),
    type: Yup.string().required(translate('entity.validation.required')),
    workHoursMax: Yup.string(),
    restHoursMin: Yup.string(),
    trainings: Yup.array(),
  });

  const formik = useFormik({
    initialValues: {
      name: position?.name || '',
      description: position?.description || '',
      type: position?.type || '',
      workHoursMax: position?.workHoursMax || '',
      restHoursMin: position?.restHoursMin || '',
      trainings: position?.trainings || [],
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      submitForm(values);
    },
  });

  useEffect(() => {
    formik.resetForm();
    formik.setValues({
      name: position?.name || '',
      description: position?.description || '',
      type: position?.type || '',
      workHoursMax: position?.workHoursMax || 0,
      restHoursMin: position?.restHoursMin || 0,
      trainings: position?.trainings || [],
    });
  }, [isOpen, position]);

  useEffect(() => {
    getAllTrainings();
  }, [isOpen]);

  const getAllTrainings = () => {
    dispatch(getTrainings({ sort: 'name,asc' }));
  };

  const submitForm = async values => {
    if (!loading) {
      // Log the values here
      let newPosition = {
        id: position?.id,
        name: values.name,
        description: values.description,
        type: values.type,
        workHoursMax: values.workHoursMax,
        restHoursMin: values.restHoursMin,
        trainings: values.trainings,
      };
      setLoading(true);
      try {
        if (position?.id) {
          await dispatch(updateCustomEntity(newPosition));
        } else {
          await dispatch(createCustomEntity(newPosition));
        }
        onSave();
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      size="lg"
      title={`${translate(position?.id ? 'rosterApp.refPosition.home.createOrEditLabel' : 'rosterApp.refPosition.home.createLabel')}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      {loading ? (
        <p>{translate('global.loading')}</p>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className="p-3 md:p-4 space-y-3 overflow-y-auto max-h-[70vh]">
            <ValidationInput formik={formik} label={translate('rosterApp.refPosition.name')} field="name" placeholder="Name" />
            <ValidationInput
              formik={formik}
              label={translate('rosterApp.refPosition.description')}
              field="description"
              placeholder="Description"
            />
            <ValidationSelect
              formik={formik}
              options={ALL_POSITION_TYPE_VALUES.map(opt => ({ key: opt, value: getPositionTypeI18nTranslation(opt) }))}
              optionsKey="key"
              optionsValue="value"
              label={translate('rosterApp.refPosition.type')}
              field="type"
              object={false}
            />
            <ValidationInput
              formik={formik}
              label={translate('rosterApp.refPosition.workHoursMax')}
              field="workHoursMax"
              placeholder="0"
              type="number"
            />
            <ValidationInput
              formik={formik}
              label={translate('rosterApp.refPosition.restHoursMin')}
              field="restHoursMin"
              placeholder="0"
              type="number"
            />
            <ValidationMultiSelect
              formik={formik}
              options={trainings}
              label="Trainings"
              field="trainings"
              optionsKey="id"
              optionsValue="name"
            />
          </div>
          <div className="flex items-center justify-end p-3 md:p-4 border-t gap-2 border-gray-200 rounded-b bg-navyBlue">
            <Button variant="outline" onClick={onClose} type="button">
              {translate('entity.action.cancel')}
            </Button>
            <Button type="submit" disabled={loading}>
              {loading && <FontAwesomeIcon icon={faSpinner} spin={loading} />}
              {translate('entity.action.save')}
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default PositionAddEdit;
