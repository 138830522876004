import Shift from '@model/custom/custom-shift.model';
import { isShiftHappenInDay } from '@util/date-utils';
import Department from 'app/shared/model/custom/custom-department.model';
import { IEquipment } from 'app/shared/model/equipment.model';
import { ShiftMode } from 'app/shared/types/types';
import { Dayjs } from 'dayjs';
import React from 'react';
import Day, { DayProps } from '../Day/Day';

type DaysPropsType = Pick<
  DayProps,
  | 'departments'
  | 'addResourcePlan'
  | 'updateResourcePlan'
  | 'deleteResourcePlan'
  | 'changedResourcePlans'
  | 'setChangedResourcePlans'
  | 'onShiftModeSelect'
  | 'onResourcePlanSave'
> & { selectedDate: Date };

type MonthProps = {
  monthSlot: Dayjs[];
  shifts?: Shift[];
  equipments: IEquipment[];
  departments: Department[];
  setEquipmentUnfolded: (departmentId: number, value: boolean) => void;
  planEquipment?: boolean;
  setPlanEquipment: (value: boolean) => void;
  setShiftMode?: (shiftId: number, mode: ShiftMode) => void;
} & DaysPropsType;

const Month = ({
  monthSlot,
  shifts = [],
  equipments = [],
  selectedDate,
  departments,
  setEquipmentUnfolded,
  planEquipment = false,
  setPlanEquipment,
  setShiftMode,
  ...daysProps
}: MonthProps) => {
  const getShiftsByDay = (day: Dayjs): Shift[] => {
    return shifts.filter(shift => isShiftHappenInDay(shift.startTime, day));
  };

  const gridTailwingClass = `grid-cols-${monthSlot.length}`;
  return (
    <div className="overflow-y-auto custom-scrollbar w-full overflow-unset min-w-[700px]">
      <div className={`grid ${gridTailwingClass} flex-grow flex-shrink flex-basis-0 w-full overflow-hidden overflow-unset`}>
        {monthSlot.map((day, i) => (
          <Day
            day={day}
            key={i}
            yesterdayShifts={i !== 0 && getShiftsByDay(monthSlot[i - 1])}
            todayShifts={getShiftsByDay(day)}
            {...daysProps}
            selectedDate={selectedDate}
            equipments={equipments}
            departments={departments}
            setEquipmentUnfolded={setEquipmentUnfolded}
            setPlanEquipment={setPlanEquipment}
            planEquipment={planEquipment}
            setShiftMode={setShiftMode}
          />
        ))}
      </div>
    </div>
  );
};

export default Month;
