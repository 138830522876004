import React, { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import LeaveAddEdit from 'app/entities/leave/leave-add-edit';
import { ILeave } from 'app/shared/model/leave.model';
import { IResource } from 'app/shared/model/resource.model';
import { getCurrentUser } from 'app/shared/reducers/authentication.selector';
import { convertDateTimeToServer } from 'app/shared/util/date-utils';
import { initializeSelectCalendarDate } from '../calendar.reducer';
import { selectSelectedCalendarDates, selectSelectedResource } from '../calendar.selector';
import { getEntities } from './../../../entities/leave/leave.reducer';

import CalendarBody from './CalendarBody';
import CalendarHead from './CalendarHead';

const CalendarTable = () => {
  const dispatch = useAppDispatch();
  const selectedCalendarDates = useAppSelector(selectSelectedCalendarDates);
  const selectedResource = useAppSelector(selectSelectedResource);
  const currentUser = useAppSelector(getCurrentUser);

  const currentSelectedResource = useMemo(() => selectedResource ?? (currentUser as IResource), [selectedResource, currentUser]);

  const isLeavePopupOpen = useMemo(() => !!selectedCalendarDates.startDate && !!selectedCalendarDates.endDate, [selectedCalendarDates]);

  const handleLeavePopupClose = () => {
    dispatch(initializeSelectCalendarDate({}));
  };

  const handleLeaveSave = () => {
    dispatch(
      getEntities({
        page: 0,
        size: 1000,
      }),
    );
    handleLeavePopupClose();
  };

  const selectedLeave = useMemo(() => {
    const leave: ILeave = {
      resource: currentSelectedResource,
      status: 'DRAFT',
      startTime: convertDateTimeToServer(selectedCalendarDates.startDate),
      endTime: convertDateTimeToServer(selectedCalendarDates.endDate),
    };
    return leave;
  }, [selectedCalendarDates, currentSelectedResource]);

  return (
    <>
      <table className="min-w-full h-full border table-auto">
        <CalendarHead />
        <CalendarBody />
      </table>
      <LeaveAddEdit isOpen={isLeavePopupOpen} onClose={handleLeavePopupClose} onSave={handleLeaveSave} leave={selectedLeave} />
    </>
  );
};

export default CalendarTable;
