import { IRefPosition } from 'app/shared/model/ref-position.model';
import { IRefDepartment } from 'app/shared/model/ref-department.model';
import { IResourcePlan } from 'app/shared/model/resource-plan.model';
import { IShiftDemand } from 'app/shared/model/shift-demand.model';
import { IDemandTemplate } from 'app/shared/model/demand-template.model';
import { IEquipmentPlan } from 'app/shared/model/equipment-plan.model';

export interface IOrganigram {
  id?: number;
  demandCoefficient?: number | null;
  demandConstant?: number | null;
  demandMin?: number | null;
  refPosition?: IRefPosition | null;
  refDepartment?: IRefDepartment | null;
  resourcePlans?: IResourcePlan[] | null;
  shiftDemands?: IShiftDemand[] | null;
  demandTemplates?: IDemandTemplate[] | null;
  equipmentPlans?: IEquipmentPlan[] | null;
}

export const defaultValue: Readonly<IOrganigram> = {};
