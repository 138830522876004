import { APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';
import { formatDateWithUTC } from 'app/shared/util/date-utils';
import React, { useCallback, useEffect, useRef } from 'react';

const ValidationDateTimeInput = ({
  formik,
  label,
  field,
  placeholder = '',
  autoFocus = false,
  disabled = false,
  inputType = 'datetime-local', // Default to 'datetime-local', can be passed as 'date'
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const isNotValid = () => {
    return formik.touched[field] && formik.errors[field];
  };

  const getInputValue = useCallback(() => {
    const format = inputType === 'date' ? 'YYYY-MM-DD' : APP_LOCAL_DATETIME_FORMAT;
    return formatDateWithUTC(formik.values[field], format);
  }, [formik.values[field], inputType]);

  return (
    <div className="mb-4">
      <label htmlFor={field} className={`${isNotValid() ? 'text-red-600' : 'text-gray-900'} block text-sm font-medium`}>
        {label}
      </label>
      <input
        ref={inputRef}
        onChange={formik.handleChange}
        value={getInputValue()}
        type={inputType} // Dynamic input type based on prop
        name={field}
        id={field}
        autoComplete={field}
        placeholder={placeholder}
        disabled={disabled}
        className={`${
          isNotValid() ? 'border-red-500' : ''
        } mt-1 shadow-sm bg-gray-50 border-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
      />
      {isNotValid() && <p className="m-0 text-xs text-red-500">{formik.errors[field]}</p>}
    </div>
  );
};

export default ValidationDateTimeInput;
