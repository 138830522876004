import { faCircleXmark, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

const ValidationBlob = ({ formik, label, field, contentTypeField = '' }) => {
  const [error, setError] = useState('');

  const handleFileChange = e => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      setError('Please select a file.');
      return;
    }

    // Validate file type
    if (!selectedFile.type.startsWith('image/')) {
      setError('Please select an image file.');
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setError('');
      formik.setFieldValue(field, reader.result);
      formik.handleChange(field);
    };
    reader.readAsDataURL(selectedFile);
  };

  const isNotValid = () => {
    return formik.touched[field] && formik.errors[field];
  };

  const getImageUrl = (avatarContentType = null, avatar) => {
    if (avatar.includes(',') || !avatarContentType) {
      return avatar;
    }
    return `data:${avatarContentType};base64,${avatar}`;
  };

  const emptyImage = () => {
    formik.setFieldValue(field, '');
    formik.handleChange(field);
  };

  return (
    <div className="mt-1">
      <label htmlFor={field} className={`${isNotValid() ? 'text-red-600' : 'text-gray-900'} block text-sm font-medium `}>
        {label}
      </label>

      <input type="file" onChange={handleFileChange} className="hidden" id="fileInput" accept="image/*" />

      {!formik.values[field] && (
        <label htmlFor="fileInput" className="flex cursor-pointer w-full border border-slate-200 bg-blue-50 items-center rounded-lg">
          <label
            htmlFor="fileInput"
            className="cursor-pointer text-sm bg-slate-800 hover:bg-slate-700 text-white py-2 px-5 rounded-l-lg inline-block"
          >
            Select File
          </label>
          <span className="ml-3 text-sm">No file selected</span>
        </label>
      )}
      {formik.values[field] && (
        <label htmlFor="fileInput" className="flex items-center justify-center">
          <div className="relative flex items-center justify-center">
            <div className="cursor-pointer absolute w-full h-full rounded-full flex items-center justify-center bg-slate-900 bg-opacity-60 opacity-5 hover:opacity-100">
              <FontAwesomeIcon className="text-white z-50 text-lg " icon={faUpload} />
            </div>
            <div onClick={emptyImage} className="absolute top-1.5 right-1.5">
              <FontAwesomeIcon icon={faCircleXmark} className="text-red-500 text-2xl rounded-full bg-white cursor-pointer" />
            </div>
            <img
              src={getImageUrl(contentTypeField ? formik.values[contentTypeField] : null, formik.values[field])}
              alt="Selected"
              className="h-28 w-28 rounded-full"
            />
          </div>
        </label>
      )}

      {isNotValid() || error ? <p className="m-0 text-xs text-red-500">{error ? error : formik.errors[field]}</p> : null}
    </div>
  );
};

export default ValidationBlob;
