import { IUser } from 'app/shared/model/user.model';
import { ITeam } from 'app/shared/model/team.model';
import { IRefDepartment } from 'app/shared/model/ref-department.model';
import { IFacility } from 'app/shared/model/facility.model';
import { IResourcePlan } from 'app/shared/model/resource-plan.model';
import { IResourceTraining } from 'app/shared/model/resource-training.model';
import { ILeave } from 'app/shared/model/leave.model';
import { IEquipmentPlan } from 'app/shared/model/equipment-plan.model';
import { IResourcePlanRequest } from 'app/shared/model/resource-plan-request.model';

export interface IResource {
  id?: number;
  name?: string;
  firstName?: string | null;
  lastName?: string | null;
  teamRole?: string | null;
  exchangeAllowed?: boolean | null;
  avatarContentType?: string | null;
  avatar?: string | null;
  type?: string | null;
  user?: IUser | null;
  team?: ITeam | null;
  refDepartment?: IRefDepartment | null;
  facility?: IFacility | null;
  resourcePlans?: IResourcePlan[] | null;
  resourceTrainings?: IResourceTraining[] | null;
  leaves?: ILeave[] | null;
  equipmentPlans?: IEquipmentPlan[] | null;
  resourcePlanRequests?: IResourcePlanRequest[] | null;
}

export const defaultValue: Readonly<IResource> = {
  exchangeAllowed: false,
};
