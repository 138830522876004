import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faArrowDownWideShort,
  faArrowUpShortWide,
  faArrowsRotate,
  faPen,
  faPlus,
  faSearch,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'app/shared/components/button';
import React, { useEffect, useState } from 'react';
import { translate } from 'react-jhipster';
import Tooltip from '../tooltip/Tooltip';

export type DataTableOptionsItem = {
  key: string;
  filterable: boolean;
  filterableKey?: string;
  sortable: boolean;
  render?: (row: any) => React.ReactNode;
};

export type DataTableOptions = {
  headers: string[];
  columns: DataTableOptionsItem[];
  searchColumns?: string[];
};

type DataTableProps = {
  data: any;
  options: DataTableOptions;
  sort?: any;
  loading?: any;
  onRefresh?: any;
  onAdd?: any;
  onUpdate?: any;
  onDelete?: any;
  onSelect?: any;
  paginationState?: any;
  onPaginationClick?: any;
  paginationTotalItems?: number;
  paginationMaxButtons?: number;
  showDetails?: boolean;
  createLabel?: string;
  refreshListLabel?: string;
  notFoundLabel?: string;
  title?: string;
  hideCreateAction?: boolean;
  leftHeader?: React.ReactElement<{ onSendData: () => void }>;
  onSendData?: (data: any) => void;
  showGlobalSearch?: boolean;
  filterData?: (data: any, query: string, key: string, filterKey?: string) => any;
};

const DataTable = ({
  data,
  options,
  sort,
  loading,
  onRefresh,
  onAdd,
  onUpdate,
  onDelete,
  onSelect,
  paginationState,
  onPaginationClick,
  paginationTotalItems,
  paginationMaxButtons,
  showDetails = false,
  createLabel = 'Create new item',
  refreshListLabel = 'Refresh list',
  notFoundLabel = 'No data found',
  title = '',
  hideCreateAction = false,
  leftHeader,
  onSendData,
  showGlobalSearch = false,
  filterData,
}: DataTableProps) => {
  const [filteredData, setFilteredData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [clickTimeout, setClickTimeout] = useState(null);

  useEffect(() => {
    setFilteredData(data);
  }, [setFilteredData, data]);

  useEffect(() => {
    let order = paginationState?.order === 'asc' ? 'ascending' : 'descending';
    setSortConfig({ key: paginationState?.sort, direction: order });
  }, [paginationState]);

  const handleSort = (key?) => {
    let direction = 'ascending';
    if (key) {
      if (sortConfig && sortConfig?.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    } else {
      direction = sortConfig.direction;
    }

    sort(key);

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });
    setFilteredData(sortedData);
  };

  const handleFilter = (e, key, subKey?) => {
    const value = e.target.value.toLowerCase();
    if (filterData) {
      setFilteredData(filterData(data, value, key, subKey));
    } else {
      const filteredData = data.filter(item => {
        if (value) {
          let neededItem = subKey && item[key] ? item[key][subKey] : item[key];
          return neededItem?.toString()?.toLowerCase()?.includes(value);
        }
        return true;
      });
      setFilteredData(filteredData);
    }
  };

  const handleGlobalSearch = e => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);
    let cols = options.searchColumns ? options.searchColumns : options.columns.map(col => col.key);
    const filteredData = data.filter(item => cols.some(field => String(item[field]).toLowerCase().includes(value.toLowerCase())));
    setFilteredData(filteredData);
  };

  const handleSearch = () => {
    setSearchQuery('');
    onRefresh();
  };

  const handleSingleSelect = (row, ctrlKey, shiftKey, index) => {
    clearTimeout(clickTimeout);

    const newTimeout = setTimeout(() => {
      if (ctrlKey) {
        toggleRow(row, index);
      } else if (shiftKey) {
        if (selectedRows.length) {
          let lastIndex = selectedRows.sort((a, b) => Math.abs(a.index - index) - Math.abs(b.index - index))[0].index;
          setSelectedRows([
            ...selectedRows,
            ...filteredData.filter((r, i) => (lastIndex < index ? i > lastIndex && i <= index : i >= index && i < lastIndex)),
          ]);
        }
      } else {
        if (selectedRows[0]?.id === row?.id) {
          setSelectedRows([]);
        } else {
          setSelectedRows([{ ...row, index }]);
          if (onSelect) {
            onSelect(row);
          }
        }
      }
    }, 200);

    setClickTimeout(newTimeout);
  };

  const handleDoubleClick = (row, index) => {
    if (onUpdate) {
      clearTimeout(clickTimeout);
      setSelectedRows([{ ...row, index }]);
      onUpdate([{ ...row, index }]);
    }
  };

  const toggleRow = (row, index) => {
    if (selectedRows.some(entity => entity.id == row.id)) {
      setSelectedRows(selectedRows.filter(entity => entity.id !== row?.id));
    } else {
      setSelectedRows([...selectedRows, { ...row, index }]);
    }
  };

  return (
    <div className="flex flex-col h-full">
      {/* Header */}
      <div className="p-2 gap-2 flex items-center justify-between bg-navyBlue text-white rounded-t-md">
        {/* Search input */}
        <div className="flex items-center">
          {leftHeader && React.isValidElement(leftHeader)
            ? React.cloneElement(leftHeader, { onSendData: () => onSendData(selectedRows) })
            : null}
          {title && <h6 className="text-lg mb-0">{title}</h6>}
          {showGlobalSearch && (
            <div className="relative">
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
                <FontAwesomeIcon icon={faSearch} className="text-gray-400" />
              </span>
              <input
                type="text"
                onChange={handleGlobalSearch}
                value={searchQuery}
                className="pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring focus:ring-blue-200"
                placeholder={`${translate('entity.action.search')}...`}
              />
            </div>
          )}
        </div>
        {/* Right buttons */}
        <div className="flex items-center gap-2 text-slate-800">
          <Tooltip text={translate('entity.action.edit')}>
            <Button
              disabled={selectedRows?.length !== 1 || onSelect || !onUpdate}
              variant="outline"
              className="hover:text-yellow-500"
              onClick={() => onUpdate(selectedRows)}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
          </Tooltip>
          <Tooltip text={translate('entity.action.delete')}>
            <Button
              disabled={selectedRows?.length < 1 || onSelect || !onDelete}
              variant="outline"
              className="hover:text-red-500"
              onClick={() => onDelete(selectedRows)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Tooltip>
          <Tooltip text={refreshListLabel}>
            <Button disabled={!onRefresh} variant="outline" className="hover:text-slate-600" onClick={handleSearch}>
              <FontAwesomeIcon icon={faArrowsRotate} spin={loading} />
            </Button>
          </Tooltip>
          <Tooltip text={createLabel}>
            <Button disabled={!onAdd || hideCreateAction} onClick={onAdd}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </Tooltip>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto h-full custom-scrollbar overflow-y-hidden flex flex-col">
        <div>
          <table className={`table-auto w-full border-collapse`}>
            <thead className="show">
              <tr className="text-white rounded-tl-md rounded-tr-md">
                {/* Render header columns */}
                {options.headers.map((header, index) => (
                  <th
                    key={index}
                    className={`border border-slate-200 p-2 transition-all transform ${
                      sortConfig.key === options.columns[index].key ? 'bg-navyBlue/85' : 'bg-navyBlue'
                    }`}
                  >
                    <div className={`flex flex-col`}>
                      <div className={`flex items-center justify-between mb-2`}>
                        {header}
                        {sort && options.columns[index].sortable && (
                          <button
                            className={`ml-1 text-sm font-medium  focus:outline-none text-white hover:text-skyBlue`}
                            onClick={() => handleSort(options.columns[index].key)}
                          >
                            {sortConfig.key === options.columns[index].key ? (
                              <FontAwesomeIcon icon={sortConfig.direction === 'ascending' ? faArrowUpShortWide : faArrowDownWideShort} />
                            ) : (
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="p-icon p-sortable-column-icon"
                              >
                                <g clipPath="url(#pn_id_349)">
                                  <path
                                    d="M5.64515 3.61291C5.47353 3.61291 5.30192 3.54968 5.16644 3.4142L3.38708 1.63484L1.60773 3.4142C1.34579 3.67613 0.912244 3.67613 0.650309 3.4142C0.388374 3.15226 0.388374 2.71871 0.650309 2.45678L2.90837 0.198712C3.17031 -0.0632236 3.60386 -0.0632236 3.86579 0.198712L6.12386 2.45678C6.38579 2.71871 6.38579 3.15226 6.12386 3.4142C5.98837 3.54968 5.81676 3.61291 5.64515 3.61291Z"
                                    fill="currentColor"
                                  ></path>
                                  <path
                                    d="M3.38714 14C3.01681 14 2.70972 13.6929 2.70972 13.3226V0.677419C2.70972 0.307097 3.01681 0 3.38714 0C3.75746 0 4.06456 0.307097 4.06456 0.677419V13.3226C4.06456 13.6929 3.75746 14 3.38714 14Z"
                                    fill="currentColor"
                                  ></path>
                                  <path
                                    d="M10.6129 14C10.4413 14 10.2697 13.9368 10.1342 13.8013L7.87611 11.5432C7.61418 11.2813 7.61418 10.8477 7.87611 10.5858C8.13805 10.3239 8.5716 10.3239 8.83353 10.5858L10.6129 12.3652L12.3922 10.5858C12.6542 10.3239 13.0877 10.3239 13.3497 10.5858C13.6116 10.8477 13.6116 11.2813 13.3497 11.5432L11.0916 13.8013C10.9561 13.9368 10.7845 14 10.6129 14Z"
                                    fill="currentColor"
                                  ></path>
                                  <path
                                    d="M10.6129 14C10.2426 14 9.93552 13.6929 9.93552 13.3226V0.677419C9.93552 0.307097 10.2426 0 10.6129 0C10.9833 0 11.2904 0.307097 11.2904 0.677419V13.3226C11.2904 13.6929 10.9832 14 10.6129 14Z"
                                    fill="currentColor"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="url(#pn_id_349)">
                                    <rect width="14" height="14" fill="white"></rect>
                                  </clipPath>
                                </defs>
                              </svg>
                            )}
                          </button>
                        )}
                      </div>
                      {options.columns[index].filterable && (
                        <input
                          type="text"
                          onChange={e => handleFilter(e, options.columns[index].key, options.columns[index].filterableKey)}
                          className="block py-1 px-2 text-sm rounded-md text-black outline-none border-1 font-normal border-slate-300 focus:border-blue-500 focus:shadow-sm transition-all"
                          placeholder={`Filter ${header}`}
                        />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="show collapse">
              {/* Render content */}
              {filteredData.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  onClick={e => handleSingleSelect(row, e.ctrlKey, e.shiftKey, rowIndex)}
                  onDoubleClick={() => handleDoubleClick(row, rowIndex)}
                  className={`active:bg-slate-300 cursor-pointer ${
                    selectedRows.some(r => r.id === row.id)
                      ? `bg-blue-200 text-blue-600`
                      : `hover:bg-slate-200 ${rowIndex % 2 === 0 ? 'hover:bg-slate-200 bg-white' : 'bg-slate-50'}`
                  }`}
                >
                  {options.columns.map((column, colIndex) => (
                    <td key={colIndex} className="border border-slate-200 p-2">
                      {column.render ? column.render(row) : row[column.key]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Loading / Not Found */}
        {!data || !data.length ? (
          <div className="flex flex-grow: 1; items-center justify-center w-full h-full min-h-28">
            {loading ? (
              <div className="flex flex-col items-center" role="status">
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 mb-1 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span>{translate('global.loading')}</span>
              </div>
            ) : (
              <strong className="font-bold">{notFoundLabel}</strong>
            )}
          </div>
        ) : (
          <div className="bg-slate-50 custom-thin-scrollbar h-full min-w-fit overflow-y-scroll">
            <table className={`table-auto w-full border-collapse`}>
              <thead className="show collapse">
                <tr className="bg-slate-100 rounded-tl-md rounded-tr-md">
                  {/* Render header columns */}
                  {options.headers.map((header, index) => (
                    <th
                      key={index}
                      className={`border border-slate-200 p-2 transition-all transform ${
                        sortConfig.key === options.columns[index].key && 'bg-blue-100 text-blue-700'
                      }`}
                    >
                      <div className={`flex flex-col`}>
                        <div className={`flex items-center justify-between mb-2`}>
                          {header}
                          {sort && options.columns[index].sortable && (
                            <button
                              className={`ml-1 text-sm font-medium  focus:outline-none ${
                                sortConfig.key === options.columns[index].key
                                  ? 'text-blue-700 hover:text-blue-800'
                                  : 'text-gray-500 hover:text-gray-700'
                              }`}
                              onClick={() => handleSort(options.columns[index].key)}
                            >
                              {sortConfig.key === options.columns[index].key ? (
                                <FontAwesomeIcon icon={sortConfig.direction === 'ascending' ? faArrowUpShortWide : faArrowDownWideShort} />
                              ) : (
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="p-icon p-sortable-column-icon"
                                >
                                  <g clipPath="url(#pn_id_349)">
                                    <path
                                      d="M5.64515 3.61291C5.47353 3.61291 5.30192 3.54968 5.16644 3.4142L3.38708 1.63484L1.60773 3.4142C1.34579 3.67613 0.912244 3.67613 0.650309 3.4142C0.388374 3.15226 0.388374 2.71871 0.650309 2.45678L2.90837 0.198712C3.17031 -0.0632236 3.60386 -0.0632236 3.86579 0.198712L6.12386 2.45678C6.38579 2.71871 6.38579 3.15226 6.12386 3.4142C5.98837 3.54968 5.81676 3.61291 5.64515 3.61291Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      d="M3.38714 14C3.01681 14 2.70972 13.6929 2.70972 13.3226V0.677419C2.70972 0.307097 3.01681 0 3.38714 0C3.75746 0 4.06456 0.307097 4.06456 0.677419V13.3226C4.06456 13.6929 3.75746 14 3.38714 14Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      d="M10.6129 14C10.4413 14 10.2697 13.9368 10.1342 13.8013L7.87611 11.5432C7.61418 11.2813 7.61418 10.8477 7.87611 10.5858C8.13805 10.3239 8.5716 10.3239 8.83353 10.5858L10.6129 12.3652L12.3922 10.5858C12.6542 10.3239 13.0877 10.3239 13.3497 10.5858C13.6116 10.8477 13.6116 11.2813 13.3497 11.5432L11.0916 13.8013C10.9561 13.9368 10.7845 14 10.6129 14Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      d="M10.6129 14C10.2426 14 9.93552 13.6929 9.93552 13.3226V0.677419C9.93552 0.307097 10.2426 0 10.6129 0C10.9833 0 11.2904 0.307097 11.2904 0.677419V13.3226C11.2904 13.6929 10.9832 14 10.6129 14Z"
                                      fill="currentColor"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="url(#pn_id_349)">
                                      <rect width="14" height="14" fill="white"></rect>
                                    </clipPath>
                                  </defs>
                                </svg>
                              )}
                            </button>
                          )}
                        </div>
                        {options.columns[index].filterable && (
                          <input
                            type="text"
                            onChange={e => handleFilter(e, options.columns[index].key)}
                            className="block py-1 px-2 text-sm rounded-md outline-none border-1 text-black font-normal border-slate-300 focus:border-blue-500 focus:shadow-sm transition-all"
                            placeholder={`Filter ${header}`}
                          />
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="show">
                {/* Render content */}
                {filteredData.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    onClick={e => handleSingleSelect(row, e.ctrlKey, e.shiftKey, rowIndex)}
                    onDoubleClick={() => handleDoubleClick(row, rowIndex)}
                    className={`active:bg-slate-300 cursor-pointer ${
                      selectedRows.some(r => r.id === row.id)
                        ? `bg-blue-200 text-blue-600`
                        : `hover:bg-slate-200 ${rowIndex % 2 === 0 ? 'bg-slate-50' : 'bg-slate-100'}`
                    }`}
                  >
                    {options.columns.map((column, colIndex) => (
                      <td key={colIndex} className="border border-slate-200 p-2">
                        {column.render ? column.render(row) : row[column.key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Footer */}
      {paginationState && (
        <div className="p-2 flex items-center justify-end bg-navyBlue text-white rounded-b-md">
          <ItemCount
            activePage={paginationState.activePage}
            totalItems={paginationTotalItems}
            itemsPerPage={paginationState.itemsPerPage}
          />
          <Pagination
            activePage={paginationState.activePage}
            onSelect={onPaginationClick}
            itemsPerPage={paginationState.itemsPerPage}
            totalItems={paginationTotalItems}
            maxButtons={paginationMaxButtons}
          />
        </div>
      )}
    </div>
  );
};

export default DataTable;

export const ItemCount = ({ activePage, totalItems, itemsPerPage }) => {
  // Calculate range of items being displayed
  const start = (activePage - 1) * itemsPerPage + 1;
  const end = Math.min(activePage * itemsPerPage, totalItems);

  return (
    <div className="flex justify-between items-center text-sm text-white">
      <div>{translate(`global.item-count`, { start, end, totalItems })}</div>
    </div>
  );
};

export const Pagination = ({ activePage, onSelect, itemsPerPage, totalItems, maxButtons = Math.ceil(totalItems / itemsPerPage) }) => {
  const [pagesNumber, setPagesNumber] = useState([]);

  useEffect(() => {
    const halfMaxPagesToShow = Math.floor(maxButtons / 2);

    let startPage = Math.max(activePage - halfMaxPagesToShow, 1);
    let endPage = Math.min(startPage + maxButtons - 1, Math.ceil(totalItems / itemsPerPage));

    const pageNumbers: number[] = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    setPagesNumber(pageNumbers);
  }, [activePage, itemsPerPage, totalItems, maxButtons]);

  return (
    <ul className="flex space-x-2 mb-0 pl-4">
      <li>
        <button
          onClick={() => onSelect(1)}
          disabled={activePage === 1}
          className="h-8 min-w-8 text-sm rounded-full text-white hover:text-skyBlue hover:bg-slate-200"
        >
          <FontAwesomeIcon icon={faAnglesLeft} />
        </button>
      </li>
      <li>
        <button
          onClick={() => onSelect(activePage - 1)}
          disabled={activePage === 1}
          className="h-8 min-w-8 text-sm rounded-full text-white hover:text-skyBlue hover:bg-slate-200"
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
      </li>
      {pagesNumber.map(page => (
        <li key={page}>
          <button
            onClick={() => onSelect(page)}
            className={`h-8 min-w-8 text-sm rounded-full ${
              activePage === page ? 'bg-blue-100 text-primary font-bold' : 'text-white hover:text-skyBlue hover:bg-slate-200'
            }`}
          >
            {page}
          </button>
        </li>
      ))}
      <li>
        <button
          onClick={() => onSelect(activePage + 1)}
          disabled={activePage === Math.ceil(totalItems / itemsPerPage)}
          className="h-8 min-w-8 text-sm rounded-full text-white hover:text-skyBlue hover:bg-slate-200"
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </li>
      <li>
        <button
          onClick={() => onSelect(Math.ceil(totalItems / itemsPerPage))}
          disabled={activePage === Math.ceil(totalItems / itemsPerPage)}
          className="h-8 min-w-8 text-sm rounded-full text-white hover:text-skyBlue hover:bg-slate-200"
        >
          <FontAwesomeIcon icon={faAnglesRight} />
        </button>
      </li>
    </ul>
  );
};
