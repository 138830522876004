import { IResourceTraining } from 'app/shared/model/resource-training.model';
import { IPositionRequirement } from 'app/shared/model/position-requirement.model';

export interface IRefTraining {
  id?: number;
  name?: string;
  description?: string | null;
  resourceTrainings?: IResourceTraining[] | null;
  positionRequirements?: IPositionRequirement[] | null;
}

export const defaultValue: Readonly<IRefTraining> = {};
