import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { languages } from 'app/config/translation';
import { getEntities as getFacilities } from 'app/entities/facility/facility.reducer';
import { getEntities as getRefDepartments } from 'app/entities/ref-department/ref-department.reducer';
import { getEntities as getRefTrainings } from 'app/entities/ref-training/ref-training.reducer';
import { getRoles } from 'app/modules/administration/user-management/user-management.reducer';
import { Button } from 'app/shared/components/button';
import Modal from 'app/shared/components/modal/Modal';
import ValidationBlob from 'app/shared/components/validation/validation-blob';
import ValidationInput from 'app/shared/components/validation/validation-input';
import ValidationMultiSelect from 'app/shared/components/validation/validation-multi-select';
import ValidationSelect from 'app/shared/components/validation/validation-select';
import { ALL_RESOURCE_TYPE_VALUES } from 'app/shared/enums';
import ResourceUserCustom from 'app/shared/model/custom/custom-resource-user.model';
import { getResourceTypeI18nTranslation } from 'app/shared/util/i18n';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { translate } from 'react-jhipster';
import * as Yup from 'yup';
import { getEntities as getTeams } from '../team/team.reducer';
import { createCustomEntity, updateCustomEntity } from './resource.reducer';

const validationSchema = Yup.object().shape({
  login: Yup.string().required(translate('entity.validation.required')),
  firstName: Yup.string().required(translate('entity.validation.required')),
  lastName: Yup.string().required(translate('entity.validation.required')),
  email: Yup.string().required(translate('entity.validation.required')).email(translate('entity.validation.email-invalid')),
  team: Yup.object(),
  type: Yup.string()
    .oneOf(ALL_RESOURCE_TYPE_VALUES, translate('entity.validation.invalid-value'))
    .required(translate('entity.validation.required')),
  profiles: Yup.array().min(1, 'Required minimum 1 profile.'),
  langKey: Yup.string().required(translate('entity.validation.required')),
  exchangeAllowed: Yup.boolean(),
  activated: Yup.boolean(),
  avatar: Yup.string().nullable(),
  avatarContentType: Yup.string(),
  department: Yup.object(),
  facility: Yup.object(),
  trainings: Yup.array(),
});

type ResourceAddEditProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  resource: ResourceUserCustom;
};

const ResourceAddEdit = ({ isOpen, onClose, onSave, resource }: ResourceAddEditProps) => {
  const dispatch = useAppDispatch();
  const profiles = useAppSelector(state => state.userManagement.authorities);
  const teams = useAppSelector(state => state.team.entities);
  const facilities = useAppSelector(state => state.facility.entities);
  const refDepartments = useAppSelector(state => state.refDepartment.entities);
  const refTrainings = useAppSelector(state => state.refTraining.entities);

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      login: resource?.name,
      firstName: resource?.firstName,
      lastName: resource?.lastName,
      email: resource?.user?.email,
      team: resource?.team,
      type: resource?.type,
      profiles: resource?.user?.authorities,
      langKey: resource?.user?.langKey,
      exchangeAllowed: resource?.exchangeAllowed,
      activated: resource?.user?.activated,
      avatar: resource?.avatar,
      avatarContentType: resource?.avatarContentType,
      department: resource?.refDepartment,
      facility: resource?.facility,
      trainings: resource?.trainings,
    },
    validationSchema,
    onSubmit: values => submitForm(values),
  });

  useEffect(() => {
    formik.setValues({
      login: resource?.name,
      firstName: resource?.firstName,
      lastName: resource?.lastName,
      email: resource?.user?.email,
      team: resource?.team,
      type: resource?.type,
      profiles: resource?.user?.authorities ? resource?.user?.authorities : [],
      langKey: resource?.user?.langKey,
      exchangeAllowed: resource?.exchangeAllowed ? resource?.exchangeAllowed : false,
      activated: resource?.user?.activated ? resource?.user?.activated : false,
      avatar: resource?.avatar,
      avatarContentType: resource?.avatarContentType,
      department: resource?.refDepartment,
      facility: resource?.facility,
      trainings: resource?.trainings || [],
    });
    getAllRoles();
    getAllTeams();
    getAllFacilities();
    getAllRefDepartments();
    getAllRefTrainings();
  }, [isOpen, resource]);

  const getAllRoles = () => {
    dispatch(getRoles());
  };

  const getAllTeams = () => {
    dispatch(getTeams({ sort: 'name,asc' }));
  };

  const getAllFacilities = () => {
    dispatch(getFacilities({ sort: 'name,asc' }));
  };
  const getAllRefDepartments = () => {
    dispatch(getRefDepartments({ sort: 'name,asc' }));
  };

  const getAllRefTrainings = () => {
    dispatch(getRefTrainings({ sort: 'name,asc' }));
  };

  const getLanguages = () => {
    return Object.keys(languages).map(key => ({ key, value: translate(`language.${key}.name`) }));
  };

  const submitForm = async values => {
    if (!loading) {
      const req = {
        id: resource?.id,
        name: values.login,
        firstName: values.firstName,
        lastName: values.lastName,
        type: values.type,
        exchangeAllowed: values.exchangeAllowed,
        team: values.team,
        refDepartment: values.department,
        facility: values.facility,
        user: {
          login: values.login,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          authorities: values.profiles,
          langKey: values.langKey,
          activated: values.activated,
        },
        avatar: values?.avatar?.includes(',') ? values.avatar.split(',')[1] : values.avatar,
        avatarContentType: resource?.avatarContentType,
        trainings: values.trainings,
      };

      if (values?.avatar?.includes(':')) {
        const fisrtIndex = values?.avatar?.indexOf(':') + 1;
        const lastIndex = values?.avatar?.indexOf(';');
        req.avatarContentType = values?.avatar?.substring(fisrtIndex, lastIndex);
      }

      setLoading(true);
      try {
        if (resource?.id) {
          await dispatch(updateCustomEntity(req));
        } else {
          await dispatch(createCustomEntity(req));
        }
        onSave();
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      size="lg"
      title={`${translate(resource?.id ? 'rosterApp.resource.home.createOrEditLabel' : 'rosterApp.resource.home.createLabel')}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
          <div className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <ValidationInput formik={formik} label={translate('rosterApp.resource.name')} field="login" />
            </div>
            <div className="sm:col-span-3">
              <ValidationInput formik={formik} label={translate('rosterApp.resource.firstName')} field="firstName" />
            </div>
            <div className="sm:col-span-3">
              <ValidationInput formik={formik} label={translate('rosterApp.resource.lastName')} field="lastName" />
            </div>
            <div className="sm:col-span-6">
              <ValidationInput formik={formik} label={translate('userManagement.email')} field="email" />
            </div>
            <div className="sm:col-span-3">
              <ValidationSelect
                formik={formik}
                options={teams}
                optionsKey="id"
                optionsValue="name"
                label={translate('rosterApp.resource.team')}
                field="team"
              />
            </div>
            <div className="sm:col-span-3">
              <ValidationSelect
                formik={formik}
                options={ALL_RESOURCE_TYPE_VALUES.map(opt => ({ key: opt, value: getResourceTypeI18nTranslation(opt) }))}
                optionsKey="key"
                optionsValue="value"
                label={translate('rosterApp.resource.type')}
                field="type"
                object={false}
              />
            </div>
            <div className="sm:col-span-3">
              <ValidationSelect
                formik={formik}
                options={refDepartments}
                optionsKey="id"
                optionsValue="name"
                label={translate('rosterApp.resource.refDepartment')}
                field="department"
              />
            </div>
            <div className="sm:col-span-3">
              <ValidationSelect
                formik={formik}
                options={facilities}
                optionsKey="id"
                optionsValue="name"
                label={translate('rosterApp.resource.facility')}
                field="facility"
              />
            </div>
            <div className="sm:col-span-6">
              <ValidationSelect
                formik={formik}
                optionsKey="key"
                optionsValue="value"
                options={getLanguages()}
                object={false}
                label={translate('userManagement.langKey')}
                field="langKey"
              />
            </div>
            <div className="sm:col-span-3">
              <ValidationMultiSelect formik={formik} options={profiles} label={translate('userManagement.profiles')} field="profiles" />
            </div>
            <div className="sm:col-span-3">
              <ValidationMultiSelect
                formik={formik}
                options={refTrainings}
                optionsKey="id"
                optionsValue="name"
                label={translate('rosterApp.resource.resourceTrainings')}
                field="trainings"
              />
            </div>
            <div className="sm:col-span-6">
              <ValidationBlob
                formik={formik}
                label={translate('rosterApp.resource.avatar')}
                field="avatar"
                contentTypeField="avatarContentType"
              />
            </div>
            <div className="sm:col-span-6">
              <ValidationInput
                formik={formik}
                label={translate('rosterApp.resource.exchangeAllowed')}
                field="exchangeAllowed"
                type="checkbox"
              />
            </div>
            <div className="sm:col-span-6">
              <ValidationInput formik={formik} label={translate('userManagement.activated')} field="activated" type="checkbox" />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2 justify-end p-3 md:p-4 border-t border-gray-200 rounded-b bg-navyBlue">
          <Button variant="outline" onClick={onClose} type="button">
            {translate('entity.action.cancel')}
          </Button>
          <Button type="submit" disabled={loading}>
            {loading && <FontAwesomeIcon icon={faSpinner} spin={loading} />}
            {translate('entity.action.save')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ResourceAddEdit;
