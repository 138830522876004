enum OrganizationTypeEnum {
  MAIN = 'MAIN',
  CONTRACTOR = 'CONTRACTOR',
  SUPPLIER = 'SUPPLIER',
  CUSTOMER = 'CUSTOMER',
}

export const ALL_ORGANIZATION_TYPE_VALUES = Object.values(OrganizationTypeEnum).filter(e => typeof e === 'string');
export type OrganizationType = keyof typeof OrganizationTypeEnum;
export default OrganizationTypeEnum;
