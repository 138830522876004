import { createSlice } from '@reduxjs/toolkit';
import ResourcesPlans from 'app/shared/model/custom/custom-resources-plans.model';
import { ChangedEquipmentPlanType, ChangedResourcePlanType } from 'app/shared/types/types';
import { translate } from 'react-jhipster';

export type PlanningShiftStateType = {
  isShiftDemandPopupOpen: { [id: number]: boolean };
  changedPositions: { payload: any; shiftDemandId: number }[];
  isShiftPlanPopupOpen: { [id: number]: boolean };
  isShiftPlanEquipmentPopupOpen: { [id: number]: boolean };
  isPrintPopupOpen: { [id: number]: boolean };
  changedResourcePlans: { plans: ResourcesPlans; status: string }[];
  changedEquipmentPlans: ChangedEquipmentPlanType[];
  isShiftPlanLoading: boolean;
  isShiftPlanEquipmentLoading: boolean;
  isPrinting: boolean;
  printMessage: string;
  isAutoDemandPopupOpen: { [id: number]: boolean };
  isLoadingAutoDemand: boolean;
  numberOfCranes: number;
  autoDemand: { label: string; value: boolean }[];
  isAutoPlanPopupOpen: { [id: number]: boolean };
  autoPlan: { field: string; label: string; value: boolean }[];
};

const initialState: PlanningShiftStateType = {
  isShiftDemandPopupOpen: {},
  changedPositions: [],
  isShiftPlanPopupOpen: {},
  isShiftPlanEquipmentPopupOpen: {},
  isPrintPopupOpen: {},
  changedResourcePlans: [],
  changedEquipmentPlans: [],
  isShiftPlanLoading: false,
  isShiftPlanEquipmentLoading: false,
  isPrinting: false,
  printMessage: '',
  isAutoDemandPopupOpen: {},
  isLoadingAutoDemand: false,
  numberOfCranes: 0,
  autoDemand: [
    { label: translate('rosterApp.planning.auto-demand.config-1'), value: false },
    { label: translate('rosterApp.planning.auto-demand.config-2'), value: false },
  ],
  isAutoPlanPopupOpen: {},
  // There is a better way than the old implementation but I need to know where this is uses elsewhere before changing it
  autoPlan: [
    { field: 'overwriteExistingPlan', label: translate('rosterApp.planning.auto-plan.overwrite-existing-plan'), value: false },
    { field: 'overwriteTeamPlan', label: translate('rosterApp.planning.auto-plan.overwrite-team-plan'), value: false },
    { field: 'allowExtraShiftsHours', label: translate('rosterApp.planning.auto-plan.allow-extra-shifts-hours'), value: false },
    { field: 'ignoreResourcesSkillLevel', label: translate('rosterApp.planning.auto-plan.ignore-resources-skill-level'), value: false },
  ],
};

export const PlanningShiftSlice = createSlice({
  name: 'shift',
  initialState,
  reducers: {
    setIsShiftDemandPopupOpen(state, action) {
      const { id, isOpen } = action.payload;
      state.isShiftDemandPopupOpen[id] = isOpen;
    },
    setChangedPositions(state, action) {
      state.changedPositions = action.payload as { payload: any; shiftDemandId: number }[];
    },
    setIsShiftPlanPopupOpen(state, action) {
      const { id, isOpen } = action.payload;
      state.isShiftPlanPopupOpen = isOpen;
    },
    setIsShiftPlanEquipmentPopupOpen(state, action) {
      const { id, isOpen } = action.payload;
      state.isShiftPlanEquipmentPopupOpen = isOpen;
    },
    setIsPrintPopupOpen(state, action) {
      const { id, isOpen } = action.payload;

      state.isPrintPopupOpen[id] = isOpen;
    },
    setChangedResourcePlans(state, action) {
      state.changedResourcePlans = action.payload as ChangedResourcePlanType[];
    },
    setChangedEquipmentPlans(state, action) {
      state.changedEquipmentPlans = action.payload as ChangedEquipmentPlanType[];
    },
    setIsShiftPlanLoading(state, action) {
      state.isShiftPlanLoading = action.payload as boolean;
    },
    setIsShiftPlanEquipmentLoading(state, action) {
      state.isShiftPlanEquipmentLoading = action.payload as boolean;
    },
    setIsPrinting(state, action) {
      state.isPrinting = action.payload as boolean;
    },
    setPrintMessage(state, action) {
      state.printMessage = action.payload as string;
    },
    setIsAutoDemandPopupOpen(state, action) {
      const { id, isOpen } = action.payload;
      state.isAutoDemandPopupOpen[id] = isOpen;
    },
    setIsLoadingAutoDemand(state, action) {
      state.isLoadingAutoDemand = action.payload as boolean;
    },
    setNumberOfCranes(state, action) {
      state.numberOfCranes = action.payload as number;
    },
    setAutoDemand(state, action) {
      state.autoDemand = action.payload as { label: string; value: boolean }[];
    },
    setIsAutoPlanPopupOpen(state, action) {
      const { id, isOpen } = action.payload;
      state.isAutoPlanPopupOpen[id] = isOpen;
    },
    setAutoPlan(state, action) {
      state.autoPlan = action.payload as { field: string; label: string; value: boolean }[];
    },
  },
});

export const {
  setIsShiftDemandPopupOpen,
  setChangedPositions,
  setIsShiftPlanPopupOpen,
  setIsShiftPlanEquipmentPopupOpen,
  setIsPrintPopupOpen,
  setChangedResourcePlans,
  setChangedEquipmentPlans,
  setIsShiftPlanLoading,
  setIsShiftPlanEquipmentLoading,
  setIsPrinting,
  setPrintMessage,
  setIsAutoDemandPopupOpen,
  setIsLoadingAutoDemand,
  setNumberOfCranes,
  setAutoDemand,
  setIsAutoPlanPopupOpen,
  setAutoPlan,
} = PlanningShiftSlice.actions;

export default PlanningShiftSlice.reducer;
