import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { NavDropdown } from './menu-components';
import { translate } from 'react-jhipster';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import EntitiesMenu from 'app/entities/entities-menu';

const adminMenuItems = () => (
  <>
    <EntitiesMenu />
    <MenuItem icon="users" to="/admin/user-management" translate="global.menu.admin.userManagement" />
    <MenuItem icon="tachometer-alt" to="/admin/metrics" translate="global.menu.admin.metrics" />
    <MenuItem icon="heart" to="/admin/health" translate="global.menu.admin.health" />
    <MenuItem icon="cogs" to="/admin/configuration" translate="global.menu.admin.configuration" />
    <MenuItem icon="tasks" to="/admin/logs" translate="global.menu.admin.logs" />
  </>
);

const openAPIItem = () => <MenuItem icon="book" to="/admin/docs" translate="global.menu.admin.apidocs" />;

export const AdminMenu = ({ showOpenAPI }) => (
  <NavDropdown icon={faGear} name={translate('global.menu.admin.main')} id="admin-menu" data-cy="adminMenu">
    {adminMenuItems()}
    {showOpenAPI && openAPIItem()}
  </NavDropdown>
);

export default AdminMenu;
