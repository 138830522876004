import { faCircleCheck, faHourglassEnd, faPen, faSave, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { Button } from 'app/shared/components/button';
import WarningConfirmationPopup from 'app/shared/components/confirmation-popup/warning-confirmation-popup';
import Tooltip from 'app/shared/components/tooltip/Tooltip';
import PositionTypeEnum from 'app/shared/enums/PositionTypeEnum';
import ResourceTypeEnum from 'app/shared/enums/ResourceTypeEnum';
import Department from 'app/shared/model/custom/custom-department.model';
import Position from 'app/shared/model/custom/custom-position.model';
import ShiftModel from 'app/shared/model/custom/custom-shift.model';
import { IEquipmentPlan } from 'app/shared/model/equipment-plan.model';
import { IEquipment } from 'app/shared/model/equipment.model';
import { IResourcePlan } from 'app/shared/model/resource-plan.model';
import { hasPermission } from 'app/shared/reducers/authentication.selector';
import {
  CalendarPlanningMode,
  ChangedEligibleEquipmentType,
  changedEligibleResourceType,
  ChangedEquipmentPlanType,
  ChangedResourcePlanType,
  ShiftActionType,
  ShiftMode,
} from 'app/shared/types/types';
import { cn } from 'app/shared/util/tailwind-merge';
import dayjs from 'dayjs';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { translate } from 'react-jhipster';
import ContextMenu from '../ContextMenu/ContextMenu';
import ShiftItem from '../ShiftItem/ShiftItem';
import { approveShift, getShiftDetails, publishShift, setCalendarPlanningMode, splitShift, submitShift } from '../planning.reducer';
import { selectEligibleEquipments } from '../planning.selector';
import AutoDemandModal from './AutoDemandModal';
import AutoPlanModal from './AutoPlanModal';
import ClearPlansModal from './ClearPlansModal';
import DuplicateModal from './DuplicateModal';
import PrintModal from './PrintModal';
import ShiftDemandConfirmationModal from './ShiftDemandConfirmationModal';
import ShiftPlanConfirmationModal from './ShiftPlanConfirmationModal';
import ShiftPlanEquipmentConfirmationModal from './ShiftPlanEquipmentConfirmationModal';
import ShiftSplitModal from './ShiftSplitModal';

type ShiftProps = {
  shift: ShiftModel;
  prevShift?: ShiftModel;
  departments?: Department[];
  numberOfShifts: number;
  equipments: IEquipment[];
  hideContent?: boolean;
  setEquipmentUnfolded: (departmentId: number, value: boolean) => void;
  planEquipment?: boolean;
  setPlanEquipment: (value: boolean) => void;
  setCurrentShiftMode?: (shiftId: number, mode: ShiftMode) => void;
  changedResourcePlans?: ChangedResourcePlanType[];
  setChangedResourcePlans?: (changedResourcePlans: ChangedResourcePlanType[]) => void;
  addResourcePlan?: (resourcePlan: IResourcePlan) => void;
  updateResourcePlan?: (resourcePlan: IResourcePlan) => void;
  deleteResourcePlan?: (planId: number, resourceId: number, organigramId: number, shiftId: number) => void;
  onShiftModeSelect?: (shiftId: number, shiftMode?: ShiftActionType) => void;
  onResourcePlanSave?: () => void;
};

const Shift = ({
  shift,
  prevShift,
  departments,
  numberOfShifts,
  equipments = [],
  hideContent = false,
  setEquipmentUnfolded,
  planEquipment = false,
  setPlanEquipment,
  setCurrentShiftMode,
  changedResourcePlans,
  setChangedResourcePlans,
  addResourcePlan,
  updateResourcePlan,
  deleteResourcePlan,
  onShiftModeSelect,
  onResourcePlanSave,
}: ShiftProps) => {
  const [menuOpen, setMenuOpen] = useState<any>(null);
  const [shiftMode, setShiftMode] = useState<ShiftMode>('VIEW');
  const [isShiftDemandPopupOpen, setIsShiftDemandPopupOpen] = useState<boolean>(false);
  const [isShiftPlanPopupOpen, setIsShiftPlanPopupOpen] = useState<boolean>(false);
  const [isShiftPlanEquipmentPopupOpen, setIsShiftPlanEquipmentPopupOpen] = useState<boolean>(false);
  const [isShiftPlanLoading, setIsShiftPlanLoading] = useState<boolean>(false);
  const [isShiftPlanEquipmentLoading, setIsShiftPlanEquipmentLoading] = useState<boolean>(false);
  const [isDuplicatePopupOpen, setIsDuplicatePopupOpen] = useState<boolean>(false);
  const [isClearPlansPopupOpen, setIsClearPlansPopupOpen] = useState<boolean>(false);
  const [isPrintPopupOpen, setIsPrintPopupOpen] = useState<boolean>(false);
  const [isAutoPlanPopupOpen, setIsAutoPlanPopupOpen] = useState<boolean>(false);
  const [isAutoDemandPopupOpen, setIsAutoDemandPopupOpen] = useState<boolean>(false);
  const [isLoadingAutoDemand, setIsLoadingAutoDemand] = useState<boolean>(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [printMessage, setPrintMessage] = useState('');
  const [numberOfCranes, setNumberOfCranes] = useState(0);
  const [isSplitModalOpen, setIsSplitModalOpen] = useState<boolean>(false);
  const [error, setError] = useState(null);
  const [autoDemand, setAutoDemand] = useState<{ label: string; value: boolean }[]>([
    { label: translate('rosterApp.planning.auto-demand.config-1'), value: false },
    { label: translate('rosterApp.planning.auto-demand.config-2'), value: false },
  ]);
  const [autoPlan, setAutoPlan] = useState<{ field: string; label: string; value: boolean }[]>([
    {
      field: 'includeResourcesFromOtherTeams',
      label: translate('rosterApp.planning.auto-plan.include-resource-from-other-teams'),
      value: false,
    },
    { field: 'overwriteExistingPlan', label: translate('rosterApp.planning.auto-plan.overwrite-existing-plan'), value: false },
    { field: 'overwriteTeamPlan', label: translate('rosterApp.planning.auto-plan.overwrite-team-plan'), value: false },
    { field: 'allowExtraShiftsHours', label: translate('rosterApp.planning.auto-plan.allow-extra-shifts-hours'), value: false },
    { field: 'ignoreResourcesSkillLevel', label: translate('rosterApp.planning.auto-plan.ignore-resources-skill-level'), value: false },
  ]);
  const [changedPositions, setChangedPositions] = useState<{ payload: any; shiftDemandId: number }[]>([]);
  const [changedEquipmentPlans, setChangedEquipmentPlans] = useState<ChangedEquipmentPlanType[]>([]);
  const [changedEligibleResources, setChangedEligibleResources] = useState<changedEligibleResourceType[]>([]);
  const [changedEligibleEquipments, setChangedEligibleEquipments] = useState<ChangedEligibleEquipmentType[]>([]);
  const [warningType, setWarningType] = useState('');
  const dropdownRef = useRef(null);
  const dispatch = useAppDispatch();
  const eligibleResources = useAppSelector(state => state.planning.eligibleResources);
  const eligibleEquipments = useAppSelector(selectEligibleEquipments);
  const resources = useAppSelector(state => state.resource.entities);
  const calendarPlanningMode = useAppSelector(state => state.planning.calendarPlanningMode) as CalendarPlanningMode;
  const hasEditDemandPermission = useAppSelector((state: IRootState) => hasPermission(state, 'demand_edit'));
  const hasEditPlanPermission = useAppSelector((state: IRootState) => hasPermission(state, 'plan_edit'));

  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  // TODO
  const hasEditPlanEquipmentPermission = true; //useAppSelector((state: IRootState) => hasPermission(state, 'plan_edit'));

  useEffect(() => {
    setPlanEquipment(shiftMode === 'PLAN_EQUIPMENT');
    setCurrentShiftMode(shift?.id, shiftMode);
  }, [shiftMode]);

  const refreshShift = useCallback(async () => {
    await dispatch(getShiftDetails({ shiftId: shift.id }));
    setChangedEquipmentPlans([]);
    setChangedResourcePlans([]);
    setChangedEligibleResources([]);
    setChangedEligibleEquipments([]);
  }, [dispatch, shift?.id]);

  const openDuplicate = () => {
    setIsDuplicatePopupOpen(true);
  };

  const openClearPlans = () => {
    setIsClearPlansPopupOpen(true);
  };

  const openPrint = () => {
    setIsPrintPopupOpen(true);
  };

  const openAutoPlan = () => {
    setAutoPlan([
      {
        field: 'includeResourcesFromOtherTeams',
        label: translate('rosterApp.planning.auto-plan.include-resource-from-other-teams'),
        value: false,
      },
      { field: 'overwriteExistingPlan', label: translate('rosterApp.planning.auto-plan.overwrite-existing-plan'), value: false },
      { field: 'overwriteTeamPlan', label: translate('rosterApp.planning.auto-plan.overwrite-team-plan'), value: false },
      { field: 'allowExtraShiftsHours', label: translate('rosterApp.planning.auto-plan.allow-extra-shifts-hours'), value: false },
      { field: 'ignoreResourcesSkillLevel', label: translate('rosterApp.planning.auto-plan.ignore-resources-skill-level'), value: false },
    ]);
    setIsAutoPlanPopupOpen(true);
  };

  const openAutoDemand = () => {
    setNumberOfCranes(0);
    setAutoDemand([
      { label: translate('rosterApp.planning.auto-demand.config-1'), value: false },
      { label: translate('rosterApp.planning.auto-demand.config-2'), value: false },
    ]);
    setIsAutoDemandPopupOpen(true);
  };

  const getShiftDepartmentByDepartmentId = (departmentId: number): Department | undefined => {
    const departments = shift?.facilities?.flatMap(facility => facility.departments) || [];
    return (departments ?? []).find(dep => dep.id === departmentId);
  };

  const onShiftMenuChoicesClick = (actionType?: ShiftActionType) => {
    if (calendarPlanningMode === 'SHIFT_DETAILS_VIEW' && ['EDIT_DEMAND', 'EDIT_PLAN', 'EDIT_PLAN_EQUIPMENT'].includes(actionType)) {
      onShiftModeSelect(shift?.id, actionType);
      switch (actionType) {
        case 'EDIT_DEMAND':
          setShiftMode('DEMAND');
          return;
        case 'EDIT_PLAN':
          setShiftMode('PLAN');
          return;
        case 'EDIT_PLAN_EQUIPMENT':
          setShiftMode('PLAN_EQUIPMENT');
          return;
        default:
          return;
      }
    } else if (!menuOpen && shiftMode === 'VIEW') {
      setMenuOpen(true);
    } else if (menuOpen) {
      setMenuOpen(false);
      // eslint-disable-next-line default-case
      switch (actionType) {
        case 'SPLIT':
          splitCurrentShift();
          return;
        case 'DUPLICATE':
          openDuplicate();
          return;
        case 'CLEAR_PLANS':
          openClearPlans();
          return;
        case 'PRINT':
          openPrint();
          return;
        case 'AUTO_DEMAND':
          openAutoDemand();
          return;
        case 'AUTO_PLAN':
          openAutoPlan();
          return;
        case 'APPROVE':
          IsShiftIncludesDummyResources('APPROVE');
          return;
        case 'SUBMIT':
          IsShiftIncludesDummyResources('SUBMIT');
          return;
        case 'PUBLISH':
          IsShiftIncludesDummyResources('PUBLISH');
          return;
      }
    } else {
      onShiftClick();
    }
  };

  const IsShiftIncludesDummyResources = (actionType: ShiftActionType) => {
    for (let d = 0; d < departments?.length; d++) {
      for (let p = 0; p < departments[d]?.positions?.length; p++) {
        for (let rp = 0; rp < departments[d]?.positions[p]?.resourcesPlans?.length; rp++) {
          if (departments[d]?.positions[p]?.resourcesPlans[rp]?.resource?.type === (ResourceTypeEnum.DUMMY as string)) {
            setWarningType(actionType);
            return;
          }
        }
      }
    }
    submitApproveShift(actionType);
  };

  const submitApproveShift = (actionType: string) => {
    if (actionType === 'APPROVE') {
      approveShifts();
    } else if (actionType === 'SUBMIT') {
      submitShifts();
    } else if (actionType === 'PUBLISH') {
      publishShifts();
    }
    setWarningType('');
  };

  const splitCurrentShift = async () => {
    await dispatch(splitShift(shift.id))
      .then(action => {
        if (splitShift.rejected.match(action)) {
          setError(action.payload || 'Something went wrong');
          setIsSplitModalOpen(true);
        }
      })
      .catch(error => {
        setError('Unexpected error');
        setIsSplitModalOpen(true);
      });
    onShiftDoubleClick();
  };

  const submitShifts = async () => {
    try {
      // Dispatch an action to update the shift
      await dispatch(submitShift(shift.id));
      refreshShift();
    } catch (error) {
      console.error('Error submitting shift:', error);
    }
  };

  const onShiftClick = () => {
    if (shiftMode === 'DEMAND') {
      openConfirmationShiftDemandePopup();
    } else if (shiftMode === 'PLAN') {
      setIsShiftPlanPopupOpen(true);
    } else if (shiftMode === 'PLAN_EQUIPMENT') {
      setIsShiftPlanEquipmentPopupOpen(true);
    }
  };

  const openConfirmationShiftDemandePopup = () => {
    setIsShiftDemandPopupOpen(true);
  };

  const approveShifts = async () => {
    try {
      // Dispatch an action to update the shift
      await dispatch(approveShift(shift.id));
      refreshShift();
    } catch (error) {
      console.error('Error approving shift:', error);
    }
  };

  const publishShifts = async () => {
    try {
      await dispatch(publishShift(shift.id));
      refreshShift();
    } catch (error) {
      console.error('Error publishing shift:', error);
    }
  };

  const updatePositionsShiftDemand = (positionShiftDemand: { payload: any; shiftDemandId: number }) => {
    if (
      changedPositions.filter(
        pos =>
          pos.payload?.organigram?.refPosition?.id === positionShiftDemand.payload.organigram.refPosition.id &&
          pos.payload?.organigram?.id === positionShiftDemand?.payload?.organigram?.id,
      ).length
    ) {
      setChangedPositions(
        changedPositions.map(pos => {
          if (
            pos.payload?.organigram?.refPosition?.id === positionShiftDemand?.payload?.organigram?.refPosition?.id &&
            pos.payload?.organigram?.id === positionShiftDemand?.payload?.organigram?.id
          ) {
            pos.payload.headCount = positionShiftDemand.payload.headCount;
          }
          return pos;
        }),
      );
    } else {
      setChangedPositions([...changedPositions, positionShiftDemand]);
    }
  };

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  const getEligibleResourceByPosition = (position: Position) => {
    const allEligibleResources = [
      ...(eligibleResources.shiftPreferredResources?.map(res => ({ ...res, mode: 'preferred' })) || []),
      ...(eligibleResources.shiftResources?.map(res => ({ ...res, mode: 'shift' })) || []),
      ...(eligibleResources.remainingResources?.map(res => ({ ...res, mode: 'remaining' })) || []),
    ];

    if (position?.type === (PositionTypeEnum.STANDBY as string)) {
      return allEligibleResources;
    }

    const shiftResourcesSet = new Set();
    const shiftResources = [];

    const departments = shift?.facilities?.flatMap(facility => facility.departments) || [];

    departments.forEach(department => {
      department.positions?.forEach(position => {
        position.resourcesPlans?.forEach(resourcePlan => {
          const resourceId = resourcePlan?.resource?.id;
          if (resourceId && !shiftResourcesSet.has(resourceId) && position?.type !== (PositionTypeEnum.STANDBY as string)) {
            const isDeleted = changedEligibleResources.some(res => res.status === 'DELETED' && res.resource?.id === resourceId);
            if (!isDeleted) {
              shiftResourcesSet.add(resourceId);
              shiftResources.push(resourcePlan.resource);
            }
          }
        });
      });
    });

    const addedResources = new Set(
      changedEligibleResources
        .filter(res => res.status === 'ADDED' && res?.position?.type !== (PositionTypeEnum.STANDBY as string))
        .map(res => res.resource.id),
    );

    return allEligibleResources.filter(
      resource =>
        resource?.type === (ResourceTypeEnum.DUMMY as string) || (!shiftResourcesSet.has(resource.id) && !addedResources.has(resource.id)),
    );
  };

  const getEligibleEquipments = () => {
    const shiftEquipments = [];
    const shiftEquipmentsSet = new Set();
    let departments = shift?.facilities?.flatMap(facility => facility.departments) || [];

    for (const department of departments || []) {
      for (const position of department.positions || []) {
        for (const equipmentPlan of position.equipmentPlans || []) {
          const equipmentId = equipmentPlan?.equipment?.id;
          if (equipmentId && !shiftEquipmentsSet.has(equipmentId)) {
            const isDeleted = changedEligibleEquipments.some(res => res.status === 'DELETED' && res.equipment?.id === equipmentId);
            if (!isDeleted) {
              shiftEquipmentsSet.add(equipmentId);
              shiftEquipments.push(equipmentPlan.equipment);
            }
          }
        }
      }
    }

    return eligibleEquipments.equipmentInCurrentDepartment
      .map(res => ({ ...res, mode: 'department' }))
      .concat(eligibleEquipments.equipmentInOtherDepartments.map(res => ({ ...res, mode: 'other' })))
      .filter(
        equipment =>
          !shiftEquipments.map(res => res?.id).includes(equipment.id) &&
          !changedEligibleEquipments
            .filter(res => res.status === 'ADDED')
            .map(res => res.equipment.id)
            .includes(equipment.id),
      );
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Edit Plan
  const handleAddResourcePlan = (resourcePlan: IResourcePlan, position?: Position) => {
    addResourcePlan(resourcePlan);
    setChangedEligibleResources([
      ...changedEligibleResources,
      {
        status: 'ADDED',
        resource: resourcePlan?.resource,
        position: position,
      },
    ]);
  };

  const handleDeleteResourcePlan = (planId: number, resourceId: number, organigramId: number) => {
    deleteResourcePlan(planId, resourceId, organigramId, shift?.id);
    setChangedEligibleResources([
      ...changedEligibleResources.filter(r => r?.resource?.id !== resourceId),
      { resource: resources.filter(res => res?.id === resourceId)[0], status: 'DELETED' },
    ]);
  };

  const addEquipmentPlan = (equipmentPlan: IEquipmentPlan) => {
    setChangedEquipmentPlans([
      ...changedEquipmentPlans,
      {
        plans: {
          id: equipmentPlan.id,
          notes: '',
          startTime: dayjs(equipmentPlan?.shift?.startTime?.toDate()),
          endTime: dayjs(equipmentPlan?.shift?.endTime?.toDate()),
          equipment: equipmentPlan?.equipment,
          resource: equipmentPlan?.resource,
          shift: {
            id: equipmentPlan?.shift?.id,
            status: equipmentPlan?.shift?.status,
          },
          organigram: equipmentPlan.organigram,
        },
        status: 'ADDED',
      },
    ]);
    setChangedEligibleEquipments([
      ...changedEligibleEquipments,
      {
        status: 'ADDED',
        equipment: equipmentPlan?.equipment,
      },
    ]);
  };

  const deleteEquipmentPlan = (planId: number, equipmentId: number, resourceId: number, organigramId: number) => {
    setChangedEquipmentPlans([
      ...changedEquipmentPlans.filter(r => r?.plans?.equipment?.id !== equipmentId),
      {
        plans: {
          id: planId,
          equipment: { id: equipmentId },
          organigram: { id: organigramId },
          resource: { id: resourceId },
        },
        status: 'DELETED',
      },
    ]);
    setChangedEligibleEquipments([
      ...changedEligibleEquipments.filter(r => r?.equipment?.id !== equipmentId),
      { equipment: equipments.filter(res => res?.id === equipmentId)[0], status: 'DELETED' },
    ]);
  };

  const onShiftDoubleClick = async () => {
    if (shift && shift.id && calendarPlanningMode !== 'SHIFT_DETAILS_VIEW' && !menuOpen) {
      document.body.style.cursor = 'wait';
      await refreshShift();
      await dispatch(setCalendarPlanningMode('SHIFT_DETAILS_VIEW'));
      document.body.style.cursor = 'auto';
    }
  };
  const shiftMaxWidth = 100 / numberOfShifts;

  const saveLabel = useMemo(() => translate('entity.action.save'), [currentLocale]);
  return (
    <Fragment>
      <div
        className={cn('flex flex-col')}
        style={{
          width: `${numberOfShifts > 0 ? `${shiftMaxWidth}%` : '100%'}`,
        }}
      >
        <div
          className={cn(
            `flex items-center sticky px-1 top-[36.8px] z-[5] justify-center pt-1.5 text-base font-bold border border-solid border-gray-300 text-center cursor-pointer transition-all pb-[4.6px]`,
            ['DEMAND', 'PLAN', 'PLAN_EQUIPMENT'].includes(shiftMode)
              ? 'bg-blue-200 hover:bg-blue-300 active:bg-blue-400'
              : ' hover:bg-blue-100 active:bg-blue-300',
          )}
          onContextMenu={() => onShiftMenuChoicesClick()}
          onClick={() => onShiftDoubleClick()}
        >
          {shift.name ? <span className="line-clamp-1 text-ellipsis">{shift.name}</span> : <span>N/A</span>}
          {['DEMAND', 'PLAN', 'PLAN_EQUIPMENT'].includes(shiftMode) &&
            (calendarPlanningMode === 'SHIFT_VIEW' ? (
              <FontAwesomeIcon icon={faSave} onClick={onShiftClick} className="ml-2 text-gray-500" />
            ) : (
              <div className="absolute right-2 flex">
                <Button size="sm" onClick={onShiftClick}>
                  <FontAwesomeIcon icon={faSave} className="mr-2" />
                  <span>{saveLabel}</span>
                </Button>
              </div>
            ))}
          {calendarPlanningMode === 'SHIFT_DETAILS_VIEW' && !['DEMAND', 'PLAN', 'PLAN_EQUIPMENT'].includes(shiftMode) && (
            <div className="absolute right-2 flex">
              {hasEditDemandPermission && (
                <Tooltip text={translate('rosterApp.planning.context-menu.edit-demand')}>
                  <FontAwesomeIcon icon={faPen} onClick={() => onShiftMenuChoicesClick('EDIT_DEMAND')} className="ml-2 text-gray-500" />
                </Tooltip>
              )}
              {hasEditPlanPermission && (
                <Tooltip text={translate('rosterApp.planning.context-menu.edit-plan-resources')}>
                  <FontAwesomeIcon icon={faUsers} onClick={() => onShiftMenuChoicesClick('EDIT_PLAN')} className="ml-2 text-gray-500" />
                </Tooltip>
              )}
              {hasEditPlanEquipmentPermission && (
                <Tooltip text={translate('rosterApp.planning.context-menu.edit-plan-equipment')}>
                  <div onClick={() => onShiftMenuChoicesClick('EDIT_PLAN_EQUIPMENT')} className="ml-2 size-7 fill-gray-500">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 209.98 154.56">
                      <g id="Layer_2" data-name="Layer 2">
                        <g id="Layer_1-2" data-name="Layer 1">
                          <path d="M23.91,76c0-15.41-.13-30,0-44.52.14-12.22,3.73-15.77,15.81-15.86,16.94-.11,33.89.29,50.82-.17,10-.27,15.07,4.27,16.71,13.72,2.14,12.34,5.06,24.59,6.42,37,1.08,9.93,5.19,13.16,15.83,9.65V0h12.78V135h41.25c2.94,7.83,1,10.72-6.66,10.43-9.81-.37-19.65-.15-29.47-.07-9,.07-17.39-.26-25.81,5.84-10,7.23-23.86,2.07-29.77-10-2.79-5.68-6.09-7.21-11.69-6.76a90.16,90.16,0,0,1-12.19.05c-9.23-.49-16.61-.24-22,10.39-5.89,11.55-24.2,13-32.42,2.74C7.34,140,3.74,129.66,1,119.94-.94,113.36.56,105.81.6,98.69.66,83.79,4.12,79.79,19,77.23,20.31,77,21.57,76.61,23.91,76Zm9.68,12.78c9.51,0,17.26.23,25-.06,8-.3,15.56-.62,19.47,8.58.68,1.58,3.78,2.9,5.82,3,7.65.35,15.32.14,22.34.14.9-1.9,1.5-2.54,1.41-3.08-3.43-22.63-6.78-45.28-10.68-67.83-.3-1.74-4.45-3.93-6.85-4-16.55-.36-33.12-.41-49.67,0-2.32.06-6.47,3.29-6.52,5.13C33.4,49.6,33.59,68.46,33.59,88.81Zm7.62,42.58c-.49-7.76-4.35-12.7-12.47-12.9S15.84,123,15.6,130.81s4.45,12.83,12.42,13S40.45,139.58,41.21,131.39Zm70.47-13.15c-7.42.7-12.31,4.79-12.39,12.75s4.41,12.84,12.47,12.84c7.84,0,12.82-4.36,12.88-12.47S120,119.15,111.68,118.24Z" />
                          <path d="M180.27,126.7c-7.12,0-14.33.67-21.31-.32-2.71-.38-6.88-4.37-7-6.88-.68-13.86-.67-27.8,0-41.66.13-2.47,4.52-6.64,7.09-6.75,14.55-.67,29.14-.24,43.71-.4C208,70.63,210,72.8,210,78c-.16,13.9.29,27.81-.39,41.68-.12,2.46-4.3,6.38-7,6.72C195.26,127.3,187.73,126.68,180.27,126.7Z" />
                        </g>
                      </g>
                    </svg>
                  </div>
                </Tooltip>
              )}
            </div>
          )}
          {['SUBMITTED', 'APPROVED'].includes(shift?.status?.toUpperCase()) &&
            !['DEMAND', 'PLAN', 'PLAN_EQUIPMENT'].includes(shiftMode) && (
              <div className="ml-1">
                <Tooltip text={shift.status} position="bottom">
                  <FontAwesomeIcon
                    className={`text-sm ${shift?.status?.toUpperCase() === 'APPROVED' ? 'text-green-700' : 'text-blue-700'}`}
                    icon={shift?.status?.toUpperCase() === 'APPROVED' ? faCircleCheck : faHourglassEnd}
                  />
                </Tooltip>
              </div>
            )}
          {shift?.status?.toUpperCase() === 'PUBLISHED' && (
            <div className="ml-1 flex items-center justify-center">
              <div
                className="w-6 h-6 bg-center bg-no-repeat bg-contain"
                style={{ backgroundImage: "url('content/images/eye-icon.png')" }}
              ></div>
            </div>
          )}
          {menuOpen && <ContextMenu forwardedRef={dropdownRef} onMenuItemClick={onShiftMenuChoicesClick} />}
        </div>
        {(departments ?? []).map((department, index) => (
          <ShiftItem
            key={`${department.name}_${index}`}
            shift={shift}
            prevShift={prevShift}
            department={department}
            shiftDepartment={getShiftDepartmentByDepartmentId(department.id)}
            getEligibleResourceByPosition={getEligibleResourceByPosition}
            eligibleEquipments={getEligibleEquipments()}
            hideContent={hideContent}
            shiftMode={shiftMode}
            updatePositionsShiftDemand={updatePositionsShiftDemand}
            changedPositions={changedPositions}
            changedResources={changedResourcePlans}
            changedEquipmentPlans={changedEquipmentPlans}
            addResourcePlan={handleAddResourcePlan}
            updateResourcePlan={updateResourcePlan}
            deleteResourcePlan={handleDeleteResourcePlan}
            addEquipmentPlan={addEquipmentPlan}
            deleteEquipmentPlan={deleteEquipmentPlan}
            equipments={equipments}
            planEquipment={planEquipment}
            setEquipmentUnfolded={setEquipmentUnfolded}
            refreshShift={refreshShift}
          />
        ))}

        <ShiftDemandConfirmationModal
          changedPositions={changedPositions}
          setChangedPositions={setChangedPositions}
          setShiftMode={setShiftMode}
          setIsShiftDemandPopupOpen={setIsShiftDemandPopupOpen}
          refreshShift={refreshShift}
          isShiftDemandPopupOpen={isShiftDemandPopupOpen}
        />

        <ShiftPlanConfirmationModal
          changedResourcePlans={changedResourcePlans}
          changedEquipmentPlans={changedEquipmentPlans}
          setShiftMode={setShiftMode}
          setChangedResourcePlans={setChangedResourcePlans}
          setIsShiftPlanLoading={setIsShiftPlanLoading}
          setIsShiftPlanPopupOpen={setIsShiftPlanPopupOpen}
          isShiftPlanPopupOpen={isShiftPlanPopupOpen}
          refreshShift={refreshShift}
          onResourcePlanSave={onResourcePlanSave}
        />

        <ShiftPlanEquipmentConfirmationModal
          changedEquipmentPlans={changedEquipmentPlans}
          setShiftMode={setShiftMode}
          setIsShiftPlanEquipmentPopupOpen={setIsShiftPlanEquipmentPopupOpen}
          setChangedEquipmentPlans={setChangedEquipmentPlans}
          setIsShiftPlanEquipmentLoading={setIsShiftPlanEquipmentLoading}
          isShiftPlanEquipmentPopupOpen={isShiftPlanEquipmentPopupOpen}
          refreshShift={refreshShift}
        />
        {isDuplicatePopupOpen && (
          <DuplicateModal
            shift={shift}
            refreshShift={refreshShift}
            setIsDuplicatePopupOpen={setIsDuplicatePopupOpen}
            isDuplicatePopupOpen={isDuplicatePopupOpen}
          />
        )}
        {isClearPlansPopupOpen && (
          <ClearPlansModal
            shift={shift}
            refreshShift={refreshShift}
            setIsClearPlansPopupOpen={setIsClearPlansPopupOpen}
            isClearPlansPopupOpen={isClearPlansPopupOpen}
          />
        )}
        <PrintModal
          shift={shift}
          refreshShift={refreshShift}
          setPrintMessage={setPrintMessage}
          setIsPrinting={setIsPrinting}
          setIsPrintPopupOpen={setIsPrintPopupOpen}
          isPrintPopupOpen={isPrintPopupOpen}
        />

        <AutoDemandModal
          setNumberOfCranes={setNumberOfCranes}
          setAutoDemand={setAutoDemand}
          refreshShift={refreshShift}
          setIsLoadingAutoDemand={setIsLoadingAutoDemand}
          setIsAutoDemandPopupOpen={setIsAutoDemandPopupOpen}
          isAutoDemandPopupOpen={isAutoDemandPopupOpen}
          shift={shift}
          numberOfCranes={numberOfCranes}
          autoDemand={autoDemand}
        />
        {isAutoPlanPopupOpen && (
          <AutoPlanModal
            shift={shift}
            refreshShift={refreshShift}
            setAutoPlan={setAutoPlan}
            autoPlan={autoPlan}
            setIsAutoPlanPopupOpen={setIsAutoPlanPopupOpen}
            isAutoPlanPopupOpen={isAutoPlanPopupOpen}
          />
        )}

        <ShiftSplitModal isOpen={isSplitModalOpen} message={error} onClose={() => setIsSplitModalOpen(false)} />

        <WarningConfirmationPopup
          headerLabel={translate('global.messages.warning')}
          open={!!warningType}
          onSave={() => submitApproveShift(warningType)}
          onCancel={() => setWarningType('')}
        >
          {translate('rosterApp.shift.shiftDummyWarning')}
        </WarningConfirmationPopup>
      </div>
    </Fragment>
  );
};

export default Shift;
