import { faCircleNotch, faMicrophone, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { refreshShift } from 'app/modules/planning/planning.reducer';
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { translate } from 'react-jhipster';

const MessageBubble = ({ text, isUser }) => {
  return (
    <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-2`}>
      <div
        className={`rounded-lg p-2 mr-1 max-w-md ${isUser ? 'bg-blue-500 text-white text-xs' : 'bg-gray-200 text-xs'}`}
        style={{ alignSelf: isUser ? 'flex-end' : 'flex-start' }}
      >
        {text}
      </div>
    </div>
  );
};

const sendMessageToBackend = async message => {
  try {
    const response = await axios.post('/api/chatbot/', { text: message }); // DOTO Edit PATH API LEX
    return response?.data?.messages.map(msg => msg.content);
  } catch (error) {
    console.error('Failed to send message to backend:', error);
    return ['Oops!!!! Something went wrong.'];
  }
};

const FloatingChatButton = () => {
  const dispatch = useAppDispatch();
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [inputText, setInputText] = useState('');
  const [messages, setMessages] = useState<{ text: string; isUser: boolean }[]>([]);
  const [loading, setLoading] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const chatRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const currentLocale = useAppSelector(state => state.locale.currentLocale);

  const toggleChat = useCallback(() => {
    setIsChatOpen(prevIsChatOpen => !prevIsChatOpen);
    setIsMinimized(() => false);
    if (!isChatOpen) {
      setMessages([
        { text: translate('chat.defaultQuestions.hello'), isUser: false },
        { text: translate('chat.defaultQuestions.fellFree'), isUser: false },
      ]);
    }
  }, []);

  const minimizeChat = () => {
    setIsMinimized(() => true);
  };

  const restoreChat = () => {
    setIsMinimized(() => false);
  };

  const handleInputChange = event => {
    setInputText(event.target.value);
  };

  const handleKeyPress = async event => {
    if (event.key === 'Enter' && inputText.trim() !== '') {
      setLoading(true);
      let newMessages = [...messages, { text: inputText, isUser: true }];
      setMessages(newMessages);

      const response: string[] = await sendMessageToBackend(inputText);
      newMessages = newMessages.concat(response.map(message => ({ text: message, isUser: false })));

      if (response.some(res => res === 'Great news! Your shift planning has been successfully completed. ')) {
        dispatch(refreshShift());
      }

      setMessages(newMessages);
      setLoading(false);
      setInputText('');
    }
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
    inputRef?.current?.focus();
  }, [loading, isChatOpen, isMinimized]);

  const handleClickOutside = event => {
    if (chatRef.current && !chatRef.current.contains(event.target)) {
      setIsMinimized(() => true);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={chatRef} className="fixed bottom-10 right-10">
      <img
        onClick={isMinimized ? restoreChat : toggleChat}
        className="fixed bottom-4 right-4 transition-all delay-75 w-14 hover:w-16 cursor-pointer"
        src="content/images/chatbot-logo.png"
        alt="chatbot-logo"
      />
      {
        <div
          className={`fixed transition-all delay-300 ${
            isChatOpen && !isMinimized ? 'right-4' : '-right-80'
          } bottom-20 mb-2 bg-white rounded-lg shadow-lg`}
        >
          <div className="flex items-center border-b pt-2 mx-3 pb-1.5">
            <h2 className="text-lg font-bold m-0">{translate('chat.title')}</h2>
            <button title={translate('chat.minimize')} onClick={minimizeChat} className="ml-auto text-gray-500 cursor-pointer">
              <FontAwesomeIcon icon={faMinus} />
            </button>
          </div>
          <div className="p-3 pt-0">
            <div className="pt-2 overflow-y-auto custom-scrollbar w-64 h-72" ref={scrollRef}>
              {messages.map((message, index) => (
                <MessageBubble key={index} text={message.text} isUser={message.isUser} />
              ))}
              {loading && (
                <div className="flex justify-center mb-2">
                  <FontAwesomeIcon icon={faCircleNotch} spin={true} />
                </div>
              )}
            </div>
            <div className="relative">
              <FontAwesomeIcon
                icon={faMicrophone}
                title={translate('chat.form.audio')}
                className="absolute left-2 ps-2 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
              />
              <input
                ref={inputRef}
                type="text"
                className="w-full py-2 pl-10 placeholder-gray-500 mt-1 shadow-sm bg-gray-50 border-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 outline-blue-500"
                placeholder={translate('chat.form.inputPlaceholder')}
                value={inputText}
                onChange={handleInputChange}
                disabled={loading}
                onKeyPress={handleKeyPress}
              />
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default FloatingChatButton;
