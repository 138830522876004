import Resource from 'app/shared/model/custom/custom-resource.model';
import ResourcePlans from 'app/shared/model/custom/custom-resources-plans.model';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Avatar from '../avatar/Avatar';
import './AvatarMore.css';
import { IEquipmentPlan } from 'app/shared/model/equipment-plan.model';
import EquipmentCustom from 'app/shared/model/custom/custom-equipment.model';
import { getImageUrl } from 'app/shared/util/file-utils';
import EquipmentResourceSelectInput from './EquipmentResourceSelectInput';

type AvatarMoreProps = {
  avatars?: { src: string; alt: string }[];
  resourcePlans?: ResourcePlans[];
  equipmentPlans?: IEquipmentPlan[];
  resources?: Resource[];
  equipments?: EquipmentCustom[];
  isAdd?: boolean;
  addClicked?: boolean;
  children: ReactNode;
  mode?: string;
  setAddClicked?: (value: boolean) => void;
  addResource?: (resource: Resource) => Promise<void>;
  addEquipment?: (equipment: EquipmentCustom) => Promise<void>;
  deleteResourcePlan?: (planId: number, id: number) => void;
  deleteEquipmentPlan?: (planId: number, id: number) => void;
};

const AvatarMore = ({
  avatars = [],
  resourcePlans = [],
  equipmentPlans = [],
  resources = [],
  equipments = [],
  isAdd = false,
  addClicked = false,
  mode,
  setAddClicked,
  addResource,
  addEquipment,
  deleteResourcePlan,
  deleteEquipmentPlan,
  children,
}: AvatarMoreProps) => {
  const dropdownRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredResources, setFilteredResources] = useState<Resource[]>([]);
  const [filteredEquipments, setFilteredEquipments] = useState<EquipmentCustom[]>([]);

  useEffect(() => {
    if (resources.length) {
      setFilteredResources(
        resources.filter(
          res => res.firstName.toLowerCase().includes(search.toLowerCase()) || res.lastName.toLowerCase().includes(search.toLowerCase()),
        ),
      );
    } else if (filteredResources.length) {
      setFilteredResources([]);
    }
  }, [resources, search]);

  useEffect(() => {
    if (equipments.length) {
      setFilteredEquipments(equipments.filter(res => res.name.toLowerCase().includes(search.toLowerCase())));
    } else if (filteredEquipments.length) {
      setFilteredEquipments([]);
    }
  }, [equipments, search]);

  const show = () => {
    return (isHovered && !addClicked && !isAdd) || (isAdd && addClicked);
  };

  const handleClose = () => {
    if (isAdd && setAddClicked) {
      setAddClicked(false);
    } else {
      setIsHovered(false);
    }
  };

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      handleClose();
    }
  };

  const handleHoverOutside = event => {
    if (dropdownRef.current && resourcePlans.length) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block" ref={dropdownRef} onMouseLeave={handleHoverOutside}>
      <div onMouseEnter={() => setIsHovered(true)} onClick={() => handleClose()}>
        {children}
      </div>
      <EquipmentResourceSelectInput
        resources={resources}
        resourcePlans={resourcePlans}
        equipments={equipments}
        equipmentPlans={equipmentPlans}
        avatars={avatars}
        mode={mode}
        deleteResourcePlan={deleteResourcePlan}
        addClicked={addClicked}
        addResource={addResource}
        addEquipment={addEquipment}
        deleteEquipmentPlan={deleteEquipmentPlan}
        isAdd={isAdd}
        setAddClicked={setAddClicked}
        isHovered={isHovered}
        setIsHovered={setIsHovered}
      />
    </div>
  );
};

export default AvatarMore;
