import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from 'app/config/store';
import Modal from 'app/shared/components/modal/Modal';
import React, { useState } from 'react';
import { translate } from 'react-jhipster';
import { deleteEntity } from './demand-template.reducer';
import { Button } from 'app/shared/components/button';
import { IDemandTemplate } from 'app/shared/model/demand-template.model';

type DemandTemplateDeleteProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  deletingDemandTemplate: IDemandTemplate[];
};

const RefLeaveTypeDelete = ({ isOpen, onClose, onConfirm, deletingDemandTemplate }: DemandTemplateDeleteProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const handleConfirmDeleteClick = () => {
    handleConfirmDelete();
  };
  const handleConfirmDelete = async () => {
    setLoading(true);
    try {
      for (const demandTemplate of deletingDemandTemplate) {
        await dispatch(deleteEntity(demandTemplate.id));
      }
      onConfirm();
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal size="lg" title={`${translate('entity.delete.title')}`} isOpen={isOpen} onClose={onClose}>
      <div className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
        {deletingDemandTemplate?.length &&
          translate('rosterApp.demandTemplate.delete.multipleQuestion', { param: deletingDemandTemplate.length })}
      </div>
      {/* Buttons */}
      <div className="flex items-center gap-2 justify-end p-3 md:p-4 border-t border-gray-200 rounded-b bg-navyBlue">
        <Button variant="outline" onClick={onClose} type="button">
          {translate('entity.action.cancel')}
        </Button>
        <Button variant="destructive" onClick={handleConfirmDeleteClick} disabled={loading}>
          {loading && <FontAwesomeIcon icon={faSpinner} spin={loading} />}
          {translate('entity.action.delete')}
        </Button>
      </div>
    </Modal>
  );
};

export default RefLeaveTypeDelete;
