import Position from 'app/shared/model/custom/custom-position.model';
import React from 'react';

type PositionItemProps = {
  position: Position;
  selectedPosition: Position;
  onClick?: () => void;
};

const PositionItem = ({ position, selectedPosition, onClick }: PositionItemProps) => {
  const isCurrentPositionSelected = () => {
    return position?.organigram?.id === selectedPosition?.organigram?.id && position?.id === selectedPosition?.id;
  };

  return (
    <li
      onClick={onClick}
      className={`${
        isCurrentPositionSelected() ? `bg-skyBlue/50 border-2 border-blue-300` : `bg-skyBlue border border-solid border-white`
      } relative flex cursor-pointer items-center pl-7 text-sm text-center min-h-12 `}
    >
      <div className="absolute w-2 h-full bg-navyBlue left-0"></div>
      {position.name}
    </li>
  );
};

export default PositionItem;
