import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';

const accountMenuItemsAuthenticated = () => (
  <>
    <MenuItem icon="wrench" to="/account/settings" translate="global.menu.account.main" />
    <MenuItem icon="lock" to="/account/password" translate="global.menu.account.password" />
    <MenuItem icon="sign-out-alt" to="/logout" translate="global.menu.account.logout" />
  </>
);

const accountMenuItems = () => (
  <>
    <MenuItem icon="sign-in-alt" to="/login" translate="global.menu.account.login" />
    <MenuItem icon="user-plus" to="/account/register" translate="global.menu.account.register" />
  </>
);

export const AccountMenu = ({ isAuthenticated = false, currentUser }) => {
  const nameInitials = `${currentUser?.firstName?.substring(0, 1)}${currentUser?.lastName?.substring(0, 1)}`;

  return (
    <>
      <NavDropdown
        clientLogo="content/images/customer-logo.png"
        imgUrl={`data:${currentUser.avatarContentType};base64,${currentUser.avatar}`}
        name={translate('global.menu.account.main')}
        id="account-menu"
        data-cy="accountMenu"
        avatarname={nameInitials}
        avatarCondition={currentUser.avatar}
      >
        {isAuthenticated && accountMenuItemsAuthenticated()}
        {!isAuthenticated && accountMenuItems()}
      </NavDropdown>
    </>
  );
};

export default AccountMenu;
