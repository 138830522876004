import React, { useState } from 'react';
import { useAppDispatch } from 'app/config/store';
import ShiftModel from 'app/shared/model/custom/custom-shift.model';
import { Dispatch, SetStateAction } from 'react';
import { duplicateShift, getShiftsByStartDateAndEndDate } from '../planning.reducer';
import Modal from 'app/shared/components/modal/Modal';
import { translate } from 'react-jhipster';
import { Button } from 'app/shared/components/button';
import * as Yup from 'yup';
import { validationSchema } from './DuplicateModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

interface ConfirmDuplicationModalProps {
  shift: ShiftModel;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsDuplicatePopupOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  refreshShift: () => void;
  values: Yup.InferType<typeof validationSchema>;
}

const ConfirmDuplicationModal = ({
  shift,
  values,
  setIsOpen,
  refreshShift,
  setIsDuplicatePopupOpen,
  isOpen,
}: ConfirmDuplicationModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleConfirmDuplication = async () => {
    setLoading(true);
    const selectedShift: Partial<ShiftModel> = values.shift as Partial<ShiftModel>;
    await dispatch(
      duplicateShift({
        originShiftId: shift.id,
        destinationShiftId: selectedShift.id,
        demands: values.positionDemand,
        equipment: values.equipmentPlans,
        resources: values.resourcePlans,
      }),
    );
    refreshShift();
    setLoading(false);
    setIsOpen(false);
    setIsDuplicatePopupOpen(false);
  };

  const onCancel = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      size="lg"
      title={`${translate('rosterApp.planning.confirmation-popup.confirm-duplication.title')}`}
      isOpen={isOpen}
      onClose={onCancel}
    >
      <div className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
        {translate('rosterApp.planning.confirmation-popup.confirm-duplication.message')}
      </div>
      {/* Buttons */}
      <div className="flex items-center justify-end gap-2 p-3 md:p-4 border-t border-gray-200 rounded-b bg-navyBlue">
        <Button variant="outline" type="button" onClick={onCancel}>
          {translate('entity.action.cancel')}
        </Button>
        <Button
          variant="destructive"
          className="flex gap-2"
          onClick={() => {
            handleConfirmDuplication();
          }}
        >
          {loading && <FontAwesomeIcon icon={faSpinner} spin={loading} />}
          {translate('rosterApp.planning.confirmation-popup.override')}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmDuplicationModal;
