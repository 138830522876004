import { useAppDispatch } from 'app/config/store';
import { Button } from 'app/shared/components/button';
import Modal from 'app/shared/components/modal/Modal';
import ValidationSelect from 'app/shared/components/validation/validation-select';
import ShiftModel from 'app/shared/model/custom/custom-shift.model';
import { ReportEntityType } from 'app/shared/types/types';
import { getFileExtension } from 'app/shared/util/file-utils';
import { saveAs } from 'file-saver';
import { useFormik } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import { translate } from 'react-jhipster';
import * as Yup from 'yup';
import { downloadReport, DownloadReportParams } from '../planning.reducer';

interface PrintModalProps {
  shift: ShiftModel;
  refreshShift: () => void;
  setPrintMessage: Dispatch<SetStateAction<string>>;
  setIsPrinting: Dispatch<SetStateAction<boolean>>;
  setIsPrintPopupOpen: Dispatch<SetStateAction<boolean>>;
  isPrintPopupOpen: boolean;
}

const validationSchema = Yup.object().shape({
  reportType: Yup.object({
    key: Yup.string().required('Report type key is required'),
    value: Yup.string().required('Report type value is required'),
  }).required('Report type is required'),
  format: Yup.object({
    key: Yup.string().required('Format key is required'),
    value: Yup.string().required('Format value is required'),
  }).required('Format is required'),
});

const PrintModal = ({ shift, refreshShift, setPrintMessage, setIsPrinting, setIsPrintPopupOpen, isPrintPopupOpen }: PrintModalProps) => {
  const reportType = [
    { key: 'RESOURCE_PLAN', value: translate('rosterApp.planning.print-options.resource-plan') },
    { key: 'SHIFT_DEMAND', value: translate('rosterApp.planning.print-options.shift-demand') },
    { key: 'SHIFT', value: translate('rosterApp.planning.print-options.shift') },
  ];

  const reportFormat = [
    { key: 'pdf', value: 'PDF' },
    { key: 'excel', value: 'EXCEL' },
  ];

  const dispatch = useAppDispatch();

  const formik = useFormik<Yup.InferType<typeof validationSchema>>({
    initialValues: {
      reportType: {},
      format: {},
    },
    validationSchema,
    onSubmit: values => submitForm(values),
  });

  const printReport = async (type: ReportEntityType) => {
    try {
      // Dispatch an action to download report
      const responseAction = await dispatch(downloadReport({ entity: 'RESOURCE_PLAN' } as DownloadReportParams));
      const response = responseAction.payload as Blob;

      if (!response) {
        setPrintMessage('Error printing report: Response is undefined');
        return;
      }

      const fileName = `resource_plan_${new Date().toISOString().slice(0, 10)}${getFileExtension('PDF')}`;
      saveAs(response, fileName);

      setPrintMessage('Report printed successfully');
    } catch (error) {
      setPrintMessage(`Error printing report: ${error.message}`);
    }
  };

  const submitForm = async (values: Yup.InferType<typeof validationSchema>) => {
    try {
      setIsPrinting(true);
      await printReport(values.reportType.key as ReportEntityType);
      setIsPrintPopupOpen(false);
    } catch (error) {
      setPrintMessage(`Error printing shifts: ${error.message}`);
    } finally {
      setIsPrinting(false);
    }
  };

  const onPrintCancel = () => {
    setIsPrintPopupOpen(false);
  };

  return (
    <Modal size="sm" title={'Confirmation'} isOpen={isPrintPopupOpen} onClose={onPrintCancel}>
      <form onSubmit={formik.handleSubmit}>
        <div className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
          <div
            className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full"
            style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}
          >
            <div className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <ValidationSelect
                  field="reportType"
                  formik={formik}
                  options={reportType}
                  optionsKey="key"
                  optionsValue="value"
                  label={'Print options'}
                />
              </div>
              <div className="sm:col-span-6">
                <ValidationSelect
                  formik={formik}
                  options={reportFormat}
                  optionsKey="key"
                  optionsValue="value"
                  label={'Format'}
                  field="format"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-2 items-center justify-end p-3 md:p-4 border-t border-gray-200 rounded-b bg-navyBlue">
          <Button variant="outline" type="button" onClick={() => setIsPrintPopupOpen(false)}>
            {translate('entity.action.cancel')}
          </Button>
          <Button type="submit" className={`ml-2 btn btn-primary`}>
            {translate('rosterApp.planning.confirmation-popup.print')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default PrintModal;
