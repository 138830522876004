import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import Modal from 'app/shared/components/modal/Modal';
import ValidationInput from 'app/shared/components/validation/validation-input';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import { translate } from 'react-jhipster';
import * as Yup from 'yup';
import { Button } from 'app/shared/components/button';
import { ITeam } from 'app/shared/model/team.model';
import { createEntity, updateEntity } from './team.reducer';
import ValidationSelect from 'app/shared/components/validation/validation-select';
import { getEntities as getOrganizations } from '../organisation/organisation.reducer';

type TeamAddEditProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  entity: ITeam;
};

const TeamAddEdit = ({ isOpen, onClose, onSave, entity }: TeamAddEditProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const organisations = useAppSelector(state => state.organisation.entities);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(translate('entity.validation.required')),
    organisation: Yup.object().required(translate('entity.validation.required')),
  });

  const formik = useFormik({
    initialValues: {
      name: entity?.name || '',
      organisation: entity?.organisation || '',
    },
    validationSchema,
    onSubmit: values => submitForm(values),
  });

  useEffect(() => {
    formik.resetForm();
    formik.setValues({
      name: entity?.name || '',
      organisation: entity?.organisation || '',
    });
  }, [entity, isOpen]);

  useEffect(() => {
    if (isOpen) {
      getAllTeams();
    }
  }, [isOpen]);

  const getAllTeams = () => {
    dispatch(getOrganizations({ sort: 'name,asc' }));
  };

  const submitForm = async values => {
    if (!loading) {
      setLoading(true);
      const req = {
        id: entity?.id,
        name: values.name,
        organisation: values.organisation,
      };

      try {
        if (entity?.id) {
          await dispatch(updateEntity(req));
        } else {
          await dispatch(createEntity(req));
        }
        onSave();
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      size="lg"
      title={`${translate(entity?.id ? 'rosterApp.team.home.createOrEditLabel' : 'rosterApp.team.home.createLabel')}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="p-3 md:p-4 space-y-3 max-h-[calc(90vh-140px)]">
          <div className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6 mb-4">
            <div className="sm:col-span-6">
              <ValidationInput formik={formik} label={translate('rosterApp.team.name')} field="name" />
            </div>
            <div className="sm:col-span-6">
              <ValidationSelect
                formik={formik}
                options={organisations}
                optionsKey="id"
                optionsValue="name"
                label={translate('rosterApp.team.organisation')}
                field="organisation"
                object
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end p-3 md:p-4 gap-2 border-t border-gray-200 rounded-b bg-navyBlue">
          <Button variant="outline" onClick={onClose} type="button">
            {translate('entity.action.cancel')}
          </Button>
          <Button variant="default" type="submit" disabled={loading}>
            {loading && <FontAwesomeIcon icon={faSpinner} spin={loading} />}
            {translate('entity.action.save')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default TeamAddEdit;
