import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Translate, getPaginationState, translate } from 'react-jhipster';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './permission.reducer';
import DataTable, { DataTableOptions } from 'app/shared/components/data-table/DataTable';
import { IPermission } from 'app/shared/model/permission.model';

export const Permission = ({ showDetails = false }) => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const [selectedPermission, setSelectedPermission] = useState<IPermission>();
  const permissionList = useAppSelector(state => state.permission.entities);
  const loading = useAppSelector(state => state.permission.loading);
  const totalItems = useAppSelector(state => state.permission.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const dtOptions: DataTableOptions = {
    headers: [translate('rosterApp.permission.id'), translate('rosterApp.permission.name')],
    columns: [
      {
        key: 'id',
        filterable: true,
        sortable: true,
      },
      {
        key: 'name',
        filterable: true,
        sortable: true,
      },
    ],
  };

  const handleAdd = () => {
    setSelectedPermission(null);
    navigate('/permission/new');
  };

  const handleUpdate = (rows: IPermission[]) => {
    setSelectedPermission(rows[0]);
    navigate(`/permission/${rows[0].id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`);
  };

  const handleDelete = (rows: IPermission[]) => {
    setSelectedPermission(rows[0]);
    navigate(`/permission/${rows[0].id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`);
  };

  const getDataTable = () => (
    <>
      {permissionList && (
        <DataTable
          data={permissionList}
          options={dtOptions}
          sort={sort}
          loading={loading}
          onRefresh={handleSyncList}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          paginationState={paginationState}
          onPaginationClick={handlePagination}
          paginationTotalItems={totalItems}
          paginationMaxButtons={5}
          showDetails={showDetails}
          createLabel={translate('rosterApp.permission.home.createLabel')}
          refreshListLabel={translate('rosterApp.permission.home.refreshListLabel')}
          notFoundLabel={translate('rosterApp.permission.home.notFound')}
        />
      )}
    </>
  );

  return (
    <>
      <h2 id="permission-heading" data-cy="PermissionHeading">
        <Translate contentKey="rosterApp.permission.home.title">Permissions</Translate>
      </h2>
      {getDataTable()}
    </>
  );
};

export default Permission;
