import ResourcesSearchInput from 'app/shared/components/resource-search-input';
import React, { useCallback } from 'react';
import CalendarNavigation from '../CalendarNavigation';
import { getDaysOfTheWeek } from 'app/shared/util/date-utils';
import { useAppSelector } from 'app/config/store';
import dayjs from 'dayjs';

const CalendarHead = () => {
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const daysOfWeek = getDaysOfTheWeek();

  const getDayTranslated = useCallback((day: dayjs.Dayjs) => day.locale(currentLocale).format('ddd').toUpperCase(), [currentLocale]);

  return (
    <thead>
      <tr>
        <th className="p-1 text-center flex flex-col items-center justify-center max-w-[250px]">
          <CalendarNavigation />
          <ResourcesSearchInput />
        </th>
        {daysOfWeek.map(day => (
          <th key={getDayTranslated(day)} className="bg-navyBlue text-white text-center border-l">
            {getDayTranslated(day)}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default CalendarHead;
