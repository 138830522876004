import { IOrganigram } from 'app/shared/model/organigram.model';
import { IRefActivityType } from 'app/shared/model/ref-activity-type.model';

export interface IDemandTemplate {
  id?: number;
  headcount?: number | null;
  organigram?: IOrganigram | null;
  refActivityType?: IRefActivityType | null;
}

export const defaultValue: Readonly<IDemandTemplate> = {};
