import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ResourcePlanRequest from './resource-plan-request';
import ResourcePlanRequestDetail from './resource-plan-request-detail';
import ResourcePlanRequestUpdate from './resource-plan-request-update';
import ResourcePlanRequestDeleteDialog from './resource-plan-request-delete-dialog';

const ResourcePlanRequestRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ResourcePlanRequest />} />
    <Route path="new" element={<ResourcePlanRequestUpdate />} />
    <Route path=":id">
      <Route index element={<ResourcePlanRequestDetail />} />
      <Route path="edit" element={<ResourcePlanRequestUpdate />} />
      <Route path="delete" element={<ResourcePlanRequestDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ResourcePlanRequestRoutes;
