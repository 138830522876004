import { IEquipment } from 'app/shared/model/equipment.model';
import React from 'react';

type EquipmentsType = {
  equipments: IEquipment[];
};

const Equipments = ({ equipments = [] }: EquipmentsType) => {
  if (!equipments.length) return;

  return (
    <div className="flex flex-col text-xs items-end p-2">
      {equipments
        .filter(equipment => equipment.type?.toUpperCase() === 'CRANE')
        .map(equipment => {
          return (
            <label key={equipment.id} className="rounded-full bg-blue-100 px-1 my-0.5 w-fit">
              {equipment.name}
            </label>
          );
        })}
    </div>
  );
};

export default Equipments;
