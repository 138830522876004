import { overridePaginationStateWithQueryParams } from '@util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '@util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import DataTable, { DataTableOptions } from 'app/shared/components/data-table/DataTable';
import LeaveCustom from 'app/shared/model/custom/custom-leave-model';
import { formatToDateOnly } from 'app/shared/util/date-utils';
import { getPaginationState, translate } from 'react-jhipster';
import LeaveAddEdit from './leave-add-edit';
import LeaveDelete from './leave-delete-modal';
import { getEntities } from './leave.reducer';

type CustomLeaveProps = {
  showDetails?: boolean;
};

const CustomLeave = ({ showDetails = false }: CustomLeaveProps) => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  pageLocation.search = '';

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'startTime', 'desc'), pageLocation.search),
  );

  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState<LeaveCustom>();
  const [deletingLeaves, setDeletingLeaves] = useState<LeaveCustom[]>();
  const leaveList = useAppSelector(state => state.leave.entities);
  const loading = useAppSelector(state => state.leave.loading);
  const totalItems = useAppSelector(state => state.leave.totalItems);

  const leaveDtOptions: DataTableOptions = {
    headers: [],
    columns: [
      {
        key: 'resource',
        filterable: true,
        filterableKey: 'name',
        sortable: false,
        render: row => (
          <Fragment>
            <Link className="text-primary capitalize hover:text-blue-700 no-underline font-medium" to={`/resource`}>
              {row.resource?.name}
            </Link>
          </Fragment>
        ),
      },
      {
        key: 'refLeaveType',
        filterable: true,
        filterableKey: 'name',
        sortable: true,
        render: row => (
          <Link className="text-primary hover:text-blue-700 no-underline font-medium" to={`/ref-leave-type`}>
            {row.refLeaveType?.name}
          </Link>
        ),
      },
      {
        key: 'startTime',
        filterable: true,
        sortable: true,
        render: row => formatToDateOnly(row.startTime),
      },
      {
        key: 'endTime',
        filterable: true,
        sortable: true,
        render: row => formatToDateOnly(row.endTime),
      },
      {
        key: 'status',
        filterable: true,
        sortable: true,
        render: row => row.status,
      },
    ],
  };

  leaveDtOptions.headers = leaveDtOptions.columns.map(c => translate(`rosterApp.leave.${c.key}`));

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);

    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleAdd = () => {
    setSelectedLeave(null);
    setIsAddEditModalOpen(true);
  };

  const handleUpdate = (rows: LeaveCustom[]) => {
    setSelectedLeave(rows[0]);
    setIsAddEditModalOpen(true);
  };

  const handleDelete = (rows: LeaveCustom[]) => {
    setIsDeleteModalOpen(true);
    setDeletingLeaves(rows);
  };

  const handleSaveAddEdit = () => {
    setIsAddEditModalOpen(false);
    sortEntities();
  };

  const handleCloseAddEdit = () => {
    setIsAddEditModalOpen(false);
  };

  const handleConfirmDelete = () => {
    setIsDeleteModalOpen(false);
    sortEntities();
  };

  const handleCloseDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const getDataTable = () => (
    <>
      {leaveList && (
        <DataTable
          data={leaveList}
          options={leaveDtOptions}
          sort={sort}
          loading={loading}
          onRefresh={handleSyncList}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          paginationState={paginationState}
          onPaginationClick={handlePagination}
          paginationTotalItems={totalItems}
          paginationMaxButtons={5}
          showDetails={showDetails}
          createLabel={translate('rosterApp.leave.home.createLabel')}
          refreshListLabel={translate('rosterApp.leave.home.refreshListLabel')}
          notFoundLabel={translate('rosterApp.leave.home.notFound')}
          title={showDetails && translate('global.menu.entities.leaves')}
        />
      )}
    </>
  );

  return (
    <>
      {!showDetails && (
        <h2 id="leaves-heading" className={`mb-2 whitespace-nowrap`} data-cy="LeavesHeading">
          {translate('global.menu.entities.leaves')}
        </h2>
      )}
      {getDataTable()}
      <LeaveAddEdit isOpen={isAddEditModalOpen} onClose={handleCloseAddEdit} onSave={handleSaveAddEdit} leave={selectedLeave} />
      <LeaveDelete isOpen={isDeleteModalOpen} onClose={handleCloseDelete} onConfirm={handleConfirmDelete} deletingLeaves={deletingLeaves} />
    </>
  );
};

export default CustomLeave;
