import { IRefDepartment } from 'app/shared/model/ref-department.model';
import { IResource } from 'app/shared/model/resource.model';

export interface IFacility {
  id?: number;
  name?: string;
  refDepartments?: IRefDepartment[] | null;
  resources?: IResource[] | null;
}

export const defaultValue: Readonly<IFacility> = {};
