import activityEquipment from 'app/entities/activity-equipment/activity-equipment.reducer';
import activity from 'app/entities/activity/activity.reducer';
import applicationSettings from 'app/entities/application-settings/application-settings.reducer';
import equipment from 'app/entities/equipment/equipment.reducer';
import leave from 'app/entities/leave/leave.reducer';
import facility from 'app/entities/facility/facility.reducer';
import organigram from 'app/entities/organigram/organigram.reducer';
import permission from 'app/entities/permission/permission.reducer';
import positionRequirement from 'app/entities/position-requirement/position-requirement.reducer';
import refCalendar from 'app/entities/ref-calendar/ref-calendar.reducer';
import refDepartment from 'app/entities/ref-department/ref-department.reducer';
import refLeaveType from 'app/entities/ref-leave-type/ref-leave-type.reducer';
import customRefLeaveType from 'app/entities/custom-ref-leave-type/ref-leave-type.reducer';
import refPosition from 'app/entities/ref-position/ref-position.reducer';
import refRotation from 'app/entities/ref-rotation/ref-rotation.reducer';
import refShiftType from 'app/entities/ref-shift-type/ref-shift-type.reducer';
import refTraining from 'app/entities/ref-training/ref-training.reducer';
import resourcePlan from 'app/entities/resource-plan/resource-plan.reducer';
import resourceTraining from 'app/entities/resource-training/resource-training.reducer';
import resource from 'app/entities/resource/resource.reducer';
import shiftDemand from 'app/entities/shift-demand/shift-demand.reducer';
import shift from 'app/entities/shift/shift.reducer';
import team from 'app/entities/team/team.reducer';

import demandTemplate from 'app/entities/demand-template/demand-template.reducer';
import equipmentPlan from 'app/entities/equipment-plan/equipment-plan.reducer';
import organisation from 'app/entities/organisation/organisation.reducer';
import refActivityType from 'app/entities/ref-activity-type/ref-activity-type.reducer';
import refEquipmentType from 'app/entities/ref-equipment-type/ref-equipment-type.reducer';
import resourcePlanRequest from 'app/entities/resource-plan-request/resource-plan-request.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  permission,
  resource,
  team,
  shift,
  resourcePlan,
  refTraining,
  resourceTraining,
  refDepartment,
  facility,
  activity,
  refPosition,
  organigram,
  positionRequirement,
  shiftDemand,
  refRotation,
  refShiftType,
  refCalendar,
  applicationSettings,
  refLeaveType,
  customRefLeaveType,
  leave,
  equipment,
  activityEquipment,
  refActivityType,
  demandTemplate,
  equipmentPlan,
  refEquipmentType,
  resourcePlanRequest,
  organisation,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
