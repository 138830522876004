import { IRootState, useAppSelector } from 'app/config/store';
import { hasPermission } from 'app/shared/reducers/authentication.selector';
import { ShiftActionType } from 'app/shared/types/types';
import React from 'react';
import { translate } from 'react-jhipster';

type ContextMenuProps = {
  onMenuItemClick: (actionType: ShiftActionType) => void;
  forwardedRef?: any;
};

const ContextMenu = ({ onMenuItemClick, forwardedRef }: ContextMenuProps) => {
  // TODO
  const hasSplitShiftPermission = true;
  const hasPrintShiftPermission = useAppSelector((state: IRootState) => hasPermission(state, 'shift_print'));
  // TODO
  const hasAutoDemandPermission = true; //useAppSelector((state: IRootState) => hasPermission(state, 'auto_demand'));
  const hasAutoPlanPermission = useAppSelector((state: IRootState) => hasPermission(state, 'auto_plan'));
  const hasApproveShiftPermission = useAppSelector((state: IRootState) => hasPermission(state, 'plan_approve'));
  const hasSubmitShiftPermission = useAppSelector((state: IRootState) => hasPermission(state, 'plan_submit'));
  const hasPublishShiftPermission = true; //useAppSelector((state: IRootState) => hasPermission(state, 'plan_publish'));
  const hasDuplicatedShiftPermission = true; //useAppSelector((state:IRootState) => hasPermission(state,'shift_duplicate'));
  const hasClearPlansPermission = true; //useAppSelector((state:IRootState) => hasPermission(state,'clear_plans'));
  return (
    <div className={`absolute m-2 z-1000`} style={{ top: '95%', minWidth: '95%' }} ref={forwardedRef}>
      <ul className="flex flex-col bg-slate-50 rounded-md shadow-xl overflow-hidden p-0 m-0 border border-slate-200 whitespace-nowrap">
        {hasSplitShiftPermission && (
          <li className="context-menu-item" onClick={() => onMenuItemClick('SPLIT')}>
            {translate('rosterApp.planning.context-menu.split')}
          </li>
        )}
        {hasDuplicatedShiftPermission && (
          <li className="context-menu-item" onClick={() => onMenuItemClick('DUPLICATE')}>
            {translate('rosterApp.planning.context-menu.duplicate')}...
          </li>
        )}
        {hasClearPlansPermission && (
          <li className="context-menu-item" onClick={() => onMenuItemClick('CLEAR_PLANS')}>
            {translate('rosterApp.planning.context-menu.clear-plans')}...
          </li>
        )}
        {hasPrintShiftPermission && (
          <li className="context-menu-item" onClick={() => onMenuItemClick('PRINT')}>
            {translate('rosterApp.planning.context-menu.print')}
          </li>
        )}
        {hasAutoDemandPermission && (
          <li className="context-menu-item" onClick={() => onMenuItemClick('AUTO_DEMAND')}>
            {translate('rosterApp.planning.context-menu.auto-demand')}
          </li>
        )}
        {hasAutoPlanPermission && (
          <li className="context-menu-item" onClick={() => onMenuItemClick('AUTO_PLAN')}>
            {translate('rosterApp.planning.context-menu.auto-plan')}
          </li>
        )}
        {hasSubmitShiftPermission && (
          <li className="context-menu-item" onClick={() => onMenuItemClick('SUBMIT')}>
            {translate('rosterApp.planning.context-menu.submit')}
          </li>
        )}
        {hasApproveShiftPermission && (
          <li className="context-menu-item" onClick={() => onMenuItemClick('APPROVE')}>
            {translate('rosterApp.planning.context-menu.approve')}
          </li>
        )}
        {hasPublishShiftPermission && (
          <li className="context-menu-item" onClick={() => onMenuItemClick('PUBLISH')}>
            {translate('rosterApp.planning.context-menu.publish')}
          </li>
        )}
      </ul>
    </div>
  );
};

export default ContextMenu;
