import './header.scss';

import React, { useEffect } from 'react';
import { Translate, Storage } from 'react-jhipster';
import LoadingBar from 'react-redux-loading-bar';

import { isRTL } from 'app/config/translation';
import { useAppDispatch } from 'app/config/store';
import { setLocale } from 'app/shared/reducers/locale';
import Navbar from 'app/shared/layout/navbar/navbar';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
}

const Header = (props: IHeaderProps) => {
  useEffect(() => document.querySelector('html').setAttribute('dir', isRTL(Storage.session.get('locale')) ? 'rtl' : 'ltr'));

  const dispatch = useAppDispatch();

  const handleLocaleChange = langKey => {
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
    document.querySelector('html').setAttribute('dir', isRTL(langKey) ? 'rtl' : 'ltr');
  };

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

  return (
    <>
      <div id="app-header">
        {/*{renderDevRibbon()}*/}
        <LoadingBar className="loading-bar" />
        <Navbar props={props} handleLocaleChange={handleLocaleChange} />
      </div>
    </>
  );
};

export default Header;
