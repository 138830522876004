import React, { Component } from 'react';

class ParticleCanvas extends Component {
  private canvasRef: React.RefObject<HTMLCanvasElement>;
  private ctx: CanvasRenderingContext2D | null;
  private particles: Particle[];
  private animationFrame: number | null;

  constructor(props: {}) {
    super(props);
    this.canvasRef = React.createRef();
    this.ctx = null;
    this.particles = [];
    this.animationFrame = null;
  }

  componentDidMount() {
    this.initCanvas();
    this.animate();
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.animationFrame!);
  }

  initCanvas() {
    const canvas = this.canvasRef.current!;
    this.ctx = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    this.particles = [];
    // Generate initial particles
    for (let i = 0; i < 40; i++) {
      this.particles.push(new Particle(canvas.width, canvas.height));
    }
  }

  animate = () => {
    this.animationFrame = requestAnimationFrame(this.animate);
    if (this.ctx) {
      this.ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
      this.particles.forEach(particle => {
        particle.update();
        particle.draw(this.ctx!);
      });
    }
  };

  render() {
    return <canvas ref={this.canvasRef} />;
  }
}

class Particle {
  private x: number;
  private y: number;
  private radius: number;
  private velocityX: number;
  private velocityY: number;
  private color: string;

  constructor(canvasWidth: number, canvasHeight: number) {
    this.x = Math.random() * canvasWidth;
    this.y = Math.random() * canvasHeight;
    this.radius = Math.random() * 2 + 1;
    this.velocityX = Math.random() - 0.5; // Random velocity
    this.velocityY = Math.random() - 0.5; // Random velocity
    this.color = `rgb(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.4)`;
  }

  update() {
    this.x += this.velocityX;
    this.y += this.velocityY;
    // Bounce off the walls
    if (this.x <= 0 || this.x >= window.innerWidth) {
      this.velocityX *= -1;
    }
    if (this.y <= 0 || this.y >= window.innerHeight) {
      this.velocityY *= -1;
    }
  }

  draw(ctx: CanvasRenderingContext2D) {
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
    ctx.fillStyle = this.color;
    ctx.fill();
  }
}

export default ParticleCanvas;
