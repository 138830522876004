import { IOrganisation } from 'app/shared/model/organisation.model';
import { IResource } from 'app/shared/model/resource.model';
import { IShift } from 'app/shared/model/shift.model';
import { IRefRotation } from 'app/shared/model/ref-rotation.model';

export interface ITeam {
  id?: number;
  name?: string;
  organisation?: IOrganisation | null;
  resources?: IResource[] | null;
  shifts?: IShift[] | null;
  refRotations?: IRefRotation[] | null;
}

export const defaultValue: Readonly<ITeam> = {};
