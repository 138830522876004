import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Translate, getPaginationState, translate } from 'react-jhipster';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { deleteEntity, getEntities } from './organigram.reducer';
import DataTable, { DataTableOptions } from 'app/shared/components/data-table/DataTable';
import { IOrganigram } from 'app/shared/model/organigram.model';
import OrganigramAddEdit from './organigram-add-edit';
import DeleteConfirmationPopup from 'app/shared/components/confirmation-popup/delete-confirmation-popup';

export const Organigram = ({ showDetails = false }) => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  pageLocation.search = '';

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState<IOrganigram>();
  const [deletingEntities, setDeletingEntities] = useState<IOrganigram[]>();
  const [confirmationText, setConfirmationText] = useState('');
  const organigramList = useAppSelector(state => state.organigram.entities);
  const loading = useAppSelector(state => state.organigram.loading);
  const totalItems = useAppSelector(state => state.organigram.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.order}`, // `${paginationState.sort},${paginationState.order}`
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const dtOptions: DataTableOptions = {
    headers: [
      translate('rosterApp.organigram.refDepartment'),
      translate('rosterApp.organigram.refPosition'),
      translate('rosterApp.organigram.demandCoefficient'),
      translate('rosterApp.organigram.demandConstant'),
      translate('rosterApp.organigram.demandMin'),
    ],
    columns: [
      {
        key: 'refDepartment',
        filterable: true,
        filterableKey: 'name',
        sortable: true,
        render: row => (
          <Link className="text-primary hover:text-blue-700 no-underline font-medium" to={`/ref-department/${row?.refDepartment?.id}`}>
            {row?.refDepartment?.name}
          </Link>
        ),
      },
      {
        key: 'refPosition',
        filterable: true,
        filterableKey: 'name',
        sortable: true,
        render: row => (
          <Link className="text-primary hover:text-blue-700 no-underline font-medium" to={`/ref-position/${row?.refPosition?.id}`}>
            {row?.refPosition?.name}
          </Link>
        ),
      },
      { key: 'demandCoefficient', filterable: true, sortable: true },
      { key: 'demandConstant', filterable: true, sortable: true },
      { key: 'demandMin', filterable: true, sortable: true },
    ],
  };

  const handleAdd = () => {
    setSelectedEntity(null);
    setIsModalOpen(true);
  };

  const handleUpdate = (rows: IOrganigram[]) => {
    setSelectedEntity(rows[0]);
    setIsModalOpen(true);
  };

  const handleDelete = (rows: IOrganigram[]) => {
    setConfirmationText(
      rows.length === 1
        ? translate('rosterApp.organigram.delete.question', { id: rows[0].id })
        : translate('rosterApp.organigram.delete.multipleQuestion', { param: rows.length }),
    );
    setDeletingEntities(rows);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSave = () => {
    setIsModalOpen(false);
    sortEntities();
  };

  const deleteItems = () => {
    Promise.all(deletingEntities.map(row => dispatch(deleteEntity(row.id))))
      .then(() => {
        setConfirmationText('');
        setSelectedEntity(null);
      })
      .finally(() => {
        sortEntities();
      });
  };

  return (
    <>
      {!showDetails && (
        <h2 id="organigram-heading" className={`mb-2 whitespace-nowrap`} data-cy="OrganigramHeading">
          <Translate contentKey="global.menu.entities.organigrams">Organigrams</Translate>
        </h2>
      )}
      {organigramList && (
        <DataTable
          data={organigramList}
          options={dtOptions}
          sort={sort}
          loading={loading}
          onRefresh={handleSyncList}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          paginationState={paginationState}
          onPaginationClick={handlePagination}
          paginationTotalItems={totalItems}
          paginationMaxButtons={5}
          createLabel={translate('rosterApp.organigram.home.createLabel')}
          refreshListLabel={translate('rosterApp.organigram.home.refreshListLabel')}
          notFoundLabel={translate('rosterApp.organigram.home.notFound')}
          title={showDetails && translate('global.menu.entities.organigrams')}
        />
      )}
      <DeleteConfirmationPopup
        open={confirmationText?.length > 0}
        onSave={deleteItems}
        onCancel={() => setConfirmationText('')}
        children={confirmationText}
        saveClassName="btn-danger"
      />
      <OrganigramAddEdit isOpen={isModalOpen} onClose={handleClose} onSave={handleSave} entity={selectedEntity} />
    </>
  );
};

export default Organigram;
