enum EquipmentStatusEnum {
  AVAILABLE = 'AVAILABLE',
  MAINTENANCE = 'MAINTENANCE',
  BREAKDOWN = 'BREAKDOWN',
  UNAVAILABLE = 'UNAVAILABLE',
}

export const ALL_EQUIPMENT_STATUS_VALUES = Object.values(EquipmentStatusEnum).filter(e => typeof e === 'string');
export type EquipmentStatus = keyof typeof EquipmentStatusEnum;
export default EquipmentStatusEnum;
