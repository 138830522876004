import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { formatDate } from 'app/shared/util/date-utils';
import { getEntity } from './equipment-plan.reducer';

export const EquipmentPlanDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const equipmentPlanEntity = useAppSelector(state => state.equipmentPlan.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="equipmentPlanDetailsHeading">
          <Translate contentKey="rosterApp.equipmentPlan.detail.title">EquipmentPlan</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{equipmentPlanEntity.id}</dd>
          <dt>
            <span id="notes">
              <Translate contentKey="rosterApp.equipmentPlan.notes">Notes</Translate>
            </span>
          </dt>
          <dd>{equipmentPlanEntity.notes}</dd>
          <dt>
            <span id="startTime">
              <Translate contentKey="rosterApp.equipmentPlan.startTime">Start Time</Translate>
            </span>
          </dt>
          <dd>{equipmentPlanEntity?.startTime ? formatDate(equipmentPlanEntity.startTime) : null}</dd>
          <dt>
            <span id="endTime">
              <Translate contentKey="rosterApp.equipmentPlan.endTime">End Time</Translate>
            </span>
          </dt>
          <dd>{equipmentPlanEntity?.endTime ? formatDate(equipmentPlanEntity.endTime) : null}</dd>
          <dt>
            <Translate contentKey="rosterApp.equipmentPlan.equipment">Equipment</Translate>
          </dt>
          <dd>{equipmentPlanEntity.equipment ? equipmentPlanEntity.equipment.id : ''}</dd>
          <dt>
            <Translate contentKey="rosterApp.equipmentPlan.resource">Resource</Translate>
          </dt>
          <dd>{equipmentPlanEntity.resource ? equipmentPlanEntity.resource.id : ''}</dd>
          <dt>
            <Translate contentKey="rosterApp.equipmentPlan.shift">Shift</Translate>
          </dt>
          <dd>{equipmentPlanEntity.shift ? equipmentPlanEntity.shift.id : ''}</dd>
          <dt>
            <Translate contentKey="rosterApp.equipmentPlan.organigram">Organigram</Translate>
          </dt>
          <dd>{equipmentPlanEntity.organigram ? equipmentPlanEntity.organigram.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/equipment-plan" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/equipment-plan/${equipmentPlanEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default EquipmentPlanDetail;
