import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { translate } from 'react-jhipster';

// Helper function to get the value of a nested object by a string path (e.g., 'refPosition.name')
const getNestedValue = (obj, path) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

const ValidationSelect = ({
  formik,
  options,
  optionsKey,
  optionsValue = undefined,
  displayMapping = {},
  label = '',
  field,
  object = true,
  autoHeight = false,
  sort = '',
  loading = false,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const dropdownRef = useRef(null);

  const selectAnOptionLabel = useMemo(() => translate('global.form.select-an-option'), [translate]);

  useEffect(() => {
    if (formik.values[field] !== selectedOption) {
      formik.setFieldValue(field, selectedOption);
    }
  }, [selectedOption]);

  useEffect(() => {
    const currentFieldValue = formik?.values[field];
    if (currentFieldValue === '' && selectedOption !== null) {
      setSelectedOption(null);
    } else if (currentFieldValue !== selectedOption) {
      setSelectedOption(currentFieldValue || null);
    }
  }, [formik?.values[field]]);

  const isNotValid = () => {
    return formik.touched[field] && formik.errors[field];
  };

  const toggleOption = option => {
    setSelectedOption(option);
    formik.setFieldValue(field, option);
    setIsDropdownOpen(false);
  };

  const getSelected = () => {
    const selectedValue = formik.values[field];
    if (!selectedValue) return selectAnOptionLabel;

    const selectedOption = options.find(option => {
      if (object) {
        return getNestedValue(option, optionsKey) === getNestedValue(selectedValue, optionsKey);
      } else {
        return getNestedValue(option, optionsKey) === selectedValue;
      }
    });

    if (!selectedOption) {
      return selectAnOptionLabel;
    }

    // Check if displayMapping is provided; if not, fall back to optionsValue
    if (Object.keys(displayMapping).length > 0) {
      const concatenatedValue = Object.entries(displayMapping)
        .map(([label, path]) => `${getNestedValue(selectedOption, path) || ''}`)
        .join(' - ');
      return concatenatedValue || selectAnOptionLabel;
    } else {
      return object ? getNestedValue(selectedOption, optionsValue) : selectedOption.value;
    }
  };

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Sort options based on the provided sort prop
  const sortedOptions = [...options].sort((a, b) => {
    if (sort) {
      const aValue = getNestedValue(a, displayMapping[sort] || sort);
      const bValue = getNestedValue(b, displayMapping[sort] || sort);
      if (aValue && bValue) {
        return aValue.localeCompare(bValue);
      }
    }
    return 0;
  });

  return (
    <Fragment>
      {label && (
        <label htmlFor={field} className={`${isNotValid() ? 'text-destructive' : 'text-gray-900'} block text-sm font-medium`}>
          {label}
        </label>
      )}
      <div
        ref={dropdownRef}
        className={`mt-1 relative transition-all delay-200`}
        style={{ height: autoHeight && isDropdownOpen && options?.length ? options.length * 36 + 40 : 'unset' }}
      >
        <div
          id={field}
          className="bg-blue-50 appearance-none border rounded-md py-2 px-4 leading-tight focus:outline-none focus:border-indigo-500 w-full cursor-pointer"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          {loading ? (
            <div className="flex items-center justify-center">
              <svg className="animate-spin h-5 w-5 text-gray-400" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
              </svg>
            </div>
          ) : (
            getSelected()
          )}
          <svg
            className="h-5 w-5 text-gray-400 absolute right-0 top-0 m-2 pointer-events-none"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M19 9l-7 7-7-7"></path>
          </svg>
        </div>
        {isDropdownOpen && (
          <div className="custom-scrollbar absolute bg-blue-50 shadow-md rounded-md mt-1 w-full z-10 overflow-y-auto max-h-44 border">
            {sortedOptions.length > 0 ? (
              sortedOptions.map(option => (
                <div
                  key={getNestedValue(option, optionsKey)}
                  className={`cursor-pointer text-sm flex items-center py-2 px-4 ${
                    (
                      object
                        ? getNestedValue(selectedOption, optionsKey) === getNestedValue(option, optionsKey)
                        : selectedOption === getNestedValue(option, optionsKey)
                    )
                      ? 'bg-blue-100 hover:bg-blue-200'
                      : 'hover:bg-slate-100'
                  }`}
                  onClick={() => toggleOption(object ? option : getNestedValue(option, optionsKey))}
                >
                  {/* Display concatenated values if displayMapping is provided, else fallback to optionsValue */}
                  {Object.keys(displayMapping).length > 0
                    ? Object.entries(displayMapping)
                        .map(([label, path]) => `${getNestedValue(option, path) || ''}`)
                        .join(' - ')
                    : getNestedValue(option, optionsValue)}
                </div>
              ))
            ) : (
              <div className="text-sm text-gray-500 py-2 px-4">No items to select</div>
            )}
          </div>
        )}
      </div>
      {formik.touched[field] && formik.errors[field] ? <div className="text-destructive text-xs mt-1">{formik.errors[field]}</div> : null}
    </Fragment>
  );
};

export default ValidationSelect;
