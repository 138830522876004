import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Activity from './activity/activity';
import ActivityEquipment from './activity-equipment';
import ApplicationSettings from './application-settings';
import DemandTemplate from './demand-template/demand-template';
import Equipment from './equipment/equipment';
import EquipmentPlan from './equipment-plan';
import Facility from './facility/facility';
import Leave from './leave/leave';
import Organigram from './organigram/organigram';
import Organisation from './organisation/organisation';
import Permission from './permission';
import PositionRequirement from './position-requirement';
import RefActivityType from './ref-activity-type/ref-activity-type';
import RefCalendar from './ref-calendar';
import RefDepartment from './ref-department/ref-department';
import RefEquipmentType from './ref-equipment-type/ref-equipment-type';
import RefLeaveType from './custom-ref-leave-type/ref-leave-type';
import RefPosition from './ref-position/ref-position';
import RefRotation from './ref-rotation/ref-rotation';
import RefShiftType from './ref-shift-type/ref-shift-type';
import RefTraining from './ref-training/ref-training';
import Resource from './resource/resource';
import ResourcePlan from './resource-plan';
import ResourcePlanRequest from './resource-plan-request';
import ResourceTraining from './resource-training/resource-training';
import Shift from './shift';
import ShiftDemand from './shift-demand';
import Team from './team/team';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div style={{ height: 'calc(100% - 46px)' }}>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="permission/*" element={<Permission />} />
        <Route path="resource/*" element={<Resource />} />
        <Route path="team/*" element={<Team />} />
        <Route path="shift/*" element={<Shift />} />
        <Route path="resource-plan/*" element={<ResourcePlan />} />
        <Route path="ref-training/*" element={<RefTraining />} />
        <Route path="resource-training/*" element={<ResourceTraining />} />
        <Route path="ref-department/*" element={<RefDepartment />} />
        <Route path="facility/*" element={<Facility />} />
        <Route path="activity/*" element={<Activity />} />
        <Route path="ref-position/*" element={<RefPosition />} />
        <Route path="organigram/*" element={<Organigram />} />
        <Route path="position-requirement/*" element={<PositionRequirement />} />
        <Route path="shift-demand/*" element={<ShiftDemand />} />
        <Route path="ref-rotation/*" element={<RefRotation />} />
        <Route path="ref-shift-type/*" element={<RefShiftType />} />
        <Route path="ref-calendar/*" element={<RefCalendar />} />
        <Route path="application-settings/*" element={<ApplicationSettings />} />
        <Route path="ref-leave-type/*" element={<RefLeaveType />} />
        <Route path="leave/*" element={<Leave />} />
        <Route path="equipment/*" element={<Equipment />} />
        <Route path="activity-equipment/*" element={<ActivityEquipment />} />
        <Route path="ref-activity-type/*" element={<RefActivityType />} />
        <Route path="demand-template/*" element={<DemandTemplate />} />
        <Route path="equipment-plan/*" element={<EquipmentPlan />} />
        <Route path="ref-equipment-type/*" element={<RefEquipmentType />} />
        <Route path="resource-plan-request/*" element={<ResourcePlanRequest />} />
        <Route path="organisation/*" element={<Organisation />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
