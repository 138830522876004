import React from 'react';

type ShiftSubItemCounterProps = {
  count: number;
  onChange: (value: number) => void;
};

const ShiftSubItemCounter = ({ count = 0, onChange }: ShiftSubItemCounterProps) => (
  <div className="flex items-center gap-2">
    <input
      type="number"
      value={count}
      min={0}
      onChange={event => onChange(Number(event.target.value) ?? 0)}
      className="shadow-sm bg-gray-50 border-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5 mx-2 text-center"
    />
  </div>
);

export default ShiftSubItemCounter;
