import dayjs from 'dayjs';
import { IEquipment } from 'app/shared/model/equipment.model';
import { IActivity } from 'app/shared/model/activity.model';

export interface IActivityEquipment {
  id?: number;
  startTime?: dayjs.Dayjs | null;
  endTime?: dayjs.Dayjs | null;
  equipment?: IEquipment | null;
  activity?: IActivity | null;
}

export const defaultValue: Readonly<IActivityEquipment> = {};
