enum LeaveStatusEnum {
  DRAFT = 'DRAFT',
  REQUESTED = 'REQUESTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export const ALL_LEAVE_STATUS_VALUES = Object.values(LeaveStatusEnum).filter(e => typeof e === 'string');
export type LeaveStatus = keyof typeof LeaveStatusEnum;
export default LeaveStatusEnum;
