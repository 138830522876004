import { overridePaginationStateWithQueryParams } from '@util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '@util/pagination.constants';
import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useMemo, useState } from 'react';
import { getPaginationState, translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEligibleResources, getShiftEligibleResources } from 'app/modules/planning/planning.reducer';
import Avatar from 'app/shared/components/avatar/Avatar';
import { Button } from 'app/shared/components/button';
import DeleteConfirmationPopup from 'app/shared/components/confirmation-popup/delete-confirmation-popup';
import DataTable, { DataTableOptions } from 'app/shared/components/data-table/DataTable';
import Tooltip from 'app/shared/components/tooltip/Tooltip';
import { ResourceType } from 'app/shared/enums';
import Position from 'app/shared/model/custom/custom-position.model';
import ResourceUserCustom from 'app/shared/model/custom/custom-resource-user.model';
import Resource from 'app/shared/model/custom/custom-resource.model';
import { IEligibleResources } from 'app/shared/model/custom/eligible-resources.model';
import { IResource } from 'app/shared/model/resource.model';
import { hasPermission } from 'app/shared/reducers/authentication.selector';
import { getImageUrl } from 'app/shared/util/file-utils';
import { getResourceTypeI18nTranslation } from 'app/shared/util/i18n';
import ResourceAddEdit from './resource-add-edit';
import { deleteResource, getEntities } from './resource.reducer';

type CustomResourceProps = {
  showDetails?: boolean;
  selectedShiftOrPosition?: { shiftId: number; organigramId: number; position: Position };
  planResourcesInShift?: (selectedRows: IResource[]) => void;
};

export const CustomResource = ({ showDetails = false, selectedShiftOrPosition = null, planResourcesInShift }: CustomResourceProps) => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  pageLocation.search = '';

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'name'), pageLocation.search),
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');
  const [selectedResource, setSelectedResource] = useState<ResourceUserCustom>();
  const [deletingResources, setDeletingResources] = useState<IResource[]>();
  const resourceList = useAppSelector(state => state.resource.entities);
  const loading = useAppSelector(state => state.resource.loading);
  const totalItems = useAppSelector(state => state.resource.totalItems);
  const hasResourceAddPermission = useAppSelector((state: IRootState) => hasPermission(state, 'resource_add'));
  const eligibleResources = useAppSelector(state => state.planning.eligibleResources) as IEligibleResources;
  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const [data, setData] = useState<any>([]);
  const [eligibleResourcesLoading, setEligibleResourcesLoading] = useState<boolean>(false);

  const dtOptions: DataTableOptions = {
    headers: [],
    columns: [
      {
        key: 'name',
        filterable: true,
        sortable: true,
        render: row => (
          <div className="flex items-center">
            <Avatar
              resourceTeamId={row?.team?.id}
              size="w-8 h-8"
              src={getImageUrl(row.avatarContentType, row.avatar)}
              alt={`${row.firstName.substring(0, 1)}${row.lastName.substring(0, 1)}`}
            />
            {showDetails ? (
              <div className="ml-4 w-full">
                <h6 className="text-slate-800 mb-0">{`${row.firstName} ${row.lastName}`}</h6>
                <span className="text-slate-400 block -mt-1 text-sm">{row.name}</span>
              </div>
            ) : (
              <h6 className="ml-4 text-slate-800 mb-0">{row.name}</h6>
            )}
          </div>
        ),
      },
      { key: 'firstName', filterable: true, sortable: true },
      { key: 'lastName', filterable: true, sortable: true },
      {
        key: 'user',
        filterable: true,
        filterableKey: 'email',
        sortable: true,
        render: row => <>{row.user?.email}</>,
      },
      {
        key: 'team',
        filterable: true,
        filterableKey: 'name',
        sortable: true,
        render: row => (
          <>
            {row.team ? (
              <Link className="text-primary hover:text-blue-700 no-underline font-medium" to={`/team/${row.team?.id}`}>
                {row.team?.name}
              </Link>
            ) : (
              ''
            )}
          </>
        ),
      },
      {
        key: 'refDepartment',
        filterable: true,
        filterableKey: 'name',
        sortable: true,
        render: row => (
          <>
            {row.refDepartment ? (
              <Link className="text-primary hover:text-blue-700 no-underline font-medium" to={`/ref-department/${row.refDepartment.id}`}>
                {row.refDepartment.name}
              </Link>
            ) : (
              ''
            )}
          </>
        ),
      },
      {
        key: 'facility',
        filterable: true,
        filterableKey: 'name',
        sortable: true,
        render: row => (
          <>
            {row.facility ? (
              <Link className="text-primary hover:text-blue-700 no-underline font-medium" to={`/facility/${row.facility?.id}`}>
                {row.facility?.name}
              </Link>
            ) : (
              ''
            )}
          </>
        ),
      },
      {
        key: 'type',
        filterable: true,
        sortable: true,
        render: row => <>{getResourceTypeI18nTranslation(row?.type as ResourceType)}</>,
      },
      {
        key: 'exchangeAllowed',
        filterable: true,
        sortable: true,
        // Commented just in case need for Demo render: row => <>{row?.exchangeAllowed ? row?.exchangeAllowed?.toString() : 'false'}</>,
        render: row => (
          <div className="text-center">
            <Tooltip text={row?.exchangeAllowed ? row?.exchangeAllowed?.toString() : 'false'} position="bottom">
              <div className={`rounded-full h-5 w-5 ${row?.exchangeAllowed ? 'bg-green-300' : 'bg-gray-300'}`}></div>
            </Tooltip>
          </div>
        ),
      },
    ],
  };

  dtOptions.headers = dtOptions.columns.map(c => translate(`rosterApp.resource.${c.key}`));

  useEffect(() => {
    setNewData();
  }, [selectedShiftOrPosition]);

  useEffect(() => {
    const { shiftResources, shiftPreferredResources, remainingResources } = eligibleResources;
    const allEligibleResources = (shiftPreferredResources ?? []).concat(shiftResources ?? []).concat(remainingResources ?? []);
    setData(allEligibleResources);
  }, [eligibleResources]);

  const setNewData = async () => {
    if (selectedShiftOrPosition?.shiftId && selectedShiftOrPosition?.organigramId) {
      setEligibleResourcesLoading(() => true);
      await dispatch(
        getEligibleResources({ shiftId: selectedShiftOrPosition.shiftId, organigramId: selectedShiftOrPosition.organigramId }),
      );
      setEligibleResourcesLoading(() => false);
    } else if (selectedShiftOrPosition?.shiftId) {
      setEligibleResourcesLoading(() => true);
      await dispatch(getShiftEligibleResources(selectedShiftOrPosition?.shiftId));
      setEligibleResourcesLoading(() => false);
    }
  };

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    if (selectedShiftOrPosition) setNewData();
    else sortEntities();
  };

  const handleAdd = () => {
    setSelectedResource(null);
    setIsModalOpen(true);
  };

  const handleUpdate = (rows: Resource[]) => {
    setSelectedResource(rows[0]);
    setIsModalOpen(true);
  };

  const handleSave = () => {
    setIsModalOpen(false);
    sortEntities();
  };

  const handleDelete = (rows: Resource[]) => {
    setConfirmationText(
      rows.length === 1
        ? translate('rosterApp.resource.delete.question', { id: rows[0].name })
        : translate('rosterApp.resource.delete.multipleQuestion', { param: rows.length }),
    );
    setDeletingResources(rows);
  };

  const deleteItems = async () => {
    try {
      for (const row of deletingResources) {
        await dispatch(deleteResource(row.id));
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      sortEntities();
      setConfirmationText('');
      setSelectedResource(null);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const dataTableTitle = useMemo(() => {
    if (selectedShiftOrPosition?.shiftId && selectedShiftOrPosition?.organigramId) {
      return translate('rosterApp.resource.home.eligibleTitle', { positionName: selectedShiftOrPosition.position.name });
    } else if (selectedShiftOrPosition?.shiftId) {
      return translate('rosterApp.resource.home.shiftEligibleTitle');
    } else {
      return translate('global.menu.entities.resources');
    }
  }, [translate, selectedShiftOrPosition, currentLocale]);

  const getDataTable = () => (
    <>
      {resourceList && (
        <DataTable
          data={selectedShiftOrPosition ? data : resourceList}
          options={dtOptions}
          sort={sort}
          loading={loading || eligibleResourcesLoading}
          onRefresh={handleSyncList}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          paginationState={paginationState}
          onPaginationClick={handlePagination}
          paginationTotalItems={totalItems}
          paginationMaxButtons={5}
          showDetails={showDetails}
          createLabel={translate('rosterApp.resource.home.createLabel')}
          refreshListLabel={translate('rosterApp.resource.home.refreshListLabel')}
          notFoundLabel={translate('rosterApp.resource.home.notFound')}
          hideCreateAction={!hasResourceAddPermission}
          leftHeader={<DataTableLeftHeader />}
          onSendData={planResourcesInShift}
          title={showDetails && dataTableTitle}
        />
      )}
    </>
  );

  const DataTableLeftHeader: React.FC<{ onSendData?: () => void }> = ({ onSendData }) => {
    if (selectedShiftOrPosition?.shiftId && selectedShiftOrPosition?.organigramId) {
      return (
        <Tooltip position="bottom" text={translate('rosterApp.resource.planInCurrentShift')}>
          <Button className="mr-2" variant="default" onClick={onSendData}>
            <FontAwesomeIcon icon={faAnglesLeft} className="text-white text-lg mr-2" />
          </Button>
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <>
      {!showDetails && (
        <h2 id="resource-heading" className={`mb-2 whitespace-nowrap ${showDetails ? 'text-lg' : ''}`} data-cy="ResourceHeading">
          {translate('global.menu.entities.resources')}
        </h2>
      )}

      {getDataTable()}

      <DeleteConfirmationPopup
        open={confirmationText?.length > 0}
        onSave={deleteItems}
        onCancel={() => setConfirmationText('')}
        children={confirmationText}
        saveClassName="btn-danger"
      />
      <ResourceAddEdit isOpen={isModalOpen} onClose={handleClose} onSave={handleSave} resource={selectedResource} />
    </>
  );
};

export default CustomResource;
