import { IPositionRequirement } from 'app/shared/model/position-requirement.model';
import { IOrganigram } from 'app/shared/model/organigram.model';

export interface IRefPosition {
  id?: number;
  name?: string;
  description?: string | null;
  workHoursMax?: number | null;
  restHoursMin?: number | null;
  type?: string | null;
  positionRequirements?: IPositionRequirement[] | null;
  organigrams?: IOrganigram[] | null;
}

export const defaultValue: Readonly<IRefPosition> = {};
