import ActivityModel from '@model/custom/custom-activity.model';
import ShiftModel from '@model/custom/custom-shift.model';
import { getDateTime } from '@util/date-utils';
import Tooltip from 'app/shared/components/tooltip/Tooltip';
import React from 'react';

type ActivityProps = {
  shift: ShiftModel;
  activity: ActivityModel;
};

export const Activity = ({ shift, activity }: ActivityProps) => {
  const getActivityPosition = () => {
    let activityStartDate = getDateTime(activity.startTime);
    let activityEndDate = getDateTime(activity.endTime);

    const shiftStartDate = getDateTime(shift.startTime);
    const shiftEndDate = getDateTime(shift.endTime);

    let isStart = true;
    let isEnd = true;

    if (activityEndDate > shiftEndDate) {
      activityEndDate = shiftEndDate;
      isEnd = false;
    }
    if (activityStartDate < shiftStartDate) {
      activityStartDate = shiftStartDate;
      isStart = false;
    }

    const activityDurationInHours = activityEndDate - activityStartDate;
    const shiftDurationInHours = shiftEndDate - shiftStartDate;

    let startPosition = activityStartDate < shiftStartDate ? 0 : ((activityStartDate - shiftStartDate) / shiftDurationInHours) * 100;

    let durationPercentage = (activityDurationInHours / shiftDurationInHours) * 100;

    return {
      left: `${startPosition}%`,
      top: `${(activity.position ? activity.position * 22 : 22) - 19}px`,
      width: `${durationPercentage}%`,
      borderTopLeftRadius: `${isStart ? 2 : 0}rem`,
      borderBottomLeftRadius: `${isStart ? 2 : 0}rem`,
      borderTopRightRadius: `${isEnd ? 2 : 0}rem`,
      borderBottomRightRadius: `${isEnd ? 2 : 0}rem`,
      backgroundColor: activity?.color,
    };
  };

  return (
    <Tooltip
      className={`absolute h-4 opacity-80 text-white text-center leading-8 cursor-pointer w-full`}
      parentClassName={`absolute h-4`}
      style={{ ...getActivityPosition() }}
      text={activity?.refActivityType?.name}
    >
      <div key={activity.id} title={activity.name}></div>
    </Tooltip>
  );
};
