import React from 'react';

type SwitchProps = {
  isChecked: boolean;
  toggleSwitch: () => any;
};

const Switch = ({ isChecked, toggleSwitch }: SwitchProps) => {
  return (
    <div className="flex items-center">
      <label className="flex items-center cursor-pointer">
        <div className="relative cursor-pointer">
          <input type="checkbox" className="sr-only" checked={isChecked} onChange={toggleSwitch} />
          <div
            className={`toggle__line w-10 h-6 ${
              isChecked ? 'bg-green-500' : 'bg-gray-400'
            } rounded-full shadow-inner transition-colors duration-300 ease-in-out`}
          >
            <div
              className={`toggle__dot absolute w-6 h-6 bg-white rounded-full shadow-md inset-y-0 transition-transform duration-300 ease-in-out ${
                isChecked ? 'transform translate-x-full' : ''
              }`}
            ></div>
          </div>
        </div>
      </label>
    </div>
  );
};

export default Switch;
