import { IActivity } from 'app/shared/model/activity.model';
import { IDemandTemplate } from 'app/shared/model/demand-template.model';

export interface IRefActivityType {
  id?: number;
  name?: string | null;
  description?: string | null;
  color?: string | null;
  category?: string | null;
  activities?: IActivity[] | null;
  demandTemplates?: IDemandTemplate[] | null;
}

export const defaultValue: Readonly<IRefActivityType> = {};
