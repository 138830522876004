import dayjs from 'dayjs';
import { IResource } from 'app/shared/model/resource.model';
import { IRefTraining } from 'app/shared/model/ref-training.model';

export interface IResourceTraining {
  id?: number;
  status?: string | null;
  level?: string | null;
  trainer?: string | null;
  activeFrom?: dayjs.Dayjs | null;
  activeTo?: dayjs.Dayjs | null;
  resource?: IResource | null;
  refTraining?: IRefTraining | null;
}

export const defaultValue: Readonly<IResourceTraining> = {};
