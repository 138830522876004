import dayjs from 'dayjs';
import { IEquipment } from 'app/shared/model/equipment.model';
import { IResource } from 'app/shared/model/resource.model';
import { IShift } from 'app/shared/model/shift.model';
import { IOrganigram } from 'app/shared/model/organigram.model';

export interface IEquipmentPlan {
  id?: number;
  notes?: string | null;
  startTime?: dayjs.Dayjs | null;
  endTime?: dayjs.Dayjs | null;
  equipment?: IEquipment | null;
  resource?: IResource | null;
  shift?: IShift | null;
  organigram?: IOrganigram | null;
}

export const defaultValue: Readonly<IEquipmentPlan> = {};
