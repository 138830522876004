import { useAppDispatch } from 'app/config/store';
import { Button } from 'app/shared/components/button';
import Modal from 'app/shared/components/modal/Modal';
import ValidationInput from 'app/shared/components/validation/validation-input';
import ShiftModel from 'app/shared/model/custom/custom-shift.model';
import { useFormik } from 'formik';
import React, { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { translate } from 'react-jhipster';
import * as Yup from 'yup';
import ConfirmCleanPlansModal from './ConfirmClearPlansModal';

interface ClearPlansModalProps {
  shift: ShiftModel;
  setIsClearPlansPopupOpen: Dispatch<SetStateAction<boolean>>;
  isClearPlansPopupOpen: boolean;
  refreshShift: () => void;
}
export const validationSchema = Yup.object().shape({
  positionDemand: Yup.boolean(),
  resourcePlans: Yup.boolean(),
  equipmentPlans: Yup.boolean(),
});

const ClearPlansModal = ({ shift, setIsClearPlansPopupOpen, refreshShift, isClearPlansPopupOpen }: ClearPlansModalProps) => {
  const [isConfirmClearPlansModalOpen, setIsConfirmClearPlansModalOpen] = useState<boolean>(false);
  const formik = useFormik<Yup.InferType<typeof validationSchema>>({
    initialValues: {
      positionDemand: false,
      resourcePlans: false,
      equipmentPlans: false,
    },
    validationSchema,
    onSubmit: values => submitForm(),
    enableReinitialize: true,
  });

  const submitForm = () => {
    setIsConfirmClearPlansModalOpen(true);
  };

  const onCancel = () => {
    setIsClearPlansPopupOpen(false);
  };

  return (
    <Fragment>
      <Modal
        size="sm"
        title={`${translate('rosterApp.planning.context-menu.clear-plans')}`}
        isOpen={isClearPlansPopupOpen}
        onClose={onCancel}
      >
        <form onSubmit={formik.handleSubmit}>
          <div
            className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full"
            style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}
          >
            <div
              className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full"
              style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}
            >
              <div className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <ValidationInput
                    formik={formik}
                    label={translate('rosterApp.planning.clean-plans-pop.position-demands')}
                    type="checkbox"
                    field="positionDemand"
                  />
                </div>
                <div className="sm:col-span-6">
                  <ValidationInput
                    formik={formik}
                    label={translate('rosterApp.planning.clean-plans-pop.resource-plans')}
                    type="checkbox"
                    field="resourcePlans"
                  />
                </div>
                <div className="sm:col-span-6">
                  <ValidationInput
                    formik={formik}
                    label={translate('rosterApp.planning.clean-plans-pop.equipment-plans')}
                    type="checkbox"
                    field="equipmentPlans"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-2 items-center justify-end p-3 md:p-4 border-t border-gray-200 rounded-b bg-navyBlue">
            <Button variant="outline" type="button" onClick={onCancel}>
              {translate('entity.action.cancel')}
            </Button>
            <Button variant="destructive" type="submit">
              {translate('rosterApp.planning.confirmation-popup.clear')}
            </Button>
          </div>
        </form>
      </Modal>
      <ConfirmCleanPlansModal
        isOpen={isConfirmClearPlansModalOpen}
        setIsOpen={setIsConfirmClearPlansModalOpen}
        shift={shift}
        refreshShift={refreshShift}
        values={formik.values}
        setIsClearPlansPopupOpen={setIsClearPlansPopupOpen}
      />
    </Fragment>
  );
};

export default ClearPlansModal;
