import React, { PropsWithChildren } from 'react';
import Modal from '../modal/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { translate } from 'react-jhipster';
import { Button } from 'app/shared/components/button';

type WarningConfirmationPopupProps = {
  open?: boolean;
  headerLabel?: string;
  saveLabel?: string;
  cancelLabel?: string;
  className?: string;
  saveClassName?: string;
  onSave?: () => void;
  saveLoading?: boolean;
  onCancel?: () => void;
};

const WarningConfirmationPopup = ({
  open = false,
  headerLabel = 'Confirmation',
  saveLabel,
  cancelLabel,
  saveClassName,
  onSave,
  saveLoading = false,
  onCancel,
  children,
}: PropsWithChildren<WarningConfirmationPopupProps>) => {
  return (
    <Modal title={headerLabel} size="lg" isOpen={open} onClose={onCancel}>
      <div className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
        {children}
      </div>
      <div className="flex items-center justify-end gap-2 p-3 md:p-4 border-t border-gray-200 rounded-b bg-navyBlue">
        <Button variant="outline" onClick={onCancel} type="button">
          {cancelLabel ? cancelLabel : translate('entity.action.no')}
        </Button>
        <Button variant="warning" onClick={onSave} disabled={saveLoading} className={saveClassName}>
          {saveLoading && <FontAwesomeIcon icon={faSpinner} spin={saveLoading} />}
          {saveLabel ? saveLabel : translate('entity.action.yes')}
        </Button>
      </div>
    </Modal>
  );
};

export default WarningConfirmationPopup;
