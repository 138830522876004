import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getFacilities } from 'app/entities/facility/facility.reducer';
import { getEntities as getRefPositions } from 'app/entities/ref-position/ref-position.reducer';
import Modal from 'app/shared/components/modal/Modal';
import ValidationInput from 'app/shared/components/validation/validation-input';
import ValidationMultiSelect from 'app/shared/components/validation/validation-multi-select';
import ValidationSelect from 'app/shared/components/validation/validation-select';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import CustomDepartmentPosition from 'app/shared/model/custom/custom-department-position.model';
import { translate } from 'react-jhipster';
import * as Yup from 'yup';
import { createCustomEntity, updateCustomEntity } from './ref-department.reducer';
import { Button } from 'app/shared/components/button';

type RefDepartmentAddEditProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  department: CustomDepartmentPosition;
};

const RefDepartmentAddEdit = ({ isOpen, onClose, onSave, department }: RefDepartmentAddEditProps) => {
  const dispatch = useAppDispatch();
  const facilities = useAppSelector(state => state.facility.entities);
  const refPositions = useAppSelector(state => state.refPosition.entities);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(translate('entity.validation.required')),
    facility: Yup.object().required(translate('entity.validation.required')),
    positions: Yup.array().optional(),
  });

  const formik = useFormik({
    initialValues: {
      name: department?.name || '',
      facility: department?.facility || null,
      positions: department?.positions || [],
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      submitForm(values);
    },
  });

  useEffect(() => {
    if (isOpen) {
      getAllFacilities();
      getAllPositions();
    }
  }, [isOpen]);

  useEffect(() => {
    formik.resetForm();
    formik.setValues({
      name: department?.name || '',
      facility: department?.facility || null,
      positions: department?.positions || [],
    });
  }, [isOpen, department]);

  const getAllFacilities = () => {
    dispatch(getFacilities({}));
  };

  const getAllPositions = () => {
    dispatch(getRefPositions({ sort: 'name,asc' }));
  };

  const submitForm = async values => {
    if (!loading) {
      setLoading(true);
      let res = {
        id: department?.id,
        name: values.name,
        facility: values.facility,
        positions: values.positions,
      };

      try {
        if (department?.id) {
          await dispatch(updateCustomEntity(res));
        } else {
          await dispatch(createCustomEntity(res));
        }
        onSave();
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      size="lg"
      title={`${translate(department?.id ? 'rosterApp.refDepartment.home.createOrEditLabel' : 'rosterApp.refDepartment.home.createLabel')}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
          <div className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6 mb-4">
            <div className="sm:col-span-6">
              <ValidationInput formik={formik} label={translate('rosterApp.refDepartment.name')} field="name" />
            </div>
            <div className="sm:col-span-6">
              <ValidationSelect
                formik={formik}
                options={facilities}
                optionsKey="id"
                optionsValue="name"
                label={translate('rosterApp.refDepartment.facility')}
                field="facility"
                object
              />
            </div>
            <div className="sm:col-span-6">
              <ValidationMultiSelect
                formik={formik}
                options={refPositions}
                optionsKey="id"
                optionsValue="name"
                label={translate('global.menu.entities.positions')}
                field="positions"
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end p-3 md:p-4 gap-2 border-t border-gray-200 rounded-b bg-navyBlue">
          <Button variant="outline" onClick={onClose} type="button">
            {translate('entity.action.cancel')}
          </Button>
          <Button type="submit" disabled={loading}>
            {loading && <FontAwesomeIcon icon={faSpinner} spin={loading} />}
            {translate('entity.action.save')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default RefDepartmentAddEdit;
