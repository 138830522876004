import { useAppDispatch } from 'app/config/store';
import { Button } from 'app/shared/components/button';
import Modal from 'app/shared/components/modal/Modal';
import ValidationInput from 'app/shared/components/validation/validation-input';
import ShiftModel from 'app/shared/model/custom/custom-shift.model';
import { useFormik } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import { translate } from 'react-jhipster';
import * as Yup from 'yup';
import { autoPlanShift } from '../planning.reducer';

const validationSchema = Yup.object().shape({
  includeResourcesFromOtherTeams: Yup.boolean().default(false),
  overwriteExistingPlan: Yup.boolean().required('Overwrite existing plan is required').default(false),
  overwriteTeamPlan: Yup.boolean().required('Overwrite team plan is required').default(false),
  allowExtraShiftsHours: Yup.boolean().required('Allow extra shifts hours is required').default(false),
  ignoreResourcesSkillLevel: Yup.boolean().required('Ignore resources skill level is required').default(false),
});

interface AutoPlanModalProps {
  shift: ShiftModel;
  refreshShift: () => void;
  setAutoPlan: Dispatch<SetStateAction<{ label: string; value: boolean }[]>>;
  autoPlan: { field; label: string; value: boolean }[];
  setIsAutoPlanPopupOpen: Dispatch<SetStateAction<boolean>>;
  isAutoPlanPopupOpen: boolean;
}

const AutoPlanModal = ({ shift, refreshShift, setAutoPlan, setIsAutoPlanPopupOpen, autoPlan, isAutoPlanPopupOpen }: AutoPlanModalProps) => {
  const dispatch = useAppDispatch();

  const formik = useFormik<Yup.InferType<typeof validationSchema>>({
    initialValues: {
      includeResourcesFromOtherTeams: false,
      overwriteExistingPlan: false,
      overwriteTeamPlan: false,
      allowExtraShiftsHours: false,
      ignoreResourcesSkillLevel: false,
    },
    validationSchema,
    onSubmit: values => submitForm(values),
    enableReinitialize: true,
  });

  const submitForm = async (values: Yup.InferType<typeof validationSchema>) => {
    try {
      await dispatch(autoPlanShift({ shiftId: shift.id, otherTeams: values.includeResourcesFromOtherTeams }));
      refreshShift();
      setAutoPlan(
        autoPlan.map(plan => {
          if (plan.label === translate('rosterApp.planning.auto-plan.include-resource-from-other-teams')) {
            plan.value = values.includeResourcesFromOtherTeams;
          }
          if (plan.label === translate('rosterApp.planning.auto-plan.overwrite-existing-plan')) {
            plan.value = values.overwriteExistingPlan;
          }
          if (plan.label === translate('rosterApp.planning.auto-plan.overwrite-team-plan')) {
            plan.value = values.overwriteTeamPlan;
          }
          if (plan.label === translate('rosterApp.planning.auto-plan.allow-extra-shifts-hours')) {
            plan.value = values.allowExtraShiftsHours;
          }
          if (plan.label === translate('rosterApp.planning.auto-plan.ignore-resources-skill-level')) {
            plan.value = values.ignoreResourcesSkillLevel;
          }
          return plan;
        }),
      );
      setIsAutoPlanPopupOpen(false);
    } catch (error) {
      console.error('Error auto Planning shift:', error);
    }
  };
  return (
    <Modal
      size="sm"
      title={translate('rosterApp.planning.confirmation-popup.auto-plan')}
      isOpen={isAutoPlanPopupOpen}
      onClose={() => setIsAutoPlanPopupOpen(false)}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
          <div
            className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full"
            style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}
          >
            <div className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
              {autoPlan.map(({ field, label }, index) => {
                return (
                  <div key={index} className="sm:col-span-6">
                    <ValidationInput field={field} formik={formik} type="checkbox" label={label} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end p-3 md:p-4 border-t gap-2 border-gray-200 rounded-b bg-navyBlue">
          <Button variant="outline" type="button" onClick={() => setIsAutoPlanPopupOpen(false)}>
            {translate('entity.action.cancel')}
          </Button>
          <Button type="submit">{translate('rosterApp.planning.confirmation-popup.auto-plan')}</Button>
        </div>
      </form>
    </Modal>
  );
};

export default AutoPlanModal;
