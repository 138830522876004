import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ApplicationSettings from './application-settings';
import ApplicationSettingsDetail from './application-settings-detail';
import ApplicationSettingsUpdate from './application-settings-update';
import ApplicationSettingsDeleteDialog from './application-settings-delete-dialog';

const ApplicationSettingsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ApplicationSettings />} />
    <Route path="new" element={<ApplicationSettingsUpdate />} />
    <Route path=":id">
      <Route index element={<ApplicationSettingsDetail />} />
      <Route path="edit" element={<ApplicationSettingsUpdate />} />
      <Route path="delete" element={<ApplicationSettingsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ApplicationSettingsRoutes;
