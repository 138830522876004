import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'app/shared/components/avatar/Avatar';
import React, { useEffect, useRef, useState } from 'react';

export const NavDropdown = props => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setOpen(false);
  }, [props.name]);

  const toggleDropdown = () => {
    setOpen(!open);
  };

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (props.clientLogo) {
    return (
      <div
        ref={dropdownRef}
        className="relative flex items-center cursor-pointer bg-slate-300 rounded-xl py-1 px-2"
        data-cy="accountMenu"
        onClick={toggleDropdown}
      >
        <img className="mr-3 h-7" src="content/images/customer-logo.png" alt="Logo" />
        {props.imgUrl && !props.avatarCondition && <Avatar alt={props.avatarname} size="h-8 w-8" />}
        {props.imgUrl && props.avatarCondition && (
          <div>
            <button
              type="button"
              className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
              id="user-menu-button"
              aria-expanded="false"
              aria-haspopup="true"
            >
              <span className="absolute -inset-1.5"></span>
              <span className="sr-only">Open user menu</span>
              <img className="h-8 w-8 rounded-full" src={props.imgUrl} alt={props.name} />
            </button>
          </div>
        )}
        {open && (
          <div
            className="absolute z-20 top-full mt-2 right-0 w-full bg-white rounded-md shadow-lg custom-scrollbar"
            style={{ maxHeight: '60vh' }}
          >
            {props.children}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="relative" ref={dropdownRef}>
      {props.imgUrl && props.avatarCondition && (
        <div>
          <button
            type="button"
            onClick={toggleDropdown}
            className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
            id="user-menu-button"
            aria-expanded="false"
            aria-haspopup="true"
          >
            <span className="absolute -inset-1.5"></span>
            <span className="sr-only">Open user menu</span>
            <img className="h-8 w-8 rounded-full" src={props.imgUrl} alt={props.name} />
          </button>
        </div>
      )}
      {!props.imgUrl && (
        <div>
          <button className="nav-link-item" onClick={toggleDropdown}>
            {props.icon === faGear ? (
              <FontAwesomeIcon icon={props.icon} className="text-lg" />
            ) : (
              <>
                {props.icon && <FontAwesomeIcon icon={props.icon} className="mr-2" />}
                {props.svg && <div className="w-5 h-5" dangerouslySetInnerHTML={{ __html: props.svg }}></div>}
                {!props.svg && props.name}
                {!props.svg && (
                  <svg
                    className="-mr-1 ml-2 h-5 w-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                  </svg>
                )}
              </>
            )}
          </button>
        </div>
      )}
      {open && (
        <div className="absolute z-20 right-0 mt-2 w-auto bg-white rounded-md shadow-lg custom-scrollbar" style={{ maxHeight: '60vh' }}>
          {props.children}
        </div>
      )}
    </div>
  );
};
