import { overridePaginationStateWithQueryParams } from '@util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '@util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import DataTable, { DataTableOptions } from 'app/shared/components/data-table/DataTable';
import { getPaginationState, translate } from 'react-jhipster';
import { getEntities } from './demand-template.reducer';
import DemandTemplateAddEdit from './demand-template-add-edit';
import DemandTemplateDelete from './demand-template-delete-modal';
import { IDemandTemplate } from 'app/shared/model/demand-template.model';

const DemandTemplate = ({ showDetails = false }) => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'headcount', 'asc'), pageLocation.search),
  );

  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedDemandTemplate, setSelectedDemandTemplate] = useState<IDemandTemplate>();
  const [deletingDemandTemplates, setDeletingDemandTemplates] = useState<IDemandTemplate[]>();
  const demandTemplateList = useAppSelector(state => state.demandTemplate.entities);
  const loading = useAppSelector(state => state.equipment.loading);
  const totalItems = useAppSelector(state => state.equipment.totalItems);

  const demandTemplateDtOptions: DataTableOptions = {
    headers: [
      translate('rosterApp.demandTemplate.refActivityType'),
      translate('rosterApp.organigram.refDepartment'),
      translate('rosterApp.organigram.refPosition'),
      translate('rosterApp.demandTemplate.headcount'),
    ],
    columns: [
      {
        key: 'refActivityType',
        filterable: true,
        sortable: true,
        render: (row: IDemandTemplate) => (
          <Link className="text-primary hover:text-blue-700 no-underline font-medium" to={`/ref-activity-type`}>
            {row?.refActivityType?.name}
          </Link>
        ),
      },
      {
        key: 'department',
        filterable: true,
        sortable: true,
        render: (row: IDemandTemplate) => (
          <Link className="text-primary hover:text-blue-700 no-underline font-medium" to={`/ref-department`}>
            {row?.organigram?.refDepartment.name}
          </Link>
        ),
      },
      {
        key: 'position',
        filterable: true,
        sortable: true,

        render: (row: IDemandTemplate) => (
          <Link className="text-primary hover:text-blue-700 no-underline font-medium" to={`/ref-position`}>
            {row?.organigram?.refPosition.name}
          </Link>
        ),
      },
      {
        key: 'headcount',
        filterable: true,
        sortable: true,
        render: (row: IDemandTemplate) => <h6 className="text-slate-800 mb-0">{row?.headcount}</h6>,
      },
    ],
  };

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleAdd = () => {
    setSelectedDemandTemplate(null);
    setIsAddEditModalOpen(true);
  };

  const handleUpdate = (rows: IDemandTemplate[]) => {
    setSelectedDemandTemplate(rows[0]);
    setIsAddEditModalOpen(true);
  };

  const handleDelete = (rows: IDemandTemplate[]) => {
    setIsDeleteModalOpen(true);
    setDeletingDemandTemplates(rows);
  };

  const handleSaveAddEdit = () => {
    setIsAddEditModalOpen(false);
  };

  const handleCloseAddEdit = () => {
    setIsAddEditModalOpen(false);
  };

  const handleConfirmDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const handleCloseDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const getDataTable = () => (
    <Fragment>
      {demandTemplateList && (
        <DataTable
          data={demandTemplateList}
          options={demandTemplateDtOptions}
          sort={sort}
          loading={loading}
          onRefresh={handleSyncList}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          paginationState={paginationState}
          onPaginationClick={handlePagination}
          paginationTotalItems={totalItems}
          paginationMaxButtons={5}
          showDetails={showDetails}
          createLabel={translate('rosterApp.demandTemplate.home.createLabel')}
          refreshListLabel={translate('rosterApp.demandTemplate.home.refreshListLabel')}
          notFoundLabel={translate('rosterApp.demandTemplate.home.notFound')}
          title={showDetails && translate('global.menu.entities.demandTemplate')}
        />
      )}
    </Fragment>
  );

  return (
    <Fragment>
      {!showDetails && (
        <h2 id="refLeaveType-heading" className={`mb-2 whitespace-nowrap`} data-cy="DemantTemplateHeading">
          {translate('global.menu.entities.demandTemplate')}
        </h2>
      )}
      {getDataTable()}
      <DemandTemplateAddEdit
        isOpen={isAddEditModalOpen}
        onClose={handleCloseAddEdit}
        onSave={handleSaveAddEdit}
        demandTemplate={selectedDemandTemplate}
      />
      <DemandTemplateDelete
        isOpen={isDeleteModalOpen}
        onClose={handleCloseDelete}
        onConfirm={handleConfirmDelete}
        deletingDemandTemplate={deletingDemandTemplates}
      />
    </Fragment>
  );
};

export default DemandTemplate;
