import { combineReducers } from '@reduxjs/toolkit';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import entitiesReducers from 'app/entities/reducers';
import activate from 'app/modules/account/activate/activate.reducer';
import passwordReset from 'app/modules/account/password-reset/password-reset.reducer';
import password from 'app/modules/account/password/password.reducer';
import register from 'app/modules/account/register/register.reducer';
import settings from 'app/modules/account/settings/settings.reducer';
import administration from 'app/modules/administration/administration.reducer';
import userManagement from 'app/modules/administration/user-management/user-management.reducer';
import planning from 'app/modules/planning/planning.reducer';
import calendar from 'app/modules/calendar/calendar.reducer';
import planningShiftReducer from 'app/modules/planning/Shift/planningShift.reducer';
import applicationProfile from './application-profile';
import authentication from './authentication';
import locale from './locale';

/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const rootReducer = combineReducers({
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  loadingBar,
  planning,
  calendar,
  planningShiftReducer,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  ...entitiesReducers,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
