import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { NavLink } from 'react-router-dom';
import { Translate } from 'react-jhipster';

export interface IMenuItem {
  icon: IconProp;
  to: string;
  translate?: string;
  children?: any;
}

const MenuItem = (props: IMenuItem) => {
  const { to, icon, translate, children } = props;

  return (
    <NavLink to={to} className="dropdown-item">
      {icon && <FontAwesomeIcon icon={icon} fixedWidth className="mx-2" />}
      <span className="mr-2">
        {children}
        {translate && <Translate contentKey={translate} />}
      </span>
    </NavLink>
  );
};

export default MenuItem;
