import React from 'react';
import Switch from '../switch/switch';

const ValidationSwitch = ({ formik, label, field }) => {
  const isNotValid = () => {
    return formik.touched[field] && formik.errors[field];
  };

  const toggleSwitch = () => {
    formik.setFieldValue(field, !formik.values[field]);
  };

  return (
    <div className="mb-4">
      <div className="grid grid-cols-3">
        <label htmlFor={field} className={`${isNotValid() ? 'text-red-600' : 'text-gray-900'} block text-sm font-medium mr-2`}>
          {label}
        </label>
        <Switch isChecked={formik.values[field]} toggleSwitch={toggleSwitch} />
      </div>
      {isNotValid() && <p className="m-0 text-xs text-red-500">{formik.errors[field]}</p>}
    </div>
  );
};

export default ValidationSwitch;
