import { IRefShiftType } from 'app/shared/model/ref-shift-type.model';
import { ITeam } from 'app/shared/model/team.model';

export interface IRefRotation {
  id?: number;
  sequence?: number | null;
  name?: string | null;
  refShiftType?: IRefShiftType | null;
  team?: ITeam | null;
}

export const defaultValue: Readonly<IRefRotation> = {};
