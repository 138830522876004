import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import Modal from 'app/shared/components/modal/Modal';
import ValidationInput from 'app/shared/components/validation/validation-input';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import { translate } from 'react-jhipster';
import * as Yup from 'yup';
import { Button } from 'app/shared/components/button';
import { createEntity, updateEntity } from './ref-rotation.reducer';
import { getEntities as getTeams } from '../team/team.reducer';
import { getEntities as getShiftTypes } from '../ref-shift-type/ref-shift-type.reducer';
import { IRefRotation } from 'app/shared/model/ref-rotation.model';
import ValidationSelect from 'app/shared/components/validation/validation-select';

type RotationAddEditProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  entity: IRefRotation;
};

const RotationAddEdit = ({ isOpen, onClose, onSave, entity }: RotationAddEditProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const teams = useAppSelector(state => state.team.entities);
  const shiftTypes = useAppSelector(state => state.refShiftType.entities);

  const validationSchema = Yup.object().shape({
    sequence: Yup.number().required(translate('entity.validation.required')),
    team: Yup.object().required(translate('entity.validation.required')),
    refShiftType: Yup.object().required(translate('entity.validation.required')),
  });

  const formik = useFormik({
    initialValues: {
      sequence: entity?.sequence || '',
      team: entity?.team || null,
      refShiftType: entity?.refShiftType || null,
    },
    validationSchema,
    onSubmit: values => submitForm(values),
  });

  useEffect(() => {
    formik.resetForm();
    formik.setValues({
      sequence: entity?.sequence || '',
      team: entity?.team || null,
      refShiftType: entity?.refShiftType || null,
    });
  }, [entity, isOpen]);

  useEffect(() => {
    if (isOpen) {
      getAllTeams();
      getAllShiftTypes();
    }
  }, [isOpen]);

  const getAllTeams = () => {
    dispatch(getTeams({ sort: 'name,asc' }));
  };

  const getAllShiftTypes = () => {
    dispatch(getShiftTypes({ sort: 'name,asc' }));
  };

  const submitForm = async values => {
    if (!loading) {
      setLoading(true);
      const res = {
        id: entity?.id,
        sequence: values.sequence,
        team: values.team,
        refShiftType: values.refShiftType,
      };

      try {
        if (entity?.id) {
          await dispatch(updateEntity(res));
        } else {
          await dispatch(createEntity(res));
        }
        onSave();
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      size="lg"
      title={`${translate(entity?.id ? 'rosterApp.refRotation.home.createOrEditLabel' : 'rosterApp.refRotation.home.createLabel')}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
          <div className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6 mb-4">
            <div className="sm:col-span-6">
              <ValidationInput formik={formik} label={translate('rosterApp.refRotation.sequence')} field="sequence" type="number" />
            </div>
            <div className="sm:col-span-6">
              <ValidationSelect
                formik={formik}
                options={teams}
                optionsKey="id"
                optionsValue="name"
                label={translate('rosterApp.refRotation.team')}
                field="team"
                object
              />
            </div>
            <div className="sm:col-span-6">
              <ValidationSelect
                formik={formik}
                options={shiftTypes}
                optionsKey="id"
                optionsValue="name"
                label={translate('rosterApp.refRotation.refShiftType')}
                field="refShiftType"
                object
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end p-3 md:p-4 gap-2 border-t border-gray-200 rounded-b bg-navyBlue">
          <Button variant="outline" onClick={onClose} type="button">
            {translate('entity.action.cancel')}
          </Button>
          <Button type="submit" disabled={loading}>
            {loading && <FontAwesomeIcon icon={faSpinner} spin={loading} />}
            {translate('entity.action.save')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default RotationAddEdit;
