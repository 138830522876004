import { IShift } from 'app/shared/model/shift.model';
import { IOrganigram } from 'app/shared/model/organigram.model';

export interface IShiftDemand {
  id?: number;
  headCount?: number | null;
  shift?: IShift | null;
  organigram?: IOrganigram | null;
}

export const defaultValue: Readonly<IShiftDemand> = {};
