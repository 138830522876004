// CalendarHeader.tsx
import React, { forwardRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ButtonProps } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from 'app/shared/components/button';

type CalendarHeaderProps = {
  currentMonthIndex: number;
  handleReset: () => void;
  handlePrev: () => void;
  handleNext: () => void;
  onDateSelected: (date: Date) => void;
  selectedDate: Date;
};

const CustomTodayButton = forwardRef<HTMLButtonElement, ButtonProps>(({ value, onClick }, ref) => (
  <Button size="sm" className="mx-2" onClick={onClick} ref={ref}>
    {value}
  </Button>
));

const CalendarHeader = ({ currentMonthIndex, handleReset, handlePrev, handleNext, onDateSelected, selectedDate }: CalendarHeaderProps) => {
  const [startDate, setStartDate] = useState(selectedDate);

  useEffect(() => {
    setStartDate(selectedDate);
  }, [selectedDate]);

  const handleTodayClick = () => {
    const today = new Date();
    setStartDate(today);
    onDateSelected(today); // Call onDateSelected with the selected date
  };

  return (
    <header className="flex items-center w-full mb-1">
      <Button size="sm" className="px-2" onClick={handlePrev}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </Button>
      <DatePicker
        selected={startDate}
        onChange={date => {
          setStartDate(date);
          onDateSelected(date);
        }}
        customInput={<CustomTodayButton />}
        calendarStartDay={1}
        popperPlacement="bottom-start"
        todayButton="Today"
        dateFormat="MMM yyyy"
      />
      <Button size="sm" className="px-2" onClick={handleNext}>
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
    </header>
  );
};

export default CalendarHeader;
