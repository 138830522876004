import dayjs from 'dayjs';
import { IRefDepartment } from 'app/shared/model/ref-department.model';
import { IRefActivityType } from 'app/shared/model/ref-activity-type.model';
import { IActivityEquipment } from 'app/shared/model/activity-equipment.model';

export interface IActivity {
  id?: number;
  notes?: string | null;
  startTime?: dayjs.Dayjs | null;
  endTime?: dayjs.Dayjs | null;
  refDepartment?: IRefDepartment | null;
  refActivityType?: IRefActivityType | null;
  activityEquipments?: IActivityEquipment[] | null;
}

export const defaultValue: Readonly<IActivity> = {};
