import React, { Fragment, useEffect, useRef, useState } from 'react';
import Avatar from '../avatar/Avatar';
import { getImageUrl } from 'app/shared/util/file-utils';
import Resource from 'app/shared/model/custom/custom-resource.model';
import EquipmentCustom from 'app/shared/model/custom/custom-equipment.model';

function EquipmentResourceSelectInput({
  resources,
  resourcePlans,
  equipments,
  equipmentPlans,
  avatars,
  mode,
  deleteResourcePlan,
  addClicked,
  addResource,
  addEquipment,
  deleteEquipmentPlan,
  isAdd,
  setAddClicked,
  isHovered,
  setIsHovered,
}) {
  const [search, setSearch] = useState<string>('');
  const [filteredResources, setFilteredResources] = useState<Resource[]>([]);
  const [filteredEquipments, setFilteredEquipments] = useState<EquipmentCustom[]>([]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (resources.length) {
      const lowerCaseSearch = search.toLowerCase();
      setFilteredResources(
        resources.filter(
          ({ firstName, lastName }: Resource) =>
            firstName.toLowerCase().includes(lowerCaseSearch) || lastName.toLowerCase().includes(lowerCaseSearch),
        ),
      );
    } else if (filteredResources.length) {
      setFilteredResources([]);
    }
  }, [resources, search]);

  useEffect(() => {
    if (equipments.length) {
      setFilteredEquipments(equipments.filter(res => res.name.toLowerCase().includes(search.toLowerCase())));
    } else if (filteredEquipments.length) {
      setFilteredEquipments([]);
    }
  }, [equipments, search]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const show = () => (isHovered && !addClicked && !isAdd) || (isAdd && addClicked);

  const handleClose = () => {
    if (isAdd && setAddClicked) {
      setAddClicked(false);
    } else {
      setIsHovered(false);
    }
  };

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      handleClose();
    }
  };

  const handleHoverOutside = event => {
    if (dropdownRef.current && resourcePlans.length) {
      handleClose();
    }
  };

  const eligibleResourcesSort = (a: Resource, b: Resource) => {
    const modeOrder = ['preferred', 'shift', 'remaining'];

    const aModeIndex = modeOrder.indexOf(a.mode);
    const bModeIndex = modeOrder.indexOf(b.mode);

    return aModeIndex - bModeIndex;
  };

  const eligibleEquipmentsSort = (a: EquipmentCustom, b: EquipmentCustom) => {
    // First, sort by mode
    if (a.mode < b.mode) return -1;
    if (a.mode > b.mode) return 1;
    return 0;
  };

  const getBgColorByResourceMode = (mode: string): string => {
    switch (mode) {
      case 'remaining':
        return 'bg-gray-200 hover:bg-green-200';
      case 'preferred':
        return 'bg-blue-100 hover:bg-blue-200';
      default:
        return 'hover:bg-blue-200';
    }
  };

  return (
    <div
      className={`avatar-more max-w-[250px] shadow-md rounded-xl -ml-1 whitespace-nowrap bg-gray-50 text-white text-s px-2 py-1 absolute z-50 top-full left-1/2 transform -translate-x-1/2 my-2 transition-opacity duration-200 ${
        show() ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
    >
      {((resources && resourcePlans.length === 0 && resources.length !== 0) ||
        (equipments && equipmentPlans.length === 0 && equipments.length !== 0)) && (
        <input
          onChange={e => setSearch(e.target.value)}
          value={search}
          type="text"
          name="search"
          placeholder="Search.."
          className={`mt-1 shadow-sm bg-gray-50 border-1 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2`}
        />
      )}
      <div className="custom-scrollbar overflow-y-auto overflow-x-hidden max-h-52 whitespace-nowrap">
        <div className="absolute h-2 w-full left-0 bottom-full">
          <svg className="text-gray-200 h-2 w-full" x="0px" y="0px" viewBox="0 0 255 255">
            <polygon className="fill-current" points="127.5,127.5 255,255 0,255" />
          </svg>
        </div>
        {avatars.length
          ? avatars?.map((avatar, index) => <Avatar key={`${index}_${avatar.alt.replace(' ', '_')}`} src={avatar.src} alt={avatar.alt} />)
          : resourcePlans.length
            ? resourcePlans?.map((plan, index) => {
                const shortName = `${plan?.resource?.firstName?.substring(0, 1)}${plan?.resource?.lastName?.substring(0, 1)}`;
                const fullName = `${plan?.resource?.firstName?.substring(0, 1)?.toUpperCase()}${plan?.resource?.firstName?.substring(
                  1,
                )} ${plan?.resource?.lastName?.substring(0, 1)}${plan?.resource?.lastName?.substring(1)}`;

                return (
                  <div key={`${index}_${plan.resource.id}_${plan.resource.name}`} className="flex items-center m-1 text-slate-800">
                    <Avatar
                      planId={plan?.id}
                      resourceId={plan?.resource?.id}
                      resourceTeamId={plan?.resource?.team?.id}
                      src={getImageUrl(plan?.resource?.avatarContentType, plan?.resource?.avatar)}
                      alt={shortName}
                      bgColor={plan?.resource?.bgColor}
                      mode={mode}
                      deleteResourcePlan={deleteResourcePlan}
                      size="h-7 w-7"
                    />
                    <span className="ml-2 text-sm">{fullName}</span>
                  </div>
                );
              })
            : filteredResources?.sort(eligibleResourcesSort).map((resource, index) => {
                const shortName = `${resource?.firstName?.substring(0, 1)}${resource?.lastName?.substring(0, 1)}`;
                const fullName = `${resource?.firstName?.substring(0, 1)?.toUpperCase()}${resource?.firstName?.substring(
                  1,
                )} ${resource?.lastName?.substring(0, 1)}${resource?.lastName?.substring(1)}`;

                const handleClick = async () => {
                  await addResource(resource);
                };

                return (
                  <Fragment key={resource.id}>
                    <div
                      onClick={() => void handleClick()}
                      className={`${getBgColorByResourceMode(
                        resource.mode,
                      )} text-slate-800  py-1 px-1 hover:px-2 rounded-xl cursor-pointer duration-200 transition-all transform`}
                    >
                      <div className="flex items-center w-full select-none pointer-events-none">
                        <Avatar
                          resourceTeamId={resource?.team?.id}
                          src={getImageUrl(resource?.avatarContentType, resource?.avatar)}
                          alt={shortName}
                          bgColor={resource?.bgColor}
                          size="h-7 w-7"
                        />
                        <span className="ml-2 text-sm">{fullName}</span>
                      </div>
                    </div>
                    {index < filteredResources.length - 1 &&
                      ((resource.mode === 'shift' && filteredResources[index + 1].mode === 'remaining') ||
                        (resource.mode === 'preferred' && ['shift', 'remaining'].includes(filteredResources[index + 1]?.mode))) && (
                        <div className="border-1 border-slate-400 my-1 rounded"></div>
                      )}
                  </Fragment>
                );
              })}
        {((resources && resourcePlans.length === 0 && resources.length === 0 && addResource) ||
          (resources.length !== 0 && filteredResources.length === 0)) && <span className="text-sm text-slate-800">No resources found</span>}
        {((equipments && equipmentPlans.length === 0 && equipments.length === 0 && addEquipment) ||
          (equipments.length !== 0 && filteredEquipments.length === 0)) && (
          <span className="text-sm text-slate-800">No equipments found</span>
        )}
        {equipmentPlans.length > 0 &&
          equipmentPlans?.map((plan, index) => {
            return (
              <div key={`${index}_${plan?.equipment?.id}_${plan?.equipment?.name}`} className="flex items-center m-1 text-slate-800">
                <Avatar
                  planId={plan?.id}
                  equipmentId={plan?.equipment?.id}
                  src={getImageUrl(plan?.equipment?.refEquipmentType?.iconContentType, plan?.equipment?.refEquipmentType?.icon)}
                  alt={plan?.equipment?.name}
                  bgColor="bg-white"
                  mode={mode}
                  deleteEquipmentPlan={deleteEquipmentPlan}
                  size="h-7 w-7"
                />
                <span className="ml-2 text-sm">{plan?.equipment?.name}</span>
              </div>
            );
          })}
        {filteredEquipments.length > 0 &&
          equipments &&
          equipmentPlans.length === 0 &&
          equipments.length !== 0 &&
          filteredEquipments?.sort(eligibleEquipmentsSort).map((equipment, index) => {
            const handleClick = async () => {
              await addEquipment(equipment);
            };

            return (
              <Fragment key={equipment.id}>
                <div
                  onClick={() => void handleClick()}
                  className={`${
                    equipment.mode === 'department' ? 'hover:bg-blue-200' : 'bg-gray-200 hover:bg-green-200'
                  } text-slate-800  py-1 px-1 hover:px-2 rounded-xl cursor-pointer duration-200 transition-all transform`}
                >
                  <div className="flex items-center w-full select-none pointer-events-none">
                    <Avatar
                      src={getImageUrl(equipment?.refEquipmentType?.iconContentType, equipment?.refEquipmentType?.icon)}
                      alt={equipment?.name}
                      bgColor="bg-white"
                      size="h-7 w-7"
                    />
                    <span className="ml-2 text-sm">{equipment?.name}</span>
                  </div>
                </div>
                {index < filteredEquipments.length - 1 &&
                  equipment.mode === 'department' &&
                  filteredEquipments[index + 1].mode === 'other' && <div className="border-1 border-slate-400 my-1 rounded"></div>}
              </Fragment>
            );
          })}
      </div>
    </div>
  );
}

export default EquipmentResourceSelectInput;
