import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getRefPositions } from 'app/entities/ref-position/ref-position.reducer';
import { getEntities as getRefDepartments } from 'app/entities/ref-department/ref-department.reducer';
import Modal from 'app/shared/components/modal/Modal';
import ValidationInput from 'app/shared/components/validation/validation-input';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import { translate } from 'react-jhipster';
import * as Yup from 'yup';
import { Button } from 'app/shared/components/button';
import { IOrganigram } from 'app/shared/model/organigram.model';
import { createEntity, updateEntity } from './organigram.reducer';
import ValidationSelect from 'app/shared/components/validation/validation-select';

type OrganigramAddEditProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  entity: IOrganigram;
};

const OrganigramAddEdit = ({ isOpen, onClose, onSave, entity }: OrganigramAddEditProps) => {
  const dispatch = useAppDispatch();
  const departments = useAppSelector(state => state.refDepartment.entities);
  const positions = useAppSelector(state => state.refPosition.entities);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    refDepartment: Yup.object().required(translate('entity.validation.required')),
    refPosition: Yup.object().required(translate('entity.validation.required')),
    demandCoefficient: Yup.number().required(translate('entity.validation.required')),
    demandConstant: Yup.number().required(translate('entity.validation.required')),
    demandMin: Yup.number().required(translate('entity.validation.required')),
  });

  const formik = useFormik({
    initialValues: {
      refDepartment: entity?.refDepartment || '',
      refPosition: entity?.refPosition || '',
      demandCoefficient: entity?.demandCoefficient || '',
      demandConstant: entity?.demandConstant || '',
      demandMin: entity?.demandMin || '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      submitForm(values);
    },
  });

  useEffect(() => {
    formik.resetForm();
    formik.setValues({
      refDepartment: entity?.refDepartment || '',
      refPosition: entity?.refPosition || '',
      demandCoefficient: entity?.demandCoefficient || '',
      demandConstant: entity?.demandConstant || '',
      demandMin: entity?.demandMin || '',
    });
  }, [entity, isOpen]);

  useEffect(() => {
    if (isOpen) {
      getAllDepartments();
      getAllPositions();
    }
  }, [isOpen]);

  useEffect(() => {
    formik.setValues({
      refDepartment: entity?.refDepartment || '',
      refPosition: entity?.refPosition || '',
      demandCoefficient: entity?.demandCoefficient || '',
      demandConstant: entity?.demandConstant || '',
      demandMin: entity?.demandMin || '',
    });
  }, [entity]);

  const getAllDepartments = () => {
    dispatch(getRefDepartments({ sort: 'name,asc' }));
  };

  const getAllPositions = () => {
    dispatch(getRefPositions({ sort: 'name,asc' }));
  };

  const submitForm = async values => {
    if (!loading) {
      setLoading(true);
      let res = {
        id: entity?.id,
        refDepartment: values.refDepartment,
        refPosition: values.refPosition,
        demandCoefficient: values.demandCoefficient,
        demandConstant: values.demandConstant,
        demandMin: values.demandMin,
      };

      try {
        if (entity?.id) {
          await dispatch(updateEntity(res));
        } else {
          await dispatch(createEntity(res));
        }
        onSave();
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      size="lg"
      title={`${translate(entity?.id ? 'rosterApp.organigram.home.createOrEditLabel' : 'rosterApp.organigram.home.createLabel')}`}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
          <div className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6 mb-4">
            <div className="sm:col-span-6">
              <ValidationSelect
                formik={formik}
                options={departments}
                optionsKey="id"
                optionsValue="name"
                label={translate('rosterApp.organigram.refDepartment')}
                field="refDepartment"
                object
              />
            </div>
            <div className="sm:col-span-6">
              <ValidationSelect
                formik={formik}
                options={positions}
                optionsKey="id"
                optionsValue="name"
                label={translate('rosterApp.organigram.refPosition')}
                field="refPosition"
                object
              />
            </div>
            <div className="sm:col-span-6">
              <ValidationInput
                formik={formik}
                label={translate('rosterApp.organigram.demandCoefficient')}
                field="demandCoefficient"
                placeholder="0"
              />
            </div>
            <div className="sm:col-span-6">
              <ValidationInput
                formik={formik}
                label={translate('rosterApp.organigram.demandConstant')}
                field="demandConstant"
                placeholder="0"
              />
            </div>
            <div className="sm:col-span-6">
              <ValidationInput formik={formik} label={translate('rosterApp.organigram.demandMin')} field="demandMin" placeholder="0" />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end p-3 md:p-4 gap-2 border-t border-gray-200 rounded-b bg-navyBlue">
          <Button variant="outline" onClick={onClose} type="button">
            {translate('entity.action.cancel')}
          </Button>
          <Button type="submit" disabled={loading}>
            {loading && <FontAwesomeIcon icon={faSpinner} spin={loading} />}
            {translate('entity.action.save')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default OrganigramAddEdit;
