import { useAppDispatch } from 'app/config/store';
import { createEntity as createEquipment, deleteEntity as deleteEquipment } from 'app/entities/equipment-plan/equipment-plan.reducer';
import { Button } from 'app/shared/components/button';
import Modal from 'app/shared/components/modal/Modal';
import { ChangedEquipmentPlanType, ShiftMode } from 'app/shared/types/types';
import React, { Dispatch, SetStateAction } from 'react';
import { translate } from 'react-jhipster';

interface ShiftPlanEquipmentConfirmationModalProps {
  setShiftMode: Dispatch<SetStateAction<ShiftMode>>;
  setIsShiftPlanEquipmentPopupOpen: Dispatch<SetStateAction<boolean>>;
  setChangedEquipmentPlans: Dispatch<SetStateAction<ChangedEquipmentPlanType[]>>;
  setIsShiftPlanEquipmentLoading: Dispatch<SetStateAction<boolean>>;
  changedEquipmentPlans: ChangedEquipmentPlanType[];
  isShiftPlanEquipmentPopupOpen: boolean;
  refreshShift: () => void;
}

const ShiftPlanEquipmentConfirmationModal = ({
  setShiftMode,
  setIsShiftPlanEquipmentPopupOpen,
  setChangedEquipmentPlans,
  setIsShiftPlanEquipmentLoading,
  changedEquipmentPlans,
  isShiftPlanEquipmentPopupOpen,
  refreshShift,
}: ShiftPlanEquipmentConfirmationModalProps) => {
  const dispatch = useAppDispatch();

  const onShiftPlanConfirmationEquipmentCancel = () => {
    setShiftMode('VIEW');
    setIsShiftPlanEquipmentPopupOpen(false);
    setChangedEquipmentPlans([]);
  };

  const onShiftPlanConfirmationEquipmentSave = async () => {
    setIsShiftPlanEquipmentLoading(true);
    for (let i = 0; i < changedEquipmentPlans.length; i++) {
      if (changedEquipmentPlans[i]?.status === 'ADDED') {
        await dispatch(
          createEquipment({
            notes: changedEquipmentPlans[i]?.plans?.notes,
            startTime: changedEquipmentPlans[i]?.plans?.startTime,
            endTime: changedEquipmentPlans[i]?.plans?.endTime,
            shift: { id: changedEquipmentPlans[i]?.plans?.shift?.id },
            equipment: { id: changedEquipmentPlans[i]?.plans?.equipment?.id },
            organigram: { id: changedEquipmentPlans[i]?.plans?.organigram?.id },
          }),
        );
      }
      if (changedEquipmentPlans[i]?.status === 'DELETED' && changedEquipmentPlans[i]?.plans?.id) {
        await dispatch(deleteEquipment(changedEquipmentPlans[i]?.plans?.id));
      }
    }
    setChangedEquipmentPlans([]);
    setShiftMode('VIEW');
    refreshShift();
    setIsShiftPlanEquipmentLoading(false);
    setIsShiftPlanEquipmentPopupOpen(false);
  };

  return (
    <Modal size="sm" title={'Confirmation'} isOpen={isShiftPlanEquipmentPopupOpen} onClose={onShiftPlanConfirmationEquipmentCancel}>
      <div className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
        {translate('rosterApp.planning.confirmation-popup.shift-plan-equipment-confirmation-message')}
      </div>
      <div className="flex gap-2 items-center justify-end p-3 md:p-4 border-t border-gray-200 rounded-b bg-navyBlue">
        {onShiftPlanConfirmationEquipmentCancel && (
          <Button variant="outline" onClick={onShiftPlanConfirmationEquipmentCancel}>
            {translate('entity.action.cancel')}
          </Button>
        )}
        {onShiftPlanConfirmationEquipmentSave && (
          <Button
            onClick={() => {
              onShiftPlanConfirmationEquipmentSave();
            }}
          >
            {translate('entity.action.save')}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default ShiftPlanEquipmentConfirmationModal;
