import { ITeam } from 'app/shared/model/team.model';

export interface IOrganisation {
  id?: number;
  name?: string | null;
  type?: string | null;
  status?: string | null;
  teams?: ITeam[] | null;
}

export const defaultValue: Readonly<IOrganisation> = {};
