import React from 'react';

type SkeletonType = {
  className: string;
};

const Skeleton = ({ className }: SkeletonType) => {
  return <div className={`${className} bg-gray-200 animate-pulse`}></div>;
};

export default Skeleton;
