import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate } from 'react-jhipster';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { formatDate } from 'app/shared/util/date-utils';
import { getEntity } from './shift.reducer';

export const ShiftDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const shiftEntity = useAppSelector(state => state.shift.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="shiftDetailsHeading">
          <Translate contentKey="rosterApp.shift.detail.title">Shift</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{shiftEntity.id}</dd>
          <dt>
            <span id="startTime">
              <Translate contentKey="rosterApp.shift.startTime">Start Time</Translate>
            </span>
          </dt>
          <dd>{shiftEntity?.startTime ? formatDate(shiftEntity.startTime) : null}</dd>
          <dt>
            <span id="endTime">
              <Translate contentKey="rosterApp.shift.endTime">End Time</Translate>
            </span>
          </dt>
          <dd>{shiftEntity?.endTime ? formatDate(shiftEntity.endTime) : null}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="rosterApp.shift.status">Status</Translate>
            </span>
          </dt>
          <dd>{shiftEntity.status}</dd>
          <dt>
            <span id="rotationSequence">
              <Translate contentKey="rosterApp.shift.rotationSequence">Rotation Sequence</Translate>
            </span>
          </dt>
          <dd>{shiftEntity.rotationSequence}</dd>
          <dt>
            <Translate contentKey="rosterApp.shift.team">Team</Translate>
          </dt>
          <dd>{shiftEntity.team ? shiftEntity.team.id : ''}</dd>
          <dt>
            <Translate contentKey="rosterApp.shift.refShiftType">Ref Shift Type</Translate>
          </dt>
          <dd>{shiftEntity.refShiftType ? shiftEntity.refShiftType.id : ''}</dd>
          <dt>
            <Translate contentKey="rosterApp.shift.parent">Parent</Translate>
          </dt>
          <dd>{shiftEntity.parent ? shiftEntity.parent.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/shift" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/shift/${shiftEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ShiftDetail;
