import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Translate, getPaginationState, translate } from 'react-jhipster';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './resource-plan.reducer';
import DataTable, { DataTableOptions } from 'app/shared/components/data-table/DataTable';
import { IResourcePlan } from 'app/shared/model/resource-plan.model';

export const ResourcePlan = ({ showDetails = false }) => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const [selectedResourcePlan, setSelectedResourcePlan] = useState<IResourcePlan>();
  const resourcePlanList = useAppSelector(state => state.resourcePlan.entities);
  const loading = useAppSelector(state => state.resourcePlan.loading);
  const totalItems = useAppSelector(state => state.resourcePlan.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  const dtOptions: DataTableOptions = {
    headers: ['Status', 'Task', 'Equipment', 'Resource', 'Shift', 'Organigram'],
    columns: [
      {
        key: 'status',
        filterable: true,
        sortable: true,
      },
      {
        key: 'task',
        filterable: true,
        sortable: true,
      },
      {
        key: 'equipment',
        filterable: true,
        sortable: true,
      },
      {
        key: 'resource',
        filterable: true,
        filterableKey: 'name',
        sortable: true,
        render: row => <>{row.resource ? <Link to={`/resource/${row.resource.id}`}>{row.resource.name}</Link> : ''}</>,
      },
      {
        key: 'shift',
        filterable: true,
        filterableKey: 'id',
        sortable: true,
        render: row => <>{row.shift ? <Link to={`/shift/${row.shift.id}`}>{row.shift.id}</Link> : ''}</>,
      },
      {
        key: 'organigram',
        filterable: true,
        filterableKey: 'id',
        sortable: true,
        render: row => <>{row.organigram ? <Link to={`/organigram/${row.organigram.id}`}>{row.organigram.id}</Link> : ''}</>,
      },
    ],
  };

  const handleAdd = () => {
    setSelectedResourcePlan(null);
    navigate('/resource-plan/new');
  };

  const handleUpdate = (rows: IResourcePlan[]) => {
    setSelectedResourcePlan(rows[0]);
    navigate(`/resource-plan/${rows[0].id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`);
  };

  const handleDelete = (rows: IResourcePlan[]) => {
    setSelectedResourcePlan(rows[0]);
    navigate(
      `/resource-plan/${rows[0].id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`,
    );
  };

  const getDataTable = () => (
    <>
      {resourcePlanList && (
        <DataTable
          data={resourcePlanList}
          options={dtOptions}
          sort={sort}
          loading={loading}
          onRefresh={handleSyncList}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          paginationState={paginationState}
          onPaginationClick={handlePagination}
          paginationTotalItems={totalItems}
          paginationMaxButtons={5}
          showDetails={showDetails}
          createLabel={translate('rosterApp.resourcePlan.home.createLabel')}
          notFoundLabel={translate('rosterApp.resourcePlan.home.notFound')}
        />
      )}
    </>
  );

  return (
    <>
      <h2 id="resource-plan-heading" data-cy="ResourcePlanHeading">
        <Translate contentKey="rosterApp.resourcePlan.home.title">Resource Plans</Translate>
      </h2>
      {getDataTable()}
    </>
  );
};

export default ResourcePlan;
