import Position from '@model/custom/custom-position.model';
import AvatarGroup from '@shared/components/avatar-group/AvatarGroup';
import { IRootState, useAppSelector } from 'app/config/store';
import Tooltip from 'app/shared/components/tooltip/Tooltip';
import ResourceTypeEnum from 'app/shared/enums/ResourceTypeEnum';
import ResourcePlans from 'app/shared/model/custom/custom-resources-plans.model';
import { ICustomEquipmentPlan } from 'app/shared/model/custom/custom.equipment-plan.model';
import { IEquipmentPlan } from 'app/shared/model/equipment-plan.model';
import { IResourcePlan } from 'app/shared/model/resource-plan.model';
import { hasPermission } from 'app/shared/reducers/authentication.selector';
import { ChangedEquipmentPlanType, ShiftMode } from 'app/shared/types/types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import ShiftSubItemCounter from '../ShiftSubItemCounter/ShiftSubItemCounter';

type ShiftSubItemProps = {
  shiftId: number;
  departmentId: number;
  position: Position;
  shiftPosition?: Position;
  hideContent?: boolean;
  changedResources?: { plans: ResourcePlans; status: string }[];
  changedEquipmentPlans?: ChangedEquipmentPlanType[];
  eligibleResources?: any;
  eligibleEquipments?: any;
  shiftMode?: ShiftMode;
  equipmnetPlans?: ICustomEquipmentPlan[];
  onIncrement?: (positionId: number) => void;
  onDecrement?: (positionId: number) => void;
  onShiftDemandChange?: (positionId: number, organigramId: number, value: number) => void;
  addResourcePlan?: (resourcePlan: IResourcePlan) => void;
  updateResourcePlan?: (resourcePlan: IResourcePlan) => void;
  addEquipmentPlan?: (equipmentPlan: IEquipmentPlan) => void;
  deleteResourcePlan?: (planId: number, resourceId: number, organigramId: number) => void;
  deleteEquipmentPlan?: (planId: number, equipmentId: number, resourceId: number, organigramId: number) => void;
};

const ShiftSubItem = ({
  shiftId,
  departmentId,
  position,
  hideContent = false,
  shiftPosition,
  changedResources,
  changedEquipmentPlans,
  eligibleResources,
  eligibleEquipments,
  shiftMode = 'VIEW',
  equipmnetPlans,
  onShiftDemandChange,
  addResourcePlan,
  updateResourcePlan,
  addEquipmentPlan,
  deleteResourcePlan,
  deleteEquipmentPlan,
}: ShiftSubItemProps) => {
  const id = shiftPosition?.id ?? position.id;
  const [width, setWidth] = useState(0);
  const [maxAvatars, setMaxAvatars] = useState(1);
  const [maxEquipmentAvatars, setMaxEquipmentAvatars] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const hasPlanViewPermission = useAppSelector((state: IRootState) => hasPermission(state, 'plan_view'));
  // TODO
  const hasPlanEquipmentPermission = true; //useAppSelector((state: IRootState) => hasPermission(state, 'plan_view'));

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        const elementWidth = containerRef.current.offsetWidth;
        setWidth(elementWidth);
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      updateWidth();
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
      updateWidth(); // Initial width calculation
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if ((getResourcePlans() ?? []).length > width / 40) {
      const calculatedMaxAvatars = Math.floor(width / 40) - 1;
      setMaxAvatars(calculatedMaxAvatars > 0 ? calculatedMaxAvatars : 1);
    } else {
      setMaxAvatars(0);
    }
  }, [width]);

  useEffect(() => {
    if (shiftMode === 'PLAN_EQUIPMENT' && (getEquipmentPlans() ?? []).length > width / 40) {
      setMaxEquipmentAvatars(Math.floor(width / 40) - 1);
    } else {
      setMaxEquipmentAvatars(0);
    }
  }, [equipmnetPlans, changedEquipmentPlans]);

  const getPercentage = (): number => {
    return (resourcePlansCount * 100) / shiftPosition?.shiftDemand?.headCount;
  };

  const isFull = () => {
    return resourcePlansCount === shiftPosition?.shiftDemand?.headCount;
  };

  const getResourcePlans = (): ResourcePlans[] => {
    let resourcePlans = shiftPosition?.resourcesPlans?.filter(plan => !!plan.id) ?? [];

    for (let i = 0; i < changedResources?.length; i++) {
      if (
        changedResources[i]?.plans?.organigram?.id === position?.organigram?.id &&
        changedResources[i].status === 'ADDED' &&
        (!changedResources[i]?.plans?.id ||
          !resourcePlans
            .filter(plan => plan?.resource?.type !== (ResourceTypeEnum.DUMMY as string))
            .map(plan => plan?.resource?.id)
            .includes(changedResources[i]?.plans?.resource?.id))
      ) {
        resourcePlans.push(changedResources[i].plans);
      }
      if (
        (changedResources[i]?.plans?.organigram?.id === position?.organigram?.id || !changedResources[i]?.plans?.organigram?.id) &&
        changedResources[i].status === 'DELETED' &&
        resourcePlans.map(plan => plan?.id).includes(changedResources[i]?.plans?.id)
      ) {
        resourcePlans = resourcePlans.filter(plan => plan?.id !== changedResources[i]?.plans?.id);
      }
    }

    return resourcePlans;
  };

  const getEquipmentPlans = (): IEquipmentPlan[] => {
    let newEquipmnetPlans = equipmnetPlans ? [...equipmnetPlans] : [];

    for (let i = 0; i < changedEquipmentPlans.length; i++) {
      if (
        changedEquipmentPlans[i]?.plans?.organigram?.id === position?.organigram?.id &&
        changedEquipmentPlans[i].status === 'ADDED' &&
        !newEquipmnetPlans.map(plan => plan?.equipment?.id).includes(changedEquipmentPlans[i]?.plans?.equipment?.id)
      ) {
        newEquipmnetPlans.push(changedEquipmentPlans[i].plans);
      }
      if (
        (changedEquipmentPlans[i]?.plans?.organigram?.id === position?.organigram?.id ||
          !changedEquipmentPlans[i]?.plans?.organigram?.id) &&
        changedEquipmentPlans[i].status === 'DELETED' &&
        newEquipmnetPlans.map(plan => plan?.equipment?.id).includes(changedEquipmentPlans[i]?.plans?.equipment?.id)
      ) {
        newEquipmnetPlans = newEquipmnetPlans.filter(plan => plan?.equipment?.id !== changedEquipmentPlans[i]?.plans?.equipment?.id);
      }
    }

    return newEquipmnetPlans;
  };

  const submitDeleteResourcePlan = (planId: number, resourceId: number) => {
    deleteResourcePlan(planId, resourceId, position?.organigram?.id);
    let resourceEquipmentPlans = position?.equipmentPlans?.filter(ep => ep.resource?.id === resourceId);
    for (let i = 0; i < resourceEquipmentPlans?.length; i++) {
      deleteEquipmentPlan(resourceEquipmentPlans[i]?.id, resourceEquipmentPlans[i]?.equipment?.id, resourceId, position?.organigram?.id);
    }
  };

  const submitDeleteEquipmentPlan = (planId: number, equipmentId: number) => {
    deleteEquipmentPlan(planId, equipmentId, null, position?.organigram?.id);
  };

  const resourcePlansCount = getResourcePlans().length;

  return (
    <div
      ref={containerRef}
      className={`relative flex items-center justify-around min-h-12 border bg-white border-solid border-gray-300 ${['DEMAND', 'PLAN', 'PLAN_EQUIPMENT'].includes(shiftMode) ? 'bg-blue-100' : ''
        }`}
    >
      {!hideContent && shiftPosition && (
        <Fragment>
          <Fragment>
            {shiftMode === 'DEMAND' && (
              <ShiftSubItemCounter
                count={shiftPosition?.shiftDemand?.headCount ?? 0}
                onChange={v => onShiftDemandChange?.(id, shiftPosition?.organigram?.id, v)}
              />
            )}
          </Fragment>
          <Fragment>
            {['VIEW', 'PLAN'].includes(shiftMode) && hasPlanViewPermission && (
              <Fragment>
                <AvatarGroup
                  shiftId={shiftId}
                  organigramId={shiftPosition.organigram?.id}
                  departmentId={departmentId}
                  max={maxAvatars}
                  resourcePlans={getResourcePlans()}
                  eligibleResources={eligibleResources}
                  eligibleEquipments={eligibleEquipments}
                  equipmentPlans={getEquipmentPlans()}
                  mode={shiftMode}
                  isFull={isFull()}
                  addResourcePlan={addResourcePlan}
                  updateResourcePlan={updateResourcePlan}
                  deleteResourcePlan={submitDeleteResourcePlan}
                  addEquipmentPlan={addEquipmentPlan}
                />
                <Tooltip
                  parentClassName="absolute bottom-0 w-full flex justify-center gap-1 items-center px-1"
                  className="absolute bottom-0 w-full flex justify-center gap-1 items-center px-1"
                  position="bottom"
                  text={`${shiftPosition?.shiftDemand?.headCount - resourcePlansCount} resource${shiftPosition?.shiftDemand?.headCount - resourcePlansCount !== 1 ? 's' : ''
                    } ${shiftPosition?.shiftDemand?.headCount - resourcePlansCount !== 1 ? 'are' : 'is'} needed`}
                >
                  {Array.from({ length: shiftPosition?.shiftDemand?.headCount - resourcePlansCount }).map((_, index) => (
                    <div key={index} className="bg-red-300 h-0.5 rounded-md w-[calc(100%/10)]"></div>
                  ))}
                </Tooltip>
              </Fragment>
            )}
            {shiftMode === 'PLAN_EQUIPMENT' && hasPlanEquipmentPermission && (
              <AvatarGroup
                shiftId={shiftId}
                organigramId={shiftPosition.organigram?.id}
                departmentId={departmentId}
                max={maxEquipmentAvatars}
                equipmentPlans={getEquipmentPlans()}
                eligibleEquipments={eligibleEquipments}
                mode={shiftMode}
                isFull={false}
                addEquipmentPlan={addEquipmentPlan}
                deleteEquipmentPlan={submitDeleteEquipmentPlan}
              />
            )}
          </Fragment>
        </Fragment>
      )}
    </div>
  );
};

export default ShiftSubItem;
