import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { Fragment, useEffect, useState } from 'react';
import { getPaginationState, Translate, translate } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import DataTable, { DataTableOptions } from 'app/shared/components/data-table/DataTable';
import { IResourceTraining } from 'app/shared/model/resource-training.model';
import { IResource } from 'app/shared/model/resource.model';
import { formatToDateOnly } from 'app/shared/util/date-utils';
import ResourceTrainingAddEdit from './resource-training-add-edit';
import ResourceTrainingDelete from './resource-training-delete-modal';
import { getEntities } from './resource-training.reducer';

export const ResourceTraining = ({ showDetails = false }) => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'resource.name'), pageLocation.search),
  );
  const [selectedResourceTraining, setSelectedResourceTraining] = useState<IResourceTraining>();
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletingResourceTrainings, setDeletingResourceTrainings] = useState<IResourceTraining[]>([]);
  const resourceTrainingList = useAppSelector(state => state.resourceTraining.entities);
  const loading = useAppSelector(state => state.resourceTraining.loading);
  const totalItems = useAppSelector(state => state.resourceTraining.totalItems);

  const getFullName = (resource?: IResource) => `${resource?.firstName} ${resource?.lastName}`;
  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);

    if (page && sort) {
      const sortSplit = sort.split(',');
      let sortBy = sortSplit[0];
      if (sortBy === 'resource') {
        sortBy = 'resource.name';
      }
      if (sortBy === 'refTraining') {
        sortBy = 'refTraining.name';
      }
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortBy,
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => {
    let sortBy = p;
    if (sortBy === 'resource') {
      sortBy = 'resource.name';
    }
    if (sortBy === 'refTraining') {
      sortBy = 'refTraining.name';
    }
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: sortBy,
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const resourceTrainingOptions: DataTableOptions = {
    headers: [],
    columns: [
      {
        key: 'resource',
        filterable: true,
        filterableKey: 'name',
        sortable: true,
        render: row => (
          <Fragment>
            <Link className="text-primary capitalize hover:text-blue-700 no-underline font-medium" to={`/resource`}>
              {getFullName(row.resource)}
            </Link>
          </Fragment>
        ),
      },
      {
        key: 'refTraining',
        filterable: true,
        filterableKey: 'name',
        sortable: true,
        render: row => (
          <Fragment>
            <Link className="text-primary capitalize hover:text-blue-700 no-underline font-medium" to={`/ref-training`}>
              {row.refTraining.name}
            </Link>
          </Fragment>
        ),
      },
      {
        key: 'activeFrom',
        filterable: true,
        sortable: true,
        render: row => formatToDateOnly(row.activeFrom),
      },
      {
        key: 'activeTo',
        filterable: true,
        sortable: true,
        render: row => formatToDateOnly(row.activeTo),
      },
      { key: 'level', filterable: true, sortable: true },
      { key: 'trainer', filterable: true, sortable: true },
    ],
  };

  resourceTrainingOptions.headers = resourceTrainingOptions.columns.map(c => translate(`rosterApp.resourceTraining.${c.key}`));

  const handleAdd = () => {
    setSelectedResourceTraining(null);
    setIsAddEditModalOpen(true);
  };

  const handleUpdate = (rows: IResourceTraining[]) => {
    setSelectedResourceTraining(rows[0]);
    setIsAddEditModalOpen(true);
  };

  const handleDelete = (rows: IResourceTraining[]) => {
    setIsDeleteModalOpen(true);
    setDeletingResourceTrainings(rows);
  };
  const handleSaveAddEdit = () => {
    setIsAddEditModalOpen(false);
    sortEntities();
  };

  const handleCloseAddEdit = () => {
    setIsAddEditModalOpen(false);
  };

  const handleConfirmDelete = () => {
    setIsDeleteModalOpen(false);
    sortEntities();
  };

  const handleCloseDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const handleFilter = (data: any, query: string, key: string, filterKey?: string): any => {
    return data.filter(item => {
      if (query) {
        let neededItem;

        if (key === 'resource') {
          const firstName = item[key]?.firstName?.toString()?.toLowerCase();
          const lastName = item[key]?.lastName?.toString()?.toLowerCase();

          return (firstName && firstName.includes(query)) || (lastName && lastName.includes(query));
        } else {
          neededItem = filterKey && item[key] ? item[key][filterKey] : item[key];
          return neededItem?.toString()?.toLowerCase()?.includes(query);
        }
      }
      return true;
    });
  };

  const getDataTable = () => (
    <>
      {resourceTrainingList && (
        <DataTable
          data={resourceTrainingList}
          options={resourceTrainingOptions}
          sort={sort}
          loading={loading}
          onRefresh={handleSyncList}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          paginationState={paginationState}
          onPaginationClick={handlePagination}
          paginationTotalItems={totalItems}
          paginationMaxButtons={5}
          showDetails={showDetails}
          createLabel={translate('rosterApp.resourceTraining.home.createLabel')}
          notFoundLabel={translate('rosterApp.resourceTraining.home.notFound')}
          filterData={handleFilter}
        />
      )}
    </>
  );

  return (
    <>
      {!showDetails && (
        <h2 id="resource-training-heading" data-cy="ResourceTrainingHeading">
          <Translate contentKey="rosterApp.resourceTraining.home.title">Resource Trainings</Translate>
        </h2>
      )}
      {getDataTable()}

      {isDeleteModalOpen && (
        <ResourceTrainingDelete
          isOpen={isDeleteModalOpen}
          onClose={handleCloseDelete}
          onConfirm={handleConfirmDelete}
          deletingResourceTrainings={deletingResourceTrainings}
        />
      )}
      {isAddEditModalOpen && (
        <ResourceTrainingAddEdit
          isOpen={isAddEditModalOpen}
          onClose={handleCloseAddEdit}
          onSave={handleSaveAddEdit}
          resourceTraining={selectedResourceTraining}
        />
      )}
    </>
  );
};

export default ResourceTraining;
