import { overridePaginationStateWithQueryParams } from '@util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '@util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import DataTable, { DataTableOptions } from 'app/shared/components/data-table/DataTable';
import { getPaginationState, translate } from 'react-jhipster';
import LeaveCustom from 'app/shared/model/custom/custom-leave-model';
import { getEntities } from './ref-leave-type.reducer';
import RefLeaveTypeCustom from 'app/shared/model/custom/custom-ref-leave-type.model';
import RefLeaveTypeAddEdit from './ref-leave-type-add-edit';
import RefLeaveTypeDelete from './ref-leave-type-delete-modal';

type CustomLeaveProps = {
  showDetails?: boolean;
};

const CustomRefLeaveType = ({ showDetails = false }: CustomLeaveProps) => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  pageLocation.search = '';

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'name'), pageLocation.search),
  );

  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedRefLeaveType, setSelectedRefLeaveType] = useState<LeaveCustom>();
  const [deletingRefLeaveTypes, setDeletingLeaves] = useState<LeaveCustom[]>();
  const refLeaveTypeList = useAppSelector(state => state.customRefLeaveType.entities);
  const loading = useAppSelector(state => state.customRefLeaveType.loading);
  const totalItems = useAppSelector(state => state.customRefLeaveType.totalItems);

  const refLeaveTypeDtOptions: DataTableOptions = {
    headers: [],
    columns: [
      {
        key: 'name',
        filterable: true,
        sortable: true,
        render: row => row.name,
      },
    ],
  };

  refLeaveTypeDtOptions.headers = refLeaveTypeDtOptions.columns.map(c => translate(`rosterApp.refLeaveType.${c.key}`));

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleAdd = () => {
    setSelectedRefLeaveType(null);
    setIsAddEditModalOpen(true);
  };

  const handleUpdate = (rows: RefLeaveTypeCustom[]) => {
    setSelectedRefLeaveType(rows[0]);
    setIsAddEditModalOpen(true);
  };

  const handleDelete = (rows: RefLeaveTypeCustom[]) => {
    setIsDeleteModalOpen(true);
    setDeletingLeaves(rows);
  };

  const handleSaveAddEdit = () => {
    setIsAddEditModalOpen(false);
    sortEntities();
  };

  const handleCloseAddEdit = () => {
    setIsAddEditModalOpen(false);
  };

  const handleConfirmDelete = () => {
    setIsDeleteModalOpen(false);
    sortEntities();
  };

  const handleCloseDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const getDataTable = () => (
    <Fragment>
      {refLeaveTypeList && (
        <DataTable
          data={refLeaveTypeList}
          options={refLeaveTypeDtOptions}
          sort={sort}
          loading={loading}
          onRefresh={handleSyncList}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          paginationState={paginationState}
          onPaginationClick={handlePagination}
          paginationTotalItems={totalItems}
          paginationMaxButtons={5}
          showDetails={showDetails}
          createLabel={translate('rosterApp.refLeaveType.home.createLabel')}
          refreshListLabel={translate('rosterApp.refLeaveType.home.refreshListLabel')}
          notFoundLabel={translate('rosterApp.refLeaveType.home.notFound')}
          title={showDetails && translate('global.menu.entities.leaveTypes')}
        />
      )}
    </Fragment>
  );

  return (
    <Fragment>
      {!showDetails && (
        <h2 id="refLeaveType-heading" className={`mb-2 whitespace-nowrap`} data-cy="RefLeaveTypeHeading">
          {translate('global.menu.entities.leaveTypes')}
        </h2>
      )}
      {getDataTable()}
      <RefLeaveTypeAddEdit
        isOpen={isAddEditModalOpen}
        onClose={handleCloseAddEdit}
        onSave={handleSaveAddEdit}
        refLeaveType={selectedRefLeaveType}
      />
      <RefLeaveTypeDelete
        isOpen={isDeleteModalOpen}
        onClose={handleCloseDelete}
        onConfirm={handleConfirmDelete}
        deletingRefLeaveTypes={deletingRefLeaveTypes}
      />
    </Fragment>
  );
};

export default CustomRefLeaveType;
