import dayjs from 'dayjs';
import { IRefLeaveType } from 'app/shared/model/ref-leave-type.model';
import { IResource } from 'app/shared/model/resource.model';

export interface ILeave {
  id?: number;
  startTime?: dayjs.Dayjs | null;
  endTime?: dayjs.Dayjs | null;
  status?: string | null;
  refLeaveType?: IRefLeaveType | null;
  resource?: IResource | null;
}

export const defaultValue: Readonly<ILeave> = {};
