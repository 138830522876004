import React from 'react';
import { NavDropdown } from './menu-components';
import { locales, languages } from 'app/config/translation';
import { translate } from 'react-jhipster';

export const LocaleMenu = ({ currentLocale, onClick }: { currentLocale: string; onClick: (event: any) => void }) =>
  Object.keys(languages).length > 1 ? (
    <NavDropdown
      name={currentLocale && translate(`language.${currentLocale}.name`)}
      svg={currentLocale ? languages[currentLocale].svg : undefined}
    >
      {locales.map(locale => (
        <div key={locale} onClick={() => onClick(locale)} className="px-4 dropdown-item cursor-pointer">
          {languages[locale].svg && <div className="w-4 h-4 me-2" dangerouslySetInnerHTML={{ __html: languages[locale].svg }}></div>}
          {translate(`language.${locale}.name`)}
        </div>
      ))}
    </NavDropdown>
  ) : null;
