import { IRefPosition } from 'app/shared/model/ref-position.model';
import { IRefTraining } from 'app/shared/model/ref-training.model';

export interface IPositionRequirement {
  id?: number;
  isRequired?: boolean | null;
  refPosition?: IRefPosition | null;
  refTraining?: IRefTraining | null;
}

export const defaultValue: Readonly<IPositionRequirement> = {
  isRequired: false,
};
