import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren } from 'react';
import { translate } from 'react-jhipster';
import { Button } from 'app/shared/components/button';

type FormProps = {
  loading?: boolean;
  cancelLabel?: string;
  saveLabel?: string;
  onCancel?: () => void;
  onSubmit?: (e?: React.FormEvent<HTMLFormElement>) => void;
};

const Form = ({
  loading = false,
  cancelLabel = translate('entity.action.cancel'),
  saveLabel = translate('entity.action.save'),
  onCancel,
  onSubmit,
  children,
}: PropsWithChildren<FormProps>) => {
  return (
    <form onSubmit={onSubmit}>
      <div className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
        <div className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">{children}</div>
      </div>
      <div className="flex items-center gap-2 justify-end p-3 md:p-4 border-t border-gray-200 rounded-b bg-navyBlue">
        <Button variant="outline" onClick={onCancel} type="button">
          {cancelLabel}
        </Button>
        <Button type="submit" disabled={loading}>
          {loading && <FontAwesomeIcon icon={faSpinner} spin={loading} />}
          {saveLabel}
        </Button>
      </div>
    </form>
  );
};

export default Form;
