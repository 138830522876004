import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { getCurrentUser } from 'app/shared/reducers/authentication.selector';
import { getMonthLayout, getWeekIndexOfYear } from 'app/shared/util/date-utils';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { getEntities } from '../../../entities/leave/leave.reducer';
import { getResourceShifts, GetResourceShiftsByStartDateAndEndDateParams, setResourceId } from '../calendar.reducer';
import { selectApprovedLeaves, selectResourceShifts } from '../calendar.selector';
import CalendarBodyCell from './CalendarBodyCell';

const CalendarBody = () => {
  const dispatch = useAppDispatch();
  const { currentYear, currentMonth, resourceId } = useAppSelector(selectResourceShifts);
  const approvedLeaves = useAppSelector((state: IRootState) => selectApprovedLeaves(state, resourceId));
  const currentUser = useAppSelector(getCurrentUser);

  const [params, setParams] = useState<GetResourceShiftsByStartDateAndEndDateParams | null>(null);

  useEffect(() => {
    dispatch(
      getEntities({
        page: 0,
        size: 1000,
      }),
    );
  }, [resourceId]);

  useEffect(() => {
    const monthLayout = getMonthLayout(currentYear, currentMonth);
    const startDate = monthLayout[0][0];
    const endDate = monthLayout[monthLayout.length - 1][monthLayout[monthLayout.length - 1].length - 1];

    setParams({
      resourceId: resourceId || currentUser.id,
      startDate: new Date(`${dayjs(startDate).format('YYYY-MM-DD')}T00:00:00.000Z`).toISOString(),
      endDate: new Date(`${dayjs(endDate).format('YYYY-MM-DD')}T23:59:59.999Z`).toISOString(),
    });
  }, [resourceId, currentUser, currentYear, currentMonth]);

  useEffect(() => {
    if (params) {
      dispatch(getResourceShifts(params)).unwrap();
    }
    if (!resourceId) {
      dispatch(setResourceId(currentUser.id));
    }
  }, [params, dispatch]);

  const monthLayout = getMonthLayout(currentYear, currentMonth);

  const isCurrentDateOnLeave = useCallback(
    (date: Date): boolean => {
      const day = dayjs(date);
      return approvedLeaves.some(leave => {
        return (
          leave.startTime &&
          leave.endTime &&
          (day.isAfter(dayjs(leave.startTime), 'day') || day.isSame(dayjs(leave.startTime), 'day')) &&
          (day.isBefore(dayjs(leave.endTime), 'day') || day.isSame(dayjs(leave.endTime), 'day'))
        );
      });
    },
    [approvedLeaves],
  );

  return (
    <tbody className="h-[calc(100vh-210px)]">
      {/* Adjust 200px as needed */}
      {monthLayout.map((week, weekIndex) => (
        <tr key={weekIndex} className="h-[calc((100vh-210px)/6)]">
          {/* Divide by number of weeks */}
          <td className="bg-navyBlue text-white w-[179px] py-2 font-bold border text-center border-gray-200">
            Week {getWeekIndexOfYear(week)}
          </td>
          {week.map((date, dateIndex) => (
            <CalendarBodyCell key={dateIndex + date.toString()} date={date} isInVacation={isCurrentDateOnLeave(date)} />
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default CalendarBody;
