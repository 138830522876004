// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tw-navbar a {
  text-decoration: none !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/layout/navbar/navbar.css"],"names":[],"mappings":"AAAA;EACE,gCAAA;AACF","sourcesContent":[".tw-navbar a {\n  text-decoration: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
