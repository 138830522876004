import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from 'app/config/store';

export const selectEquipmentEntities = (state: IRootState) => state.equipment.entities;

// Create a memoized selector that returns a new array only if the entities have changed
export const selectEquipmentList = createSelector(
  [selectEquipmentEntities],
  entities => [...entities], // Create a new array only when `entities` changes
);
