import dayjs from 'dayjs';
import { ITeam } from 'app/shared/model/team.model';
import { IRefShiftType } from 'app/shared/model/ref-shift-type.model';
import { IResourcePlan } from 'app/shared/model/resource-plan.model';
import { IShiftDemand } from 'app/shared/model/shift-demand.model';
import { IEquipmentPlan } from 'app/shared/model/equipment-plan.model';
import { IResourcePlanRequest } from 'app/shared/model/resource-plan-request.model';

export interface IShift {
  id?: number;
  startTime?: dayjs.Dayjs | null;
  endTime?: dayjs.Dayjs | null;
  status?: string | null;
  rotationSequence?: number | null;
  team?: ITeam | null;
  refShiftType?: IRefShiftType | null;
  parent?: IShift | null;
  resourcePlans?: IResourcePlan[] | null;
  shiftDemands?: IShiftDemand[] | null;
  equipmentPlans?: IEquipmentPlan[] | null;
  resourcePlanRequests?: IResourcePlanRequest[] | null;
}

export const defaultValue: Readonly<IShift> = {};
