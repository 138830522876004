import './home-page.scss';

import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import ParticleSystem from './particle';

export const HomePage = () => {
  let links = [
    { url: 'http://18.192.220.229:8080/vessel', title: 'Marsa Move', src: 'content/images/marsa-move-logo.png' },
    { url: 'http://18.192.220.229:8080/berth-plan', title: 'Marsa Quay', src: 'content/images/marsa-quay-logo.png' },
    { url: '/planning', title: 'Marsa Shift', src: 'content/images/marsaShift-logo.png' },
    { url: '#', title: 'Marsa Flow', src: 'content/images/marsa-flow-logo.png' },
  ];

  return (
    <div className="flex items-center justify-center h-screen w-screen">
      <div className="flex items-center justify-center">
        {links.map((link, index) => (
          <Link
            key={link.title}
            to={link.url}
            title={link.title}
            className={`${
              index === links.length - 1 ? '' : 'mr-14'
            } shadow-md shadow-slate-400 bg-opacity-60 bg-slate-300 hover:bg-slate-400 hover:bg-opacity-60 rounded-full p-4 flex flex-col items-center text-slate-600 hover:text-slate-700 no-underline`}
          >
            {link.src && <img className="w-36" src={link.src} alt=" " />}
          </Link>
        ))}
      </div>
      <div className="-z-10 absolute top-0 left-0 w-screen h-screen bg-slate-100 flex justify-center items-center">
        <ParticleSystem />
      </div>
    </div>
  );
};

export default HomePage;
