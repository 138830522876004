import { IResource } from 'app/shared/model/resource.model';
import { IShift } from 'app/shared/model/shift.model';
import { IOrganigram } from 'app/shared/model/organigram.model';

export interface IResourcePlan {
  id?: number;
  status?: string | null;
  task?: string | null;
  equipment?: string | null;
  resource?: IResource | null;
  shift?: IShift | null;
  organigram?: IOrganigram | null;
}

export const defaultValue: Readonly<IResourcePlan> = {};
