import { IRefEquipmentType } from 'app/shared/model/ref-equipment-type.model';
import { IRefDepartment } from 'app/shared/model/ref-department.model';
import { IActivityEquipment } from 'app/shared/model/activity-equipment.model';
import { IEquipmentPlan } from 'app/shared/model/equipment-plan.model';

export interface IEquipment {
  id?: number;
  name?: string;
  type?: string | null;
  status?: string | null;
  refEquipmentType?: IRefEquipmentType | null;
  refDepartment?: IRefDepartment | null;
  activityEquipments?: IActivityEquipment[] | null;
  equipmentPlans?: IEquipmentPlan[] | null;
}

export const defaultValue: Readonly<IEquipment> = {};
