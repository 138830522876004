import React, { useEffect } from 'react';

import { useAppDispatch } from 'app/config/store';
import CalendarTable from './CalendarTable/CalendarTable';
import { findResourcesBySearchQuery } from './calendar.reducer';

const Calendar = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(findResourcesBySearchQuery(''));
  }, [dispatch]);

  return (
    <div className="w-full h-full flex flex-col gap-2 mx-auto">
      <CalendarTable />
    </div>
  );
};

export default Calendar;
