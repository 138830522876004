import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import RefCalendar from './ref-calendar';

const RefCalendarRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<RefCalendar />} />
  </ErrorBoundaryRoutes>
);

export default RefCalendarRoutes;
