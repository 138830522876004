import { IRootState } from 'app/config/store';

export const getCurrentUser = (state: IRootState) => {
  return state.authentication.currentUser;
};

export const hasPermission = (state: IRootState, permission: string) => {
  const currentUser = state.authentication.currentUser;
  // check if the current user has the permission in parameter
  return currentUser && currentUser.user && currentUser.user.permissions.includes(permission);
};
