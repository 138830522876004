import { IEquipment } from 'app/shared/model/equipment.model';

export interface IRefEquipmentType {
  id?: number;
  name?: string | null;
  description?: string | null;
  iconContentType?: string | null;
  icon?: string | null;
  equipments?: IEquipment[] | null;
}

export const defaultValue: Readonly<IRefEquipmentType> = {};
