import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ActivityEquipment from './activity-equipment';
import ActivityEquipmentDetail from './activity-equipment-detail';
import ActivityEquipmentUpdate from './activity-equipment-update';
import ActivityEquipmentDeleteDialog from './activity-equipment-delete-dialog';

const ActivityEquipmentRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ActivityEquipment />} />
    <Route path="new" element={<ActivityEquipmentUpdate />} />
    <Route path=":id">
      <Route index element={<ActivityEquipmentDetail />} />
      <Route path="edit" element={<ActivityEquipmentUpdate />} />
      <Route path="delete" element={<ActivityEquipmentDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ActivityEquipmentRoutes;
