import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IRefPosition, defaultValue } from 'app/shared/model/ref-position.model';
import Position from 'app/shared/model/custom/custom-position.model';
import { DEFAULT_PAGE, ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

const initialState: EntityState<IRefPosition> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/ref-positions';
const customApiUrl = 'api/position-training/positions';

// Actions

export const getEntities = createAsyncThunk(
  'refPosition/fetch_entity_list',
  async ({ page = DEFAULT_PAGE, size = ITEMS_PER_PAGE, sort = '' }: IQueryParams) => {
    const requestUrl = `${customApiUrl}?page=${page}&size=${size}&sort=${sort}&cacheBuster=${new Date().getTime()}`;
    return axios.get<IRefPosition[]>(requestUrl);
  },
);

export const getEntity = createAsyncThunk(
  'refPosition/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IRefPosition>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createCustomEntity = createAsyncThunk(
  'resource/create_custom_entity',
  async (entity: Position) => {
    const result = await axios.post<Position>(customApiUrl, entity);
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateCustomEntity = createAsyncThunk(
  'resource/update_custom_entity',
  async (entity: Position) => {
    const result = await axios.put<Position>(`${customApiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteCustomEntity = createAsyncThunk(
  'refPosition/delete_custom_entity',
  async (id: string | number) => {
    const requestUrl = `api/position-training/${id}`;
    const result = await axios.delete<Position>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'refPosition/create_entity',
  async (entity: IRefPosition) => {
    const result = await axios.post<IRefPosition>(apiUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'refPosition/update_entity',
  async (entity: IRefPosition) => {
    const result = await axios.put<IRefPosition>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'refPosition/partial_update_entity',
  async (entity: IRefPosition, thunkAPI) => {
    const result = await axios.patch<IRefPosition>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'refPosition/delete_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IRefPosition>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const RefPositionSlice = createEntitySlice({
  name: 'refPosition',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = RefPositionSlice.actions;

// Reducer
export default RefPositionSlice.reducer;
