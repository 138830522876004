import { useAppDispatch } from 'app/config/store';
import React, { Dispatch, SetStateAction } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { setShiftAutoDemand } from '../planning.reducer';
import { toast } from 'react-toastify';
import { translate } from 'react-jhipster';
import Modal from 'app/shared/components/modal/Modal';
import ValidationInput from 'app/shared/components/validation/validation-input';
import ShiftModel from 'app/shared/model/custom/custom-shift.model';
import { Button } from 'app/shared/components/button';

interface AutoDemandModalProps {
  setNumberOfCranes: Dispatch<SetStateAction<number>>;
  setAutoDemand: Dispatch<SetStateAction<{ label: string; value: boolean }[]>>;
  refreshShift: () => void;
  setIsLoadingAutoDemand: Dispatch<SetStateAction<boolean>>;
  setIsAutoDemandPopupOpen: Dispatch<SetStateAction<boolean>>;
  isAutoDemandPopupOpen: boolean;
  shift: ShiftModel;
  numberOfCranes: number;
  autoDemand: { label: string; value: boolean }[];
}

const validationSchema = Yup.object().shape({
  numberOfCranes: Yup.number().required('Number of cranes is required'),
  config1: Yup.boolean().required('Configuration 1 is required').default(false),
  config2: Yup.boolean().required('Configuration 2 is required').default(false),
});

const AutoDemandModal = ({
  numberOfCranes,
  autoDemand,
  setAutoDemand,
  refreshShift,
  setIsLoadingAutoDemand,
  setIsAutoDemandPopupOpen,
  isAutoDemandPopupOpen,
  shift,
}: AutoDemandModalProps) => {
  const dispatch = useAppDispatch();

  const formik = useFormik<Yup.InferType<typeof validationSchema>>({
    initialValues: {
      numberOfCranes,
      config1: autoDemand[0].value,
      config2: autoDemand[1].value,
    },
    validationSchema,
    onSubmit: values => submitForm(values),
  });

  const submitForm = async (values: Yup.InferType<typeof validationSchema>) => {
    setIsLoadingAutoDemand(false);
    await dispatch(setShiftAutoDemand({ cranes: values.numberOfCranes, shiftId: shift.id })).then(res => {
      if (res.payload) {
        toast.success(translate('rosterApp.shiftDemand.saved'));
      }
    });
    // don't know if this is connected with the backend but this is the old implementation until further instructions
    setAutoDemand([
      { label: translate('rosterApp.planning.auto-demand.config-1'), value: values.config1 },
      { label: translate('rosterApp.planning.auto-demand.config-2'), value: values.config2 },
    ]);
    refreshShift();
    setIsLoadingAutoDemand(false);
    setIsAutoDemandPopupOpen(false);
  };

  return (
    <Modal
      size="sm"
      title={translate('rosterApp.planning.confirmation-popup.auto-demand')}
      isOpen={isAutoDemandPopupOpen}
      onClose={() => setIsAutoDemandPopupOpen(false)}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full" style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}>
          <div
            className="custom-scrollbar overflow-y-auto p-3 md:p-4 space-y-3 max-h-full"
            style={{ maxHeight: 'calc(90vh - 65px - 75px)' }}
          >
            <div className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <ValidationInput
                  type="number"
                  formik={formik}
                  label={translate('rosterApp.planning.auto-demand.number-of-cranes')}
                  field="numberOfCranes"
                />
              </div>
              <div className="sm:col-span-6">
                <ValidationInput
                  formik={formik}
                  type="checkbox"
                  label={translate('rosterApp.planning.auto-demand.config-1')}
                  field="config1"
                />
              </div>
              <div className="sm:col-span-6">
                <ValidationInput
                  formik={formik}
                  type="checkbox"
                  label={translate('rosterApp.planning.auto-demand.config-2')}
                  field="config2"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end p-3 md:p-4 border-t border-gray-200 rounded-b bg-navyBlue">
          <Button variant="outline" type="button" onClick={() => setIsAutoDemandPopupOpen(false)}>
            {translate('entity.action.cancel')}
          </Button>
          <Button type="submit" className={`ml-2 btn btn-primary`}>
            {translate('rosterApp.planning.confirmation-popup.auto-demand')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default AutoDemandModal;
