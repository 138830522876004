import { cn } from 'app/shared/util/tailwind-merge';
import React, { Fragment, useState } from 'react';

const Tooltip = ({ text = '', texts = [], position = 'top', children, className = '', parentClassName = '', style = {} }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div style={style} className={cn('relative inline-block hover:z-10 ', parentClassName)}>
      {position === 'top' && (
        <span
          className={cn(
            'rounded-lg whitespace-nowrap bg-gray-800 text-white text-sm px-2 py-1 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 mb-2 pointer-events-none transition-opacity duration-200',
            isHovered ? 'opacity-100' : 'opacity-0',
          )}
        >
          {text ? (
            text
          ) : (
            <Fragment>
              {texts.map(mappedText => (
                <p key={mappedText} className="mb-0">
                  {mappedText}
                </p>
              ))}
            </Fragment>
          )}
          <svg className="absolute text-gray-800 h-2 w-full left-0 top-full" x="0px" y="0px" viewBox="0 0 255 255">
            <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
          </svg>
        </span>
      )}

      <div className={className} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        {children}
      </div>

      {position === 'bottom' && (
        <span
          className={cn(
            'rounded-lg whitespace-nowrap bg-gray-800 text-white text-xs px-2 py-1 absolute z-10 top-full left-1/2 transform -translate-x-1/2 mt-2 pointer-events-none transition-opacity duration-200',
            isHovered ? 'opacity-100' : 'opacity-0',
          )}
        >
          <svg className="absolute text-gray-800 h-2 w-full left-0 -top-2" x="0px" y="0px" viewBox="0 0 255 255" transform="rotate(180)">
            <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
          </svg>
          {text ? (
            text
          ) : (
            <Fragment>
              {texts.map(mappedText => (
                <p key={mappedText} className="mb-0">
                  {mappedText}
                </p>
              ))}
            </Fragment>
          )}
        </span>
      )}
    </div>
  );
};

export default Tooltip;
