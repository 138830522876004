import { faCircleNotch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState, useAppSelector } from 'app/config/store';
import { hasPermission } from 'app/shared/reducers/authentication.selector';
import { getBgColorById } from 'app/shared/util/color-utils';
import React, { useState } from 'react';
import Skeleton from '../skeleton/Skeleton';

type AvatarProps = {
  planId?: number;
  resourceId?: number;
  resourceTeamId?: number;
  equipmentId?: number;
  src?: string;
  alt: string;
  size?: string;
  mode?: string;
  bgColor?: string;
  equipmentName?: string;
  deleteResourcePlan?: (planId: number, id: number) => any;
  deleteEquipmentPlan?: (planId: number, id: number) => any;
};

const Avatar = ({
  planId,
  resourceId,
  resourceTeamId,
  equipmentId,
  src,
  alt,
  size,
  mode,
  bgColor,
  equipmentName,
  deleteResourcePlan,
  deleteEquipmentPlan,
}: AvatarProps) => {
  const avatarSize = size || 'h-10 w-10'; // Default size
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [imgLoading, setImgLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState<boolean>(false);

  const hasResourcePlanDeletePermission = useAppSelector((state: IRootState) => hasPermission(state, 'resource_plan_delete'));
  // TODO
  const hasEquipmentPlanDeletePermission = true; //useAppSelector((state: IRootState) => hasPermission(state, 'resource_plan_delete'));

  const handleImageLoad = () => {
    setImgLoading(false);
  };

  const isModePlanning = () => {
    return mode === 'PLAN';
  };

  const isModeEquipmentPlanning = () => {
    return mode === 'PLAN_EQUIPMENT';
  };

  const submitDelete = async () => {
    if (isModePlanning() && isHovered && resourceId && !actionLoading && hasResourcePlanDeletePermission) {
      setActionLoading(true);
      await deleteResourcePlan(planId, resourceId);
      setActionLoading(false);
    } else if (isModeEquipmentPlanning() && isHovered && equipmentId && !actionLoading && hasEquipmentPlanDeletePermission) {
      setActionLoading(true);
      await deleteEquipmentPlan(planId, equipmentId);
      setActionLoading(false);
    }
  };

  const getBgColor = () => {
    if (bgColor) return bgColor;
    if (resourceTeamId) return getBgColorById(resourceTeamId);
    return 'bg-blue-500 text-white';
  };

  return (
    <div className="flex flex-column align-items-center justify-content-center">
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => submitDelete()}
        className="relative rounded-full border-white border-2 cursor-pointer"
      >
        {src ? (
          <>
            {imgLoading && <Skeleton className={`${avatarSize} rounded-full border-white border-2`} />}
            <img
              style={{
                display: imgLoading ? 'none' : 'unset',
                filter: (isModePlanning() && isHovered) || actionLoading ? 'brightness(0.7)' : 'unset',
              }}
              src={src}
              alt={alt}
              onLoad={handleImageLoad}
              onError={handleImageLoad}
              className={`${avatarSize} max-w-none rounded-full`}
            />
          </>
        ) : (
          <span
            style={{
              filter: ((isModePlanning() || isModeEquipmentPlanning()) && isHovered) || actionLoading ? 'brightness(0.7)' : 'unset',
            }}
            className={`${
              avatarSize !== 'h-10 w-10' && 'text-xs'
            } rounded-full shadow-lg text-slate-600 cursor-pointer flex items-center font-bold justify-center ${avatarSize} ${getBgColor()}`}
          >
            {alt?.toUpperCase()}
          </span>
        )}
        {isModePlanning() && isHovered && !actionLoading && hasResourcePlanDeletePermission && (
          <div className="absolute z-10 inset-0 text-white flex items-center justify-center">
            <FontAwesomeIcon icon={faTrash} />
          </div>
        )}
        {isModeEquipmentPlanning() && isHovered && !actionLoading && hasEquipmentPlanDeletePermission && (
          <div className="absolute z-10 inset-0 text-white flex items-center justify-center">
            <FontAwesomeIcon icon={faTrash} />
          </div>
        )}
        {actionLoading && (
          <div className="absolute z-10 inset-0 text-white flex items-center justify-center">
            <FontAwesomeIcon icon={faCircleNotch} className="animate-spin" />
          </div>
        )}
      </div>
      {equipmentName ? <span style={{ height: '0.7rem', marginTop: '-0.15rem', fontSize: '0.65rem' }}>{equipmentName}</span> : <></>}
    </div>
  );
};
export default Avatar;
