import { IFacility } from 'app/shared/model/facility.model';
import { IActivity } from 'app/shared/model/activity.model';
import { IOrganigram } from 'app/shared/model/organigram.model';
import { IResource } from 'app/shared/model/resource.model';
import { IEquipment } from 'app/shared/model/equipment.model';

export interface IRefDepartment {
  id?: number;
  name?: string;
  facility?: IFacility | null;
  activities?: IActivity[] | null;
  organigrams?: IOrganigram[] | null;
  resources?: IResource[] | null;
  equipments?: IEquipment[] | null;
}

export const defaultValue: Readonly<IRefDepartment> = {};
