import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPaginationState, Translate, translate } from 'react-jhipster';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { deleteCustomEntity, getEntities } from './ref-position.reducer';
import DataTable, { DataTableOptions } from 'app/shared/components/data-table/DataTable';
import PositionAddEdit from './ref-position-add-edit';
import Position from 'app/shared/model/custom/custom-position.model';
import DeleteConfirmationPopup from 'app/shared/components/confirmation-popup/delete-confirmation-popup';
import { getPositionTypeI18nTranslation } from 'app/shared/util/i18n';
import { PositionType } from 'app/shared/enums';

export const RefPosition = ({ showDetails = false }) => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  pageLocation.search = '';

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'name'), pageLocation.search),
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');
  const [selectedPosition, setSelectedPosition] = useState<Position>();
  const [deletingPositions, setDeletingPositions] = useState<Position[]>();
  const refPositionList = useAppSelector(state => state.refPosition.entities);
  const loading = useAppSelector(state => state.refPosition.loading);
  const totalItems = useAppSelector(state => state.refPosition.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  let dtOptions: DataTableOptions = {
    headers: [],
    columns: [
      { key: 'name', filterable: true, sortable: true },
      { key: 'description', filterable: true, sortable: true },
      {
        key: 'type',
        filterable: true,
        sortable: true,
        render: row => <>{getPositionTypeI18nTranslation(row?.type as PositionType)}</>,
      },
      { key: 'workHoursMax', filterable: true, sortable: true },
      { key: 'restHoursMin', filterable: true, sortable: true },
    ],
  };
  dtOptions.headers = dtOptions.columns.map(c => translate(`rosterApp.refPosition.${c.key}`));

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSave = () => {
    setIsModalOpen(false);
    sortEntities();
  };

  const handleAdd = () => {
    setSelectedPosition(null);
    setIsModalOpen(true);
  };

  const handleUpdate = (rows: Position[]) => {
    setSelectedPosition(rows[0]);
    setIsModalOpen(true);
  };

  const handleDelete = (rows: Position[]) => {
    setConfirmationText(
      rows.length === 1
        ? translate('rosterApp.refPosition.delete.question', { id: rows[0].name })
        : translate('rosterApp.refPosition.delete.multipleQuestion', { param: rows.length }),
    );
    setDeletingPositions(rows);
  };

  const deleteItems = async () => {
    try {
      for (const row of deletingPositions) {
        await dispatch(deleteCustomEntity(row.id));
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      sortEntities();
      setConfirmationText('');
      setSelectedPosition(null);
    }
  };

  const getDataTable = () => (
    <>
      {refPositionList && (
        <DataTable
          data={refPositionList}
          options={dtOptions}
          sort={sort}
          loading={loading}
          onRefresh={handleSyncList}
          onAdd={handleAdd}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          paginationState={paginationState}
          onPaginationClick={handlePagination}
          paginationTotalItems={totalItems}
          paginationMaxButtons={5}
          showDetails={showDetails}
          createLabel={translate('rosterApp.refPosition.home.createLabel')}
          notFoundLabel={translate('rosterApp.refPosition.home.notFound')}
          refreshListLabel={translate('rosterApp.refPosition.home.refreshListLabel')}
          title={showDetails && translate('global.menu.entities.positions')}
        />
      )}
    </>
  );

  return (
    <>
      {!showDetails && (
        <h2 id="ref-position-heading" className={`mb-2 whitespace-nowrap`} data-cy="RefPositionHeading">
          <Translate contentKey="global.menu.entities.positions">Positions</Translate>
        </h2>
      )}
      {getDataTable()}
      <DeleteConfirmationPopup
        open={confirmationText?.length > 0}
        onSave={deleteItems}
        onCancel={() => setConfirmationText('')}
        children={confirmationText}
        saveClassName="btn-danger"
      />
      <PositionAddEdit isOpen={isModalOpen} onClose={handleClose} onSave={handleSave} position={selectedPosition} />
    </>
  );
};

export default RefPosition;
