import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity } from 'app/entities/activity/activity.reducer';
import { getEntities as getRefActivityTypes } from 'app/entities/ref-activity-type/ref-activity-type.reducer';
import { setActivityTypeShiftAutoDemand } from 'app/modules/planning/planning.reducer';
import Modal from 'app/shared/components/modal/Modal';
import { IActivity } from 'app/shared/model/activity.model';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { translate } from 'react-jhipster';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Form from '../validation/Form';
import ValidationDateTimeInput from '../validation/validation-date-time-input';
import ValidationInput from '../validation/validation-input';
import ValidationSelect from '../validation/validation-select';
import ValidationSwitch from '../validation/validation-switch';

type AutoDemandActivityModalProps = {
  startTime: string;
  endTime: string;
  departmentId: number;
  shiftId: number;
  onClose: () => void;
  refreshShift: () => void;
  isOpen?: boolean;
};

const validationSchema = Yup.object().shape({
  refActivityType: Yup.object().required('RefActivityType is required'),
  notes: Yup.string().optional(),
});
const AutoDemandActivityModal = ({
  shiftId,
  departmentId,
  startTime,
  endTime,
  isOpen = false,
  onClose,
  refreshShift,
}: AutoDemandActivityModalProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const refActivityTypes = useAppSelector(state => state.refActivityType.entities);
  const formik = useFormik({
    initialValues: {
      refActivityType: null,
      notes: '',
      startTime,
      endTime,
      applyTemplateActivity: false,
    },
    validationSchema,
    onSubmit: values => handleSave(values),
  });

  useEffect(() => {
    formik.setValues(values => ({ ...values, startTime, endTime }));
    dispatch(getRefActivityTypes({ sort: 'name,asc' }));
  }, [isOpen, startTime, endTime, dispatch]);

  const handleSave = useCallback(
    values => {
      const { applyTemplateActivity, refActivityType } = values;
      setLoading(true);
      try {
        createActivity(values);
        if (applyTemplateActivity) {
          handleAutoDemandForActivity(refActivityType.id);
        }
        onClose();
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    },
    [onClose],
  );

  const createActivity = values => {
    const { refActivityType, notes } = values;
    const payload: IActivity = {
      notes,
      startTime: dayjs(startTime),
      endTime: dayjs(endTime),
      refDepartment: { id: departmentId },
      refActivityType: { id: refActivityType.id },
    };
    dispatch(createEntity(payload));
  };

  const handleAutoDemandForActivity = async (refActivityTypeId: number) => {
    await dispatch(setActivityTypeShiftAutoDemand({ refActivityTypeId, shiftId, departmentId })).then(res => {
      if (res.payload) {
        toast.success(translate('rosterApp.activity.auto-demand-popup.title'));
      }
    });
    refreshShift();
  };

  return (
    <>
      <Modal size="lg" title={translate('rosterApp.activity.auto-demand-popup.title')} isOpen={isOpen} onClose={onClose}>
        <Form
          onSubmit={formik.handleSubmit}
          loading={loading}
          cancelLabel={translate('rosterApp.activity.auto-demand-popup.form.cancel')}
          saveLabel={translate('rosterApp.activity.auto-demand-popup.form.apply')}
          onCancel={onClose}
        >
          <div className="sm:col-span-6">
            <ValidationSelect
              formik={formik}
              options={refActivityTypes}
              optionsKey="id"
              optionsValue="name"
              label={translate('rosterApp.activity.auto-demand-popup.form.activity')}
              field="refActivityType"
            />
          </div>
          <div className="sm:col-span-6">
            <ValidationInput
              formik={formik}
              label={translate('rosterApp.activity.auto-demand-popup.form.notes')}
              field="notes"
              type="text"
            />
          </div>
          <div className="sm:col-span-3">
            <ValidationDateTimeInput
              formik={formik}
              label={translate('rosterApp.activity.auto-demand-popup.form.from')}
              field="startTime"
              disabled
            />
          </div>
          <div className="sm:col-span-3">
            <ValidationDateTimeInput
              formik={formik}
              label={translate('rosterApp.activity.auto-demand-popup.form.to')}
              field="endTime"
              disabled
            />
          </div>

          <div className="sm:col-span-6">
            <ValidationSwitch
              formik={formik}
              label={translate('rosterApp.activity.auto-demand-popup.form.applyTemplateActivity')}
              field="applyTemplateActivity"
            />
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AutoDemandActivityModal;
